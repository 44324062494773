import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/videoShare.css'
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
import {
    BigPlayButton,
    Player,
    ControlBar,
    PlayToggle, // PlayToggle 播放/暂停按钮 若需禁止加 disabled
    ReplayControl, // 后退按钮
    ForwardControl,  // 前进按钮
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,  // 倍速播放选项
    VolumeMenuButton
} from 'video-react';
import HLSSource from './HLSSource';
import JsTypeFunction from "../../common/JsTypeFunction";

class Extend extends Component {

    constructor(props) {
        super(props);
        this.state = {
            extend_id: '',
            wxShow: false,
        };
    }

    componentDidMount() {
        let uc = window.navigator.userAgent.toLowerCase();
        if(uc.match(/MicroMessenger/i) == 'micromessenger'){      //判断是否是微信环境
            this.setState({
                wxShow: true,
            })
        }
        let obj=JsTypeFunction.getUrlParams();
        console.log(obj);
        if(obj.extend_id){
            window.localStorage.setItem("extend_id",obj.extend_id);
            this.setState({
                extend_id: obj.extend_id
            },()=>{
                this.handleExtend();
            })
        }
    }

    // 推广id接口
    handleExtend=()=>{
        util.postYangAxios(
            api.extend,
            {
            },
            (res) => {
                console.log(res);
            },
            (err) => {
                console.log(err);
            },
        );
    }

    // 下载接口
    handleSkipDown=()=>{
        this.props.history.push('download')
        // util.postYangAxios(
        //     api.download,
        //     {
        //     },
        //     (res) => {
        //         console.log(res);
        //     },
        //     (err) => {
        //         console.log(err);
        //     },
        // );
    }

    render() {
        const {userData} =this.props.UserDataReducer;
        const { wxShow }=this.state;
        return (
            <div className={'down_div'} style={{overflow: wxShow?'hidden':'scroll'}}>
                <img src={JsTypeFunction.handleImgAddr('down')} style={{width: '100%'}}/>
                <div className="down_btn_view">
                    <img src={JsTypeFunction.handleImgAddr('down_btn')} className="down_btn"  onClick={this.handleSkipDown}/>
                </div>
                {
                    wxShow &&
                    <img src={'https://cdn.ellll.com/capphabit/open_browser_img.png'} className="open_browser_img"/>
                }
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(Extend)
