import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/videoShare.css'
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
import {
    BigPlayButton,
    Player,
    ControlBar,
    PlayToggle, // PlayToggle 播放/暂停按钮 若需禁止加 disabled
    ReplayControl, // 后退按钮
    ForwardControl,  // 前进按钮
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,  // 倍速播放选项
    VolumeMenuButton
} from 'video-react';
import HLSSource from './HLSSource';
import Hls from 'hls.js';

class VideoShare extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoUrl: null,
        };
    }

    componentDidMount() {
        let obj=this.getUrlParams();
        console.log(obj);
        if(obj.url.indexOf(qiNiuIP)==-1){
            obj.url=qiNiuIP+obj.url
        }
        if(obj.url){
            this.setState({
                videoUrl: obj.url,
            },()=>{
                var video = document.getElementById('video');
                var hls = new Hls();
                hls.loadSource(obj.url);
                hls.attachMedia(video);
                hls.on(Hls.Events.MANIFEST_PARSED,function() {
                    video.play();
                })
                // this.player.subscribeToStateChange(this.handleStateChange.bind(this));
            })
        }else{
            Toast.info('您查看的视频已失效')
        }
    }

    handleStateChange(state, prevState) {
        // console.log(state)
        if(state.ended){
           this.handleDoload()
        }
        if(prevState.isFullscreen&&!state.isFullscreen){
            this.player.pause();
        }
    }

    // 播放视频
    handlePlayVideo=()=>{
        this.player.play();
        this.player.toggleFullscreen();
    }

    getUrlParams = () => {
        let url = decodeURI(window.location.href);
        let index = url.indexOf('?')
        let obj = {}
        if (index !== -1) {
            let str = url.split('?')[1]
            let arr = str.split('&')
            for (let i = 0; i < arr.length; i++) {
                let str=arr[i].slice(arr[i].indexOf('=')+1);
                let list=arr[i].split('=')
                obj[list[0]] = str
            }
        }
        return obj//{articleId: "1", articleNum: "1", opt: "edit"}
    }

    // 前往下载
    handleDoload=()=>{
        this.props.history.push('download')
    }

    render() {
        const {userData} =this.props.UserDataReducer;
        const {videoUrl }=this.state
        return (
            <div className={"article_view"}>
                <div className={"video_view"}>
                    {
                        videoUrl&&  <video src={videoUrl} controls="controls" className={"video_view"} onEnded={this.handleDoload} id={'video'}>
                            您的浏览器不支持播放该视频。
                        </video>
                    }
                </div>
                {/*<div className={"article_btn_view row_center"}>*/}
                {/*    <img src={'https://cdn.ellll.com/capphabit/xggs_invite_logo.png'} alt="" className={'article_xggs_invite_logo'}/>*/}
                {/*    <div>*/}
                {/*        <div className={'article_bottom_text'}>习惯公社</div>*/}
                {/*        <div className={'article_bottom_text'}>帮助一亿中国人养成好习惯</div>*/}
                {/*    </div>*/}
                {/*    <div className={"article_btn_box row_center"} onClick={this.handleDoload}>*/}
                {/*        前往下载*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(VideoShare)
