import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/parentsSchool.css'
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";
import FloatFooter from "../../component/Footer/FloatFooter";
class ParentsSchoolXyb extends Component {

    constructor(props) {
        super(props);
        this.state = {
            articleId: 1,
            type: 1,
            articleObj: null,
        };
    }

    componentDidMount() {
        let obj=this.getUrlParams();
        console.log(obj);
        if(obj.id){
            this.setState({
                articleId: obj.id,
                type: obj.type,
            },()=>{
                this.handleRefresh();
            })
        }else{
            Toast.info('您查看的文章已失效')
        }
    }


    getUrlParams = () => {
        let url = decodeURI(window.location.href);
        let index = url.indexOf('?')
        let obj = {}
        if (index !== -1) {
            let str = url.split('?')[1]
            let arr = str.split('&')
            for (let i = 0; i < arr.length; i++) {
                obj[arr[i].split('=')[0]] = arr[i].split('=')[1]
            }
        }
        return obj//{articleId: "1", articleNum: "1", opt: "edit"}
    }


    // 家长学堂获取数据
    handleRefresh = () => {
        const { articleId,type }=this.state;
        util.getYangAxios(
            api.todayCourse,
            {
                id: articleId,
                // type,
            },
            (res) => {
                console.log(res);
                if(res.web_url){
                    window.location.href=res.web_url
                }else{
                    this.setState({
                        articleObj: res
                    })
                }
            },
            (err) => {
                console.log(err);
                // Toast.fail('服务器异常,请稍后重试')
            },
        );
    };

    // 前往下载
    handleDoload=()=>{
        this.props.history.push('download')
    }


    render() {
        const {userData} =this.props.UserDataReducer;
        const {articleObj, type }=this.state
        return (
            <div className={"article_view"}>
                <div className={'row_center article_header_div animate__animated animate__zoomIn'} style={{marginBottom: 0}}>
                    家长学堂
                </div>
                {
                    articleObj&&<>
                        <div className={"parent_video_view"}>
                            {
                                articleObj.video&&  <video src={articleObj.video} controls="controls" className={"parent_video_view"}>
                                    您的浏览器不支持播放该视频。
                                </video>
                            }
                            {
                                !articleObj.video&&articleObj.audio&&  <video src={articleObj.audio} controls="controls" className={"parent_video_view"} poster={articleObj.cover}>
                                    您的浏览器不支持播放该视频。
                                </video>
                            }
                        </div>
                        <div className={'activity_gray_line'} style={{marginBottom: '1rem',marginTop: 0}}/>
                        <div className={'article_top_div row_align'}>
                            <div style={{backgroundSize: 'cover', backgroundImage: `url(${articleObj.cover}?imageView2/5/q/75|imageslim)`, backgroundRepeat: "no-repeat",}} className={'article_top_bg_img'}>
                            </div>
                            <div className={'activity_top_text_view'}>
                                <div className={'activity_top_title'}>{articleObj.name}</div>
                                <div className={'activity_top_des'}>{articleObj.des}</div>
                                <div className={'activity_top_date'}>{JsTypeFunction.toCnGreeknTimeFormat(articleObj.updated_at)}</div>
                            </div>
                        </div>
                    </>
                }
                <FloatFooter/>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(ParentsSchoolXyb)
