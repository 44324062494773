import * as types from '../constant/UserTypes'


// 用户信息设置
export function userDataSet(obj) {
  return {
    // type是必要参数，通过type值判断
    type: types.USER_DATA_SET,
    obj,
  }
}

// 退出登陆
export function outLogin() {
  return {
    // type是必要参数，通过type值判断
    type: types.OUT_LOGIN,
  }
}


// 学校信息设置
export function SchoolDataSet(obj) {
  return {
    // type是必要参数，通过type值判断
    type: types.SCHOOL_DATA_SET,
    obj,
  }
}