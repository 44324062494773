import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import {util} from "../../common/util";
import "./css/appraisal.css";
import {api} from "../../common/Config";
import {InputItem, Toast,} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";
class AppraisalReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questionnaireList: [
            ],
            type: 0,
            selectList: [],
        };
    }

    componentDidMount() {

    }

    handleGetCp=()=>{
        util.getYangAxios(
            api.evaluation,
            {
            },
            async (res) => {
                this.setState({
                    questionnaireList: res
                })
            },
            (err) => {
                console.log(err);
            },
        );
    }


    // 提交
    handleSubmit = () => {
        window.ReactNativeWebView&&window.ReactNativeWebView.postMessage('2')
    };


    render() {
        const {} = this.state;
        return (
            <div className={'appraisal_report_xyb_div'}>
                <img src={JsTypeFunction.handleImgAddr('appraisal_report_top_img')} alt="" className={'appraisal_report_top_img'}/>
                <div className={'appraisal_report_content_text'}>
                    测评结果报告生成之后，我们会在【习惯公社APP】
                    为您发送通知，请您及时关注平台信息。
                </div>
                <div className={'row_center appraisal_report_content_btn_box'}>
                    <div className={'appraisal_report_content_btn row_center'} onClick={this.handleSubmit}>确认</div>
                </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(AppraisalReport)
