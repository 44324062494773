import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import {util} from "../../common/util";
import {api} from "../../common/Config";
import jpb_down from  "../../image/jpb_down.jpg"
import down_jpb_logo from  "../../image/down_jpb_logo.png"
import down_jpb_bottom_bg from  "../../image/down_jpb_bottom_bg.png"
class DownloadJpb extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wxShow: false,
        };
    }

    componentDidMount(){
        let ua = navigator.userAgent
        console.log(ua)
        window.location.href = 'xggsjpb://';
        if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            console.log('苹果')
        } else if (ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1) {
            console.log('安卓')
        }
        let uc = window.navigator.userAgent.toLowerCase();
        if(uc.match(/MicroMessenger/i) == 'micromessenger'){      //判断是否是微信环境
            this.setState({
                wxShow: true,
            })
        }
    }


    // 下载按钮
    handleSkipDown=()=>{
        // this.handleDownload();
        let ua = navigator.userAgent
        if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            console.log('苹果')
            window.location.href ="https://apps.apple.com/cn/app/%E4%B9%A0%E6%83%AF%E5%85%AC%E7%A4%BE%E6%95%99%E5%9F%B9%E7%89%88/id1593318229";
        } else if (ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1) {
            console.log('安卓')
            window.location.href ="https://cdn.ellll.com/jp914.apk";
        }
    }

    // 下载接口
    handleDownload=()=>{
        let ua = navigator.userAgent
        util.postYangAxios(
            api.download,
            {
                // app_type: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)?1:2
            },
            (res) => {
                console.log(res);
            },
            (err) => {
                console.log(err);
            },
            true
        );
    }

    render() {
        const { wxShow } = this.state;
        return (
            <div className={'down_div'} style={{overflow: wxShow?'hidden':'scroll'}}>
                <div className='row_center'> <img src={down_jpb_logo} className="down_jpb_logo"/></div>
                <img src={down_jpb_bottom_bg} className="down_jpb_bottom_bg"/>
                <div className='column_align down_jpb_bottom_btn_div'>
                    <div className='down_jpb_bottom_btn row_center' onClick={this.handleSkipDown}>立即下载</div>
                    <div className='down_jpb_bottom_name'>技术支持：上海形者网络科技发展有限公司</div>
                </div>
                {/*<img src={jpb_down} className="top_logo"  onClick={this.handleSkipDown}/>*/}
                {
                    wxShow&& <img src={'https://cdn.ellll.com/capphabit/open_browser_img.png'} className="open_browser_img" />
                }
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(DownloadJpb)
