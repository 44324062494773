import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/address.css'
import {util} from "../../common/util";
import {api} from "../../common/Config";
import {InputItem, Toast, Modal} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";
import HeaderView from "../../component/header/headerView";
import add_addr_white_icon from "../../image/spell/add_addr_white_icon.png";
import addr_select_icon from "../../image/spell/addr_select_icon.png";
import select_oragin_icon from "../../image/spell/select_oragin_icon.png";
import select_not_studying_icon from "../../image/spell/select_not_studying_icon.png";
import * as copy from "copy-to-clipboard";
const alert = Modal.alert;

class addressList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activityList: [],
            addrObj: null,
            deleteVisible: false,
        };
    }

    componentDidMount() {
        let obj=JsTypeFunction.getUrlParams();
        console.log(obj);
        this.onRefresh();
    }

    // 刷新
    onRefresh = () => {
        this.handleEndLoad();
    };

    // 上啦加载
    handleEndLoad = () => {
        util.getYangAxios(
            api.userAddress,
            {
            },
            (res) => {
                console.log(res);
                this.setState({
                    activityList: res.list,
                });
            },
            (err) => {
                console.log(err);
            },
            true,
        );
    };

    // 设置默认
    handleSetDefault=(obj)=>{
        if(obj.is_default==1){
            return null;
        }
        util.putYangAxios(
            api.userAddress+`/${obj.id}`,
            {
                ...obj,
                is_default: 1
            },
            (res) => {
                console.log(res);
                this.onRefresh();
            },
            (err) => {
                console.log(err);
            },
            true,
        );
    }

    // 删除
    handleDelete=(addrObj)=>{
        alert('提示', '确定要删除该地址吗？', [
            { text: '取消', onPress: () => console.log('cancel') },
            {
                text: '确认',
                onPress: () =>{
                    util.deleteYangAxios(
                        api.userAddress+`/${addrObj.id}`,
                        {
                        },
                        (res) => {
                            console.log(res);
                            this.onRefresh();
                        },
                        (err) => {
                            console.log(err);
                        },
                        true,
                    );
                }
            },
        ])
    }

    render() {
        const {userData} =this.props.UserDataReducer;
        const { activityList, deleteVisible}=this.state
        return (
            <div className={"article_view"} style={{background: '#F4F5F6'}}>
                <HeaderView
                    title={'收货地址'}
                />
                <div className={'spell_address_div'}>
                    {
                        activityList.map((item,index)=>{
                            return (
                                <div key={index} className={'spell_address_card'}>
                                    <div className={'spell_address_title'}>{item.name} {item.phone}</div>
                                    <div className={'row_between'}>
                                        <div className={'spell_address_content'}>{item.province}{item.city}{item.area}{'\n'}{item.address}</div>
                                        {
                                            item.is_default == 1? <img src={addr_select_icon} alt="" className={'addr_select_icon'}/>:<div className={'spell_address_select_text row_center'} onClick={()=>this.handleSetDefault(item)}>使用</div>
                                        }

                                    </div>
                                    <div className={'spell_address_action_div row_between'}>
                                        {
                                            item.is_default == 1? <div className={'row_align spell_address_action_select_text'}>
                                                <img src={select_oragin_icon} alt="" className={'select_oragin_icon'}/>
                                                已设为默认
                                            </div>: <div className={'row_align spell_address_action_select_text'} style={{color: '#A0A0A0'}} onClick={()=>this.handleSetDefault(item)}>
                                                <img src={select_not_studying_icon} alt="" className={'select_oragin_icon'}/>
                                                设为默认
                                            </div>
                                        }

                                        <div className={'row_align spell_address_action_btn_text'}>
                                            <div onClick={()=>JsTypeFunction.handleCopy(`${item.name} ${item.phone}\n${item.province}${item.city}${item.area}\n${item.address}`)}>复制</div>
                                            <div className={'ml50'} onClick={()=>this.handleDelete(item)}>删除</div>
                                            <div className={'ml50'} onClick={()=>this.props.history.push({
                                                pathname: '/addAddress',
                                                state: {
                                                    type: 2, //1新增  2修改
                                                    addrObj: item,
                                                }
                                            })}>修改</div>
                                        </div>

                                    </div>

                                </div>
                            )
                        })
                    }

                </div>
                <div className={'row_center spell_address_btn_div'}>
                    <div className={'spell_address_btn_box row_center'} onClick={()=>this.props.history.push({
                        pathname: '/addAddress',
                        state: {
                            type: 1, //1新增  2修改
                        }
                    })}>
                        <img src={add_addr_white_icon} alt="" className={'add_addr_white_icon'}/>
                        添加收货地址
                    </div>
                </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(addressList)
