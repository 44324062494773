import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/address.css'
import {util} from "../../common/util";
import {api} from "../../common/Config";
import {data} from "../../common/LocationUtil";
import {InputItem, Toast, Picker} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";
import HeaderView from "../../component/header/headerView";
import add_addr_white_icon from "../../image/spell/add_addr_white_icon.png";


// 如果不是使用 List.Item 作为 children
const CustomChildren = props => (
    <div
        onClick={props.onClick}
    >
        <div className={'row_align mb30'}>
            <div className={'addr_input_title'}>{props.children}</div>
            <div className={'addr_input_text bm'} style={{flex: 1}}>{props.extra}</div>
        </div>
    </div>
);

class addAddress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nameValue: '',
            phoneValue: '',
            detailedValue: '',
            pickerValue: null,
            addrList: null,
            addrObj: null,
            type: 1,
        };
    }

    componentDidMount() {
        let obj=JsTypeFunction.getUrlParams();
        let list=data.map((item,index)=>{
            var obj={label: item.label,value: item.label,children:item.children.map((g,i)=>{
                    return {label: g.label,value: g.label,children:g.children.map((v,k)=>{
                            return {label: v.label,value: v.label}
                        })}
                })}
            return (obj)
        })
        if(this.props.history.location.state){
            let { addrObj }=this.props.history.location.state;
            if(addrObj){
                this.setState({
                    type: this.props.history.location.state.type,
                    addrObj: addrObj,
                    nameValue: addrObj.name,
                    phoneValue: addrObj.phone,
                    detailedValue: addrObj.address,
                    pickerValue: [addrObj.province,addrObj.city,addrObj.area],

                })
            }

        }
        this.setState({addrList: list})
        console.log(list)
    }

    onNameChange=(event)=>{
        this.setState({
            nameValue:  event.target.value,
        })
    }

    onPhoneChange=(event)=>{
        this.setState({
            phoneValue:  event.target.value,
        })
    }

    onDetailedChange=(event)=>{
        this.setState({
            detailedValue:  event.target.value,
        })
    }

    // 提交
    handleSubmit=()=>{
        const {nameValue,phoneValue, detailedValue,pickerValue, type, addrObj  }=this.state
        if (!nameValue){
            Toast.info('请输入姓名', 1.5)
            return null
        }
        if (!phoneValue) {
            Toast.info('请输入手机号码', 1.5)
            return null
        }
        if (JSON.stringify(pickerValue) === '[]'&&type==1) {
            Toast.info('请选择地址', 1.5)
            return null
        }
        if (!detailedValue) {
            Toast.info('请输入详细地址', 1.5)
            return null
        }
        if(type==1){
            util.postYangAxios(
                api.userAddress,
                {
                    name: nameValue,
                    phone: phoneValue,
                    province: pickerValue[0],
                    city: pickerValue[1],
                    area: pickerValue[2],
                    address: detailedValue,
                    is_default: 1,
                },
                (res) => {
                    console.log(res);
                    Toast.info('创建成功',1.5,()=>{
                        this.props.history.goBack();
                    })

                },
                (err) => {
                    console.log(err);
                },
                true,
            );
        }else{
            util.putYangAxios(
                api.userAddress+`/${addrObj.id}`,
                {
                    name: nameValue,
                    phone: phoneValue,
                    province: JSON.stringify(pickerValue) === '[]'?addrObj.province:pickerValue[0],
                    city: JSON.stringify(pickerValue) === '[]'?addrObj.city:pickerValue[1],
                    area: JSON.stringify(pickerValue) === '[]'?addrObj.area:pickerValue[2],
                    address: detailedValue,
                },
                (res) => {
                    console.log(res);
                    Toast.info('修改成功',1.5,()=>{
                        this.props.history.goBack();
                    })

                },
                (err) => {
                    console.log(err);
                },
                true,
            );
        }

    }

    render() {
        const {userData} =this.props.UserDataReducer;
        const {nameValue,phoneValue, detailedValue,pickerValue, addrList }=this.state
        return (
            <div className={"article_view"}>
                <HeaderView
                    title={'添加收货地址'}
                />
                <div className={'p30'}>
                    <div className={'row_align mb30'} onClick={()=>{ this.nameInputs.focus()}}>
                        <div className={'addr_input_title'}>
                            收货人
                        </div>
                        <div className={'bm'}>
                            <input type="text" value={nameValue} onChange={this.onNameChange} className={'addr_input_text'} placeholder={'请输入姓名'} ref={(c) => {
                                this.nameInputs=c;
                            }}/>
                        </div>
                    </div>
                    <div className={'row_align mb30'} onClick={()=>{ this.phoneInputs.focus()}}>
                        <div className={'addr_input_title'}>
                            手机号
                        </div>
                        <div className={'bm'}>
                            <input type="text" value={phoneValue} onChange={this.onPhoneChange} className={'addr_input_text'} placeholder={'请输入手机号'} ref={(c) => {
                                this.phoneInputs=c;
                            }}/>
                        </div>
                    </div>
                    <Picker
                        title="选择地区"
                        extra="请选择地区"
                        data={addrList}
                        value={pickerValue}
                        onChange={v => this.setState({ pickerValue: v })}
                        onOk={v => this.setState({ pickerValue: v })}

                    >
                        <CustomChildren>地区</CustomChildren>
                    </Picker>
                    <div className={'row_align mb30'} onClick={()=>{ this.detailedInputs.focus()}}>
                        <div className={'addr_input_title'}>
                            详细地址
                        </div>
                        <div className={'bm'}>
                            <input type="text" value={detailedValue} onChange={this.onDetailedChange} className={'addr_input_text'} placeholder={'请输入详细地址'} ref={(c) => {
                                this.detailedInputs=c;
                            }}/>
                        </div>
                    </div>


                </div>
                <div className={'row_center spell_address_btn_div'} >
                    <div className={'spell_address_btn_box row_center'} onClick={this.handleSubmit}>
                        保存
                    </div>
                </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(addAddress)
