import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/memberDetail.css'
import {util} from "../../common/util";
import {api} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
import HeaderView from "../../component/header/headerView";
import Carousel, { Modal, ModalGateway } from 'react-images'
import { PhotoProvider, PhotoConsumer,PhotoSlider } from 'react-photo-view';
import 'react-photo-view/dist/index.css';
import JsTypeFunction from "../../common/JsTypeFunction";

const images2 = ['https://cdn.ellll.com/capphabit/member_school_img1.png', 'https://cdn.ellll.com/capphabit/member_school_img2.png' ]
const images3 = ['https://cdn.ellll.com/capphabit/school_doctor_img1.png','https://cdn.ellll.com/capphabit/school_doctor_img2.png','https://cdn.ellll.com/capphabit/school_doctor_img3.png','https://cdn.ellll.com/capphabit/school_doctor_img4.png','https://cdn.ellll.com/capphabit/school_doctor_img5.png','https://cdn.ellll.com/capphabit/school_doctor_img6.png','https://cdn.ellll.com/capphabit/school_doctor_img7.png','https://cdn.ellll.com/capphabit/school_doctor_img8.png', ]


class MemberLookDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            doctorModal: false,
            photoIndex: 0,
            photoDoctorIndex: 0,
            type: 1,
            id: 1,
            name: '',
        };
    }


    toggleModal = () => {
        window.postMessage('one');
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen }))
    }

    toggleDoctorModal = () => {
        window.postMessage('two');
        this.setState(state => ({ doctorModal: !state.doctorModal }))
    }

    componentDidMount() {
        if(this.props.history.location.state){
            this.setState({
                type: this.props.history.location.state.type,
                name: this.props.history.location.state.name,
                id: this.props.history.location.state.id,
            })
        }
    }

    setPhotoIndex=(index)=>{
        this.setState({
            photoIndex: index
        })
    }

    setPhotoDoctorIndex=(index)=>{
        this.setState({
            photoDoctorIndex: index
        })
    }

    handlePay=()=>{
        const {id, name}=this.state;
        this.props.history.push({
            pathname: '/addChild',
            state: {
                id: id,
                name: name,
            }
        })
    }

    // 跳抓
    handleSkip=()=>{
        this.props.history.push('download')
    }

    render() {
        const {userData} =this.props.UserDataReducer;
        const { modalIsOpen,photoIndex,doctorModal,photoDoctorIndex }=this.state
        return (
            <div className={"member_view"}>
                {/*<HeaderView*/}
                {/*    title={'创建孩子'}*/}
                {/*/>*/}
                {/*<div className={'row_between title_text'} style={{background: '#495272',color: '#fff'}}>*/}
                {/*    <img src={left_white_icon} alt="" className={'back_white_icon'} onClick={()=>this.props.history.goBack()}/>*/}
                {/*    <div>*/}
                {/*        私教会员*/}
                {/*    </div>*/}
                {/*    <img src={'https://cdn.ellll.com/capphabit/back_black_icon.png'} alt="" className={'back_white_icon'} style={{opacity: 0}}/>*/}
                {/*</div>*/}
                {/*<div className={'member_top_title'}>*/}
                {/*    创建孩子，加入私教会员*/}
                {/*</div>*/}
                {/*<div className={'row_center ph30'} style={{background: '#495272',color: '#fff',paddingTop: '1rem'}}>*/}
                {/*    <div className={'member_top_div'}>*/}
                {/*        私教班服务仅为习惯公社私教会员提供<br/>*/}
                {/*        欢迎成为习惯公社私教会员*/}
                {/*    </div>*/}
                {/*</div>*/}
                <img src={JsTypeFunction.handleImgAddr('member_new_img1')} alt="" className={'member_view_img'}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img2')} alt="" className={'member_view_img'}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img3')} alt="" className={'member_view_img'} onClick={this.toggleModal}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img4')} alt="" className={'member_view_img'}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img5')} alt="" className={'member_view_img'}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img6')} alt="" className={'member_view_img'}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img7')} alt="" className={'member_view_img'}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img8')} alt="" className={'member_view_img'} onClick={this.toggleDoctorModal}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img9')} alt="" className={'member_view_img'}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img10')} alt="" className={'member_view_img'}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img11')} alt="" className={'member_view_img'}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img12')} alt="" className={'member_view_img'}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img13')} alt="" className={'member_view_img'}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img14')} alt="" className={'member_view_img'}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img15')} alt="" className={'member_view_img'}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img16')} alt="" className={'member_view_img'}/>
                <img src={JsTypeFunction.handleImgAddr('member_new_img17')} alt="" className={'member_view_img'}/>
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img1.png'} alt="" className={'member_view_img'}/>*/}
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img2.png'} alt="" className={'member_view_img'}/>*/}
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img3.png'} alt="" className={'member_view_img'}/>*/}
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img4.png'} alt="" className={'member_view_img'} onClick={this.toggleModal}/>*/}
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img5.png'} alt="" className={'member_view_img'} />*/}
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img6.png'} alt="" className={'member_view_img'}/>*/}
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img7.png'} alt="" className={'member_view_img'}/>*/}
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img8.png'} alt="" className={'member_view_img'} onClick={this.toggleDoctorModal}/>*/}
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img9.png'} alt="" className={'member_view_img'}/>*/}
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img10.png'} alt="" className={'member_view_img'}/>*/}
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img11.png'} alt="" className={'member_view_img'}/>*/}
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img12.png'} alt="" className={'member_view_img'}/>*/}
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img13.png'} alt="" className={'member_view_img'}/>*/}
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img14.png'} alt="" className={'member_view_img'}/>*/}
                {/*<img src={'https://cdn.ellll.com/capphabit/member_img15.png'} alt="" className={'member_view_img'}/>*/}
                {/*<div className={'invite_join_view row_between add_child_btn member_bottom_div'}>*/}
                {/*    <div className={'invite_join_btn row_center'} onClick={this.handleSkip} style={{width: '45%',background: '#F6F7F8',color: '#171819',marginBottom: 0,fontSize: '0.5rem'}}>*/}
                {/*        下载APP，去体验一下*/}
                {/*    </div>*/}
                {/*    <div className={'invite_join_btn row_center'} style={{width: '45%',marginBottom: 0,fontSize: '1rem'}} onClick={this.handlePay}>*/}
                {/*        下一步*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className={'row_center member_btn_view'}>*/}
                {/*    <div className={'row_center member_btn_div'} onClick={()=>this.props.history.push('pay')}>*/}
                {/*        开通私教会员*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<ModalGateway>*/}
                {/*    {modalIsOpen ? (*/}
                {/*        <Modal onClose={this.toggleModal}>*/}
                {/*            <Carousel views={images} />*/}
                {/*        </Modal>*/}
                {/*    ) : null}*/}
                {/*</ModalGateway>*/}
                {/*<PhotoProvider>*/}
                {/*    {images2.map((item, index) => (*/}
                {/*        <PhotoConsumer key={index} src={item} intro={item}>*/}
                {/*            <img src={item} alt="" />*/}
                {/*        </PhotoConsumer>*/}
                {/*    ))}*/}
                {/*</PhotoProvider>*/}
                <PhotoSlider
                    images={images2.map(item => ({ src: item }))}
                    visible={modalIsOpen}
                    onClose={this.toggleModal}
                    index={photoIndex}
                    onIndexChange={this.setPhotoIndex}
                />
                <PhotoSlider
                    images={images3.map(item => ({ src: item }))}
                    visible={doctorModal}
                    onClose={this.toggleDoctorModal}
                    index={photoDoctorIndex}
                    onIndexChange={this.setPhotoDoctorIndex}
                />
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(MemberLookDetail)
