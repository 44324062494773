import React, { Component } from 'react'
import { withRouter, } from 'react-router-dom'
import xggs_logo from "../../image/spell/xggs_logo.png";
import {qiNiuIP, shareIP} from "../../common/Config";
import JsTypeFunction from "../../common/JsTypeFunction";
import {Toast} from "antd-mobile";
class SpellStoreGoods extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {
    }

    // 跳转到详情
    handleSkipDetails = () => {
        let login_token = window.localStorage.getItem("login_token")?window.localStorage.getItem("login_token"):'';
        if(!login_token){
            let url = decodeURI(window.location.href);
            Toast.info('请登录！')
            setTimeout(() => {
                Toast.hide()
                this.props.history.push({
                    pathname: '/loginPage',
                    state: {
                        url: url,
                        type: 4, //1下载  2邀请
                    }
                })
            }, 2000)
            return null;
        }
        const {goodObj}=this.props;
        if(goodObj.type==1){
            this.props.history.push({
                pathname: '/spellClassHome',
                state: {
                    id: goodObj.id,
                }
            })
            // this.props.navigation.push('spellClassHome',{id: goodObj.id});
        }else{
            this.props.history.push({
                pathname: '/purchaseGoodsDetails',
                state: {
                    id: goodObj.id,
                }
            })
            // this.props.navigation.push('purchaseGoodsDetails',{id: goodObj.id});
        }
    };

    render() {
        const { goodObj }=this.props;
        if(!goodObj){
            return null;
        }
        return (
            <div onClick={this.handleSkipDetails}>
                <img  src={goodObj.image.length>0?`${qiNiuIP}${goodObj.image[0]}`:xggs_logo} alt="" className={'spell_store_goods_img'}/>
                <div className={'spell_store_goods_title'}>{goodObj.name}</div>
                <div className={'row_between'}>
                    <div className={'spell_store_goods_price'}>¥{goodObj.price_sell}</div>
                    <div className={'spell_store_goods_num'}>已拼{JsTypeFunction.digitalDispose(goodObj.sales_volume)}{goodObj.type==1?'班':'单'}</div>
                </div>

            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default withRouter(SpellStoreGoods);
