import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";
import {InputItem, Toast, Carousel, Modal} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";
import FloatFooter from "../../component/Footer/FloatFooter";
import Hls from "hls.js";
import RecommendedShowList from "../../component/Show/RecommendedShowList";
import msg_zan_icon from "../../image/msg_zan_icon.png";
import share_video_icon from "../../image/share_video_icon.png";
const alert = Modal.alert;
class ShowShareXyb extends Component {

    constructor(props) {
        super(props);
        this.state = {
            articleId: 1,
            articleObj: null,
        };
    }

    componentDidMount() {
        let obj=this.getUrlParams();
        console.log(obj);
        if(obj.id){
            this.setState({
                articleId: obj.id,
            },()=>{
                this.handleRefresh();
            })
        }else{
            Toast.info('您查看的秀一秀已失效')
        }
    }


    getUrlParams = () => {
        let url = decodeURI(window.location.href);
        let index = url.indexOf('?')
        let obj = {}
        if (index !== -1) {
            let str = url.split('?')[1]
            let arr = str.split('&')
            for (let i = 0; i < arr.length; i++) {
                obj[arr[i].split('=')[0]] = arr[i].split('=')[1]
            }
        }
        return obj//{articleId: "1", articleNum: "1", opt: "edit"}
    }


    // 家长学堂获取数据
    handleRefresh = () => {
        const { articleId }=this.state;
        util.getYangAxios(
            api.schoolShow,
            {
                id: articleId,
            },
            (res) => {
                console.log(res);
                this.setState({
                    articleObj: res
                },()=>{
                    var video = document.getElementById('video');
                    var hls = new Hls();
                    hls.loadSource((res.is_new==2?qiNiuIP:'')+res.u8_video);
                    hls.attachMedia(video);
                    hls.on(Hls.Events.MANIFEST_PARSED,function() {
                        video.play();
                    })
                })
            },
            (err) => {
                console.log(err);
                // Toast.fail('服务器异常,请稍后重试')
            },
        );
    };

    // 前往下载
    handleDoload=()=>{
        this.props.history.push('download')
    }

    handleGoApp=()=>{
        alert('提示', '您将打开习惯公社私教版APP', [
            { text: '取消', onPress: () => console.log('cancel') },
            { text: '确认', onPress: () => {this.handleDoload()} },
        ])
    }

    render() {
        const {userData} =this.props.UserDataReducer;
        const {articleObj }=this.state
        return (
            <div className={"article_view"}>
                <div className={'row_center article_header_div animate__animated animate__zoomIn'} style={{marginBottom: 0}}>
                    秀一秀
                </div>
                {
                    articleObj&&<>
                        {
                            articleObj.u8_video&&<div className={"parent_video_view"}>
                                <video src={(articleObj.is_new==2?qiNiuIP:'')+articleObj.u8_video} id={'video'}  controls="controls" className={"parent_video_view"}>
                                    您的浏览器不支持播放该视频。
                                </video>
                            </div>
                        }
                        {
                            articleObj.photo.length>0&& <Carousel
                                autoplay={true}
                                infinite
                                dotActiveStyle={{ backgroundColor: '#FE6B02'}}
                                dotStyle={{backgroundColor: '#fff'}}
                            >
                                {articleObj.photo.map((item,index) => (
                                    <div className={"parent_video_view row_center"} key={index}>
                                        <img
                                            src={`${qiNiuIP}${item}`}
                                            alt=""
                                            className={'parent_video_view'}
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        }
                        <div className={'ph30 row_between mv30'}>
                            {
                                articleObj.department&& <div className={'row_align'}>
                                    <img
                                        src={articleObj.department.icon}
                                        alt=""
                                        className={'show_user_detail_logo'}
                                    />
                                    <div className={'show_user_detail_div'}>
                                        <div className={'show_user_detail_new_title'}>{articleObj.name}</div>
                                        <div className={'show_user_detail_des'}>{articleObj.department && articleObj.is_hide==1?`${articleObj.department.name.charAt(0)}**`:articleObj.department.name}{articleObj.department && articleObj.department.title_name}#{articleObj.department && articleObj.department.group && articleObj.department.group.name}</div>
                                        <div className={'show_user_detail_date'}>{JsTypeFunction.toCnGreeknTimeFormat(articleObj.updated_at)}</div>
                                    </div>
                                </div>
                            }
                            <div className={'row_align'}>
                                <img
                                    src={msg_zan_icon}
                                    alt=""
                                    className={'msg_zan_icon'}
                                    onClick={this.handleGoApp}
                                />
                                <img
                                    src={share_video_icon}
                                    alt=""
                                    className={'share_video_icon'}
                                    onClick={this.handleGoApp}
                                />
                            </div>
                        </div>
                        <div className={'gray_line mb30'}></div>
                    </>
                }
                <RecommendedShowList/>
                <FloatFooter/>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(ShowShareXyb)
