import React, { Component } from "react"
import { Button, InputItem, Toast, Picker, Icon, TextareaItem} from "antd-mobile"
import { Input } from "antd"
import ss from "./css/index.module.css"
import { util } from "../../common/util"
import { api } from "../../common/Config"

export default class activation extends Component {
  state = {
    phone: "",
    real_name: "",
    mail: "",
    typeValue: [],
    typeLabel: "",
    typeData: [
      {
        label: "电话回复",
        value: 1,
      },
      {
        label: "邮件回复",
        value: 2,
      },
    ], 
    content:''

  }
  render() {
    const {  phone, real_name, mail, typeValue, typeLabel, typeData, content } = this.state
    return (
      <div className={`${ss.container_view} npn_common`}>
         <div className={ss.container_title}>请留言，我们必将给您回复。</div>
        <div className={`${ss.input_lable}`}><span className={`${ss.input_lable_tag}`}>*</span>留言方式</div>
         <div  style={{padding:'0 15px'}}>
            <Picker
              cols={1}
              data={typeData}
              value={typeValue}
              className="forss"
              onChange={(v) => {
                this.setState({
                  typeValue: v,
                  typeLabel: this.getGradeLabel(v, "typeData"),
                })
              }}
              onOk={(v) => {
                this.setState({
                  typeValue: v,
                  typeLabel: this.getGradeLabel(v, "typeData"),

                })
              }}
            >
              {
                <div className={ss.input_view_value_picker_content} >
                  <Input
                    className={ss.input_view_value_picker}
                    readOnly
                    value={typeLabel}
                    placeholder="请选择留言方式"
                  />
                  <Icon type={"down"} className={ss.iconPosiAct} />
                </div>
              }
            </Picker>
          </div>
          <div className={ss.input_view_value_picker_tag}>请留下电话号码，以便客服与您取得联系。</div>
        <div>
        <div className={`${ss.input_lable}`}>姓名</div>
          <div  style={{padding:'0 15px'}}>
            <div className={ss.posiNull}>
              <InputItem
                placeholder="请输入姓名"
                className={ss.input_view_activation}
                value={real_name}
                onChange={(val) => {
                  this.setState({
                    real_name: val,
                  })
                }}
              />
            </div>
          </div>
        <div className={`${ss.input_lable}`}><span className={`${ss.input_lable_tag}`}>*</span>手机号码</div>
          <div className={ss.input_view_container}>
            <InputItem
              placeholder="请输入手机号码"
              className={ss.input_view_activation}
              value={phone}
              onChange={(val) => {
                this.setState({
                  phone: val,
                })
              }}
            />
          </div>
     
          <div className={`${ss.input_lable}`}>电子邮件</div>
          <div className={ss.input_view_container}>
            <InputItem
              placeholder="电子邮件"
              className={ss.input_view_activation}
              value={mail}
              onChange={(val) => {
                this.setState({
                  mail: val,
                })
              }}
            />
          </div>
          <div className={`${ss.input_lable}`}><span className={`${ss.input_lable_tag}`}>*</span>留言内容</div>
          <div  >
            <TextareaItem
            style={{border:'1px solid #DBDBDB',width:'96%'}}
              placeholder="留言内容"
            //   className={ss.input_view_activation}
              value={content}
              rows={5}
              onChange={(val) => {
                this.setState({
                    content: val,
                })
              }}
            />
          </div>
         
        </div>

        <Button
          type="primary"
          className={ss.orange_btn_view}
          onClick={this.goActive}
        >
          提交
        </Button>
      </div>
    )
  }
  getGradeLabel = (v, arr = 'typeData') => {
    let value = ""
    this.state[arr].forEach((item) => {
      if (item.value === v[0]) {
        value = item.label
      }
    })
    return value
  }
  isNull = (obj) => {
    let flag = false
    if (obj == "" || obj == undefined || obj == null) {
      flag = true
    }
    return flag
  }

  goActive = () => {
    const { phone, mail, real_name, typeValue,content } = this.state
    if(typeValue.length<1){
        Toast.info("请选择留言方式", 1)
          return 
    }
    if (this.isNull(phone)) {
      Toast.info("请输入手机号码", 1)
      return
    }
    if (this.isNull(content)) {
        Toast.info("请输入留言", 1)
        return
      }
   
    let type = typeValue[0]
    // console.log(typeValue,type)
    // return
    util.postYangAxios(
        api.reply,
      { phone, real_name, mail, type,content },
      async (res) => {
        Toast.success("留言成功", 1)
        this.setState({
            phone: "",
            real_name: "",
            mail: "",
            content:""
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }
}
