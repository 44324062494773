import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/spellHome.css'
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";
import integral_back_img from "../../image/integral_back_img.png";
import xggs_logo from "../../image/spell/xggs_logo.png";
import {Rate} from "antd";
import SpellStoreGoods from "../../component/spell/SpellStoreGoods";
import InfiniteScroll from "react-infinite-scroll-component";


class spellStoreHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            shopList: [],
            goodsList: [],
            shopObj: null,
            page: 1,
        };
    }

    componentDidMount() {
        let obj=JsTypeFunction.getUrlParams();
        console.log(obj);
        if(obj.id){
            this.setState({
                id: obj.id,
            },()=>{
                this.handleGetShop();
                this.onRefresh(true);
            })
        }else{
            console.log(this.props.history.location.state);
            if(this.props.history.location.state&&this.props.history.location.state.id){
                this.setState({
                    id: this.props.history.location.state.id,
                },()=>{
                    this.handleGetShop();
                    this.onRefresh(true);
                })
            }else{
                Toast.info('您查看的商家已失效')
            }
        }

    }

    // 获取商铺信息
    handleGetShop=()=>{
        const { page, id , goodsList}=this.state;
        util.getYangAxios(
            api.goodShop+`/${id}`,
            {
            },
            (res) => {
                console.log(res);
                this.setState({
                    shopObj: res,
                });
            },
            (err) => {
                console.log(err);
            },
            true,
        );
    }

    // 刷新
    onRefresh = (ref) => {
        const { page, id , goodsList}=this.state;
        console.log(api.goodShop+`/${id}/good`)
        util.getYangAxios(
            api.goodShop+`/${id}/good`,
            {
                page: ref?1:page,
            },
            (res) => {
                console.log(res);
                if(res.data.length==0){
                    return null;
                }
                this.setState({
                    goodsList: ref?res.data:goodsList.concat(res.data),
                    page: ref?2:(page+1),
                });
            },
            (err) => {
                console.log(err);
            },
            true,
        );
    };

    handleBack=()=>{
        this.props.history.goBack();
    }


    render() {
        const {userData} =this.props.UserDataReducer;
        const { goodsList, shopObj }=this.state
        return (
            <div className={"article_view"} style={{background: '#F4F4F6'}}>
               <div style={{background: '#fff'}}>
                   <div className={'Spell_store_back_view'}>
                       <img src={integral_back_img} alt="" className={'integral_back_img'} onClick={this.handleBack}/>
                   </div>
                   {
                       shopObj&&<div className={'spell_store_details_view'}>
                           <div className={'row_align'}>
                               <img src={shopObj.logo?`${qiNiuIP}${shopObj.logo}`:xggs_logo} alt="" className={'spell_store_logo'}/>
                               <div>
                                   <div className={'spell_store_name_text'}>
                                       {shopObj.name}
                                   </div>
                                   <div className={'spell_store_num_text'}>已拼{JsTypeFunction.digitalDispose(shopObj.sales_volume)}件</div>
                               </div>
                           </div>
                           <div className={'row_align'} style={{marginTop: '0.8rem'}}>
                               <div className={'spell_store_score_text'}>
                                   评价
                               </div>
                               <Rate disabled defaultValue={shopObj.star} className={'spell_store_score_num'}/>
                           </div>
                       </div>
                   }

               </div>
                <div className={'row_center spell_store_score_list_title'}>
                    全部商品
                </div>
                <InfiniteScroll
                    dataLength={goodsList.length}
                    next={()=>this.onRefresh(false)}
                    hasMore={true}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>暂无更多</b>
                        </p>
                    }
                >
                    <div className={'row_between_warp ph30'}>
                        {goodsList.map((item, index) => (
                            <SpellStoreGoods key={index} goodObj={item}/>
                        ))}
                    </div>
                </InfiniteScroll>


            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(spellStoreHome)
