import Login from './page/users/login'
import JpbPrivacy from './page/users/jpbPrivacy'
import SjbPrivacy from './page/users/sjbPrivacy'
import XybPrivacy from './page/users/xybPrivacy'
import XybProtocol from './page/users/xybProtocol'
import SjbProtocol from './page/users/sjbProtocol'
import JpbProtocol from './page/users/jpbProtocol'
import Invite from "./page/invite/invite";
import InviteSuccessful from "./page/invite/InviteSuccessful";
import Download from "./page/pay/download";
import DownloadXyb from "./page/pay/downloadXyb";
import DownloadJpb from "./page/pay/downloadJpb";
import Article from "./page/article/article";
import MemberDetail from "./page/home/memberDetail";
import MemberLookDetail from "./page/home/memberLookDetail";
import Appraisal from "./page/appraisal/appraisal";
import AppraisalReport from "./page/appraisal/appraisalReport";
import XggsAppraisal from "./page/appraisal/xggsAppraisal";
import XggsAppraisalReport from "./page/appraisal/xggsAppraisalReport";
import SelectChild from "./page/club/selectChild";
import AddChild from "./page/club/addChild";
import PerfectInformation from "./page/club/perfectInformation";
import InviteClub from "./page/invite/inviteClub";
import VideoShare from "./page/share/videoShare";
import VideoShareTwo from "./page/share/videoShareTwo";
import mapPage from "./page/share/mapPage";
import Extend from "./page/share/extend";
import ParentsSchool from "./page/parents/parentsSchool";
import ParentsSchoolXyb from "./page/parents/parentsSchoolXyb";
import ParentsGoodsSchool from "./page/parents/ParentsGoodsSchool";
import ExpertsSchool from "./page/parents/expertsSchool";
import SpeciallyHabit from "./page/parents/speciallyHabit";
import SingleHabit from "./page/parents/singleHabit";
import AppraisalContent from "./page/parents/appraisalContent";
import ImgLang from "./page/share/imgLang";
import InvitePrincipal from "./page/jpb/invitePrincipal";
import InviteJpbSuccess from "./page/jpb/InviteJpbSuccess";
import InviteJpbPatriarch from "./page/jpb/inviteJpbPatriarch";

import Message from "./page/Message/index"

// 拼
import spellClassHome from "./page/spell/spellClassHome";
import spellStoreHome from "./page/spell/spellStoreHome";
import addressList from "./page/spell/addressList";
import addAddress from "./page/spell/addAddress";
import spellInvitation from "./page/spell/spellInvitation";
import purchaseGoodsDetails from "./page/spell/purchaseGoodsDetails";
import ShowShareXyb from "./page/share/showShareXyb";
import ShowShareSjb from "./page/share/showShareSjb";

const routes = [
    {path: "/", name: "download", component: Download, auth: true},
    {path: "/login", name: "login", component: Login, auth: false},
    {path: "/loginPage", name: "loginPage", component: Login, auth: false},
    {path: "/memberDetail", name: "memberDetail", component: MemberDetail, auth: false},
    {path: "/memberLookDetail", name: "memberLookDetail", component: MemberLookDetail, auth: false},
    {path: "/invite", name: "invite", component: Invite, auth: false},
    {path: "/inviteClub", name: "inviteClub", component: InviteClub, auth: false},
    {path: "/inviteSuccessful", name: "inviteSuccessful", component: InviteSuccessful, auth: false},
    {path: "/article", name: "article", component: Article, auth: false},
    {path: "/download", name: "download", component: Download, auth: false},
    {path: "/downloadXyb", name: "downloadXyb", component: DownloadXyb, auth: false},
    {path: "/downloadJpb", name: "downloadJpb", component: DownloadJpb, auth: false},
    {path: "/appraisal", name: "appraisal", component: Appraisal, auth: false},
    {path: "/appraisalReport", name: "appraisalReport", component: AppraisalReport, auth: false},
    {path: "/xggsAppraisal", name: "xggsAppraisal", component: XggsAppraisal, auth: false},
    {path: "/xggsAppraisalReport", name: "xggsAppraisalReport", component: XggsAppraisalReport, auth: false},
    {path: "/selectChild", name: "selectChild", component: SelectChild, auth: false},
    {path: "/addChild", name: "addChild", component: AddChild, auth: false},
    {path: "/videoShare", name: "videoShare", component: VideoShare, auth: false},
    {path: "/videoShareTwo", name: "videoShareTwo", component: VideoShareTwo, auth: false},
    {path: "/showShareXyb", name: "showShareXyb", component: ShowShareXyb, auth: false},
    {path: "/showShareSjb", name: "showShareSjb", component: ShowShareSjb, auth: false},
    {path: "/perfectInformation", name: "perfectInformation", component: PerfectInformation, auth: false},
    {path: "/parentsSchool", name: "parentsSchool", component: ParentsSchool, auth: false},
    {path: "/parentsSchoolXyb", name: "parentsSchoolXyb", component: ParentsSchoolXyb, auth: false},
    {path: "/parentsGoodsSchool", name: "parentsGoodsSchool", component: ParentsGoodsSchool, auth: false},
    {path: "/expertsSchool", name: "expertsSchool", component: ExpertsSchool, auth: false},
    {path: "/speciallyHabit", name: "speciallyHabit", component: SpeciallyHabit, auth: false},
    {path: "/singleHabit", name: "singleHabit", component: SingleHabit, auth: false},
    {path: "/appraisalContent", name: "appraisalContent", component: AppraisalContent, auth: false},
    {path: "/imgLang", name: "imgLang", component: ImgLang, auth: false},
    {path: "/mapPage", name: "mapPage", component: mapPage, auth: false},
    {path: "/extend", name: "extend", component: Extend, auth: false},
    {path: "/invitePrincipal", name: "invitePrincipal", component: InvitePrincipal, auth: false},
    {path: "/inviteJpbSuccess", name: "inviteJpbSuccess", component: InviteJpbSuccess, auth: false},
    {path: "/inviteJpbPatriarch", name: "inviteJpbPatriarch", component: InviteJpbPatriarch, auth: false},
    {path: "/jpbPrivacy", name: "jpbPrivacy", component: JpbPrivacy, auth: false},
    {path: "/sjbPrivacy", name: "sjbPrivacy", component: SjbPrivacy, auth: false},
    {path: "/jpbProtocol", name: "jpbProtocol", component: JpbProtocol, auth: false},
    {path: "/sjbProtocol", name: "sjbProtocol", component: SjbProtocol, auth: false},
    {path: "/xybPrivacy", name: "xybPrivacy", component: XybPrivacy, auth: false},
    {path: "/xybProtocol", name: "xybProtocol", component: XybProtocol, auth: false},
    {path: "/message", name: "Message", component: Message, auth: false},
    
    // 拼
    {path: "/spellClassHome", name: "spellClassHome", component: spellClassHome, auth: false},
    {path: "/spellStoreHome", name: "spellStoreHome", component: spellStoreHome, auth: false},
    {path: "/addressList", name: "addressList", component: addressList, auth: false},
    {path: "/addAddress", name: "addAddress", component: addAddress, auth: false},
    {path: "/spellInvitation", name: "spellInvitation", component: spellInvitation, auth: false},
    {path: "/purchaseGoodsDetails", name: "purchaseGoodsDetails", component: purchaseGoodsDetails, auth: false},
]
// auth 是否需要登录
export default routes ;
