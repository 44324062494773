import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/parentsSchool.css'
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";
import lock_white_menu_img from '../../image/lock_white_menu_img.png'
import EmptyStatePage from "../../component/Empty/EmptyStatePage";
import FloatFooter from "../../component/Footer/FloatFooter";

class ParentsGoodsSchool extends Component {

    constructor(props) {
        super(props);
        this.state = {
            articleId: 1,
            type: 1,
            articleObj: null,
            tabList: [{name: '详情',id: 1},{name: '视频课',id: 2},],
            tabIndex: 1,
            parentsSchoolObj: null,
            parentsSchoolList: [],
        };
    }

    componentDidMount() {
        let obj=this.getUrlParams();
        console.log(obj);
        if(obj.id){
            this.setState({
                articleId: obj.id,
                type: obj.type,
            },()=>{
                this.handleRefresh();
            })
        }else{
            Toast.info('您查看的文章已失效')
        }
    }


    getUrlParams = () => {
        let url = decodeURI(window.location.href);
        let index = url.indexOf('?')
        let obj = {}
        if (index !== -1) {
            let str = url.split('?')[1]
            let arr = str.split('&')
            for (let i = 0; i < arr.length; i++) {
                obj[arr[i].split('=')[0]] = arr[i].split('=')[1]
            }
        }
        return obj//{articleId: "1", articleNum: "1", opt: "edit"}
    }


    // 家长学堂获取数据
    handleRefresh = () => {
        const { articleId,type }=this.state;
        util.getYangAxios(
            api.course,
            {
                id: articleId,
                // type,
            },
            (res) => {
                console.log(res);
                if(res.web_url){
                    window.location.href=res.web_url
                }else{
                    this.setState({
                        articleObj: res
                    })
                }
            },
            (err) => {
                console.log(err);
                // Toast.fail('服务器异常,请稍后重试')
            },
        );
        util.getYangAxios(
            api.courseVideo,
            {
                course_id: articleId,
                // type
            },
            (res) => {
                this.setState({
                    parentsSchoolList: res,
                    parentsSchoolObj: res[0],
                })
            },
            (err) => { console.log(err) },
            true,
        )
    };

    // 前往下载
    handleDoload=()=>{
        this.props.history.push('download')
    }

    // 提示下载
    handleToastDown=()=>{
        Toast.info('下载习惯公社APP观看更多精彩课程！')
    }

    // 渲染tab栏
    handleTabRender=()=>{
        const {articleObj, tabIndex, parentsSchoolList }=this.state
        if(tabIndex==1){
            return (
                <div>
                    <EmptyStatePage/>
                </div>
            )
        }
        return (
           <>
               {
                   parentsSchoolList.length>0&&parentsSchoolList.map((item,index)=>{
                       return (
                           <div className={'article_top_div row_align animate__animated animate__fadeInUp'} key={index} style={{marginBottom: '1.6rem'}} onClick={this.handleToastDown}>
                               <div style={{backgroundSize: 'cover', backgroundImage: `url(https://cdn.ellll.com/${item.cover}?imageView2/5/q/75|imageslim)`, backgroundRepeat: "no-repeat",}} className={'article_goods_bg_img'}>
                                   {
                                       index!=0&&<div className={'activity_goods_mask column_center'}>
                                           <img src={lock_white_menu_img} alt="" className={'lock_white_menu_img'}/>
                                       </div>
                                   }

                               </div>
                               <div>
                                   <div className={'activity_goods_title'}>{item.name}</div>
                                   <div className={'activity_goods_des'}>{item.des}</div>
                                   <div className={'activity_goods_date'}>{JsTypeFunction.toCnGreeknTimeFormat(item.updated_at)}</div>
                               </div>
                           </div>
                       )
                   })
               }

           </>
        )
    }


    render() {
        const {userData} =this.props.UserDataReducer;
        const {articleObj, type, tabList, tabIndex, parentsSchoolObj }=this.state
        return (
            <div className={"article_view"} style={{paddingBottom: '4rem'}}>
                <div className={'row_center article_header_div animate__animated animate__zoomIn'} style={{marginBottom: 0}}>
                    {type==2?'会员课':'精品课'}
                </div>
                {
                    parentsSchoolObj&&<>
                        <div className={"parent_video_view"}>
                            {
                                parentsSchoolObj.video&&  <video src={qiNiuIP+parentsSchoolObj.video} controls="controls" className={"parent_video_view"}>
                                    您的浏览器不支持播放该视频。
                                </video>
                            }
                            {
                                !parentsSchoolObj.video&&parentsSchoolObj.audio&&  <video src={qiNiuIP+parentsSchoolObj.audio} controls="controls" className={"parent_video_view"} poster={qiNiuIP+parentsSchoolObj.cover}>
                                    您的浏览器不支持播放该视频。
                                </video>
                            }
                        </div>
                        <div className={'activity_gray_line'} style={{marginBottom: '1rem',marginTop: 0}}/>
                        <div className={'row_between activity_good_tab'}>
                            {
                                tabList.map((item,index)=>{
                                    return (
                                        <div className={'column_center'} key={index} onClick={()=>this.setState({tabIndex:item.id})}>
                                            <div className={tabIndex==item.id?'good_tab_select_text':'good_tab_default_text'}>{item.name}</div>
                                            <div className={tabIndex==item.id?'good_tab_select_line':'good_tab_default_line'}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {this.handleTabRender()}
                    </>
                }
                <FloatFooter/>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(ParentsGoodsSchool)
