import './App.css';
// 你的高阶组件
import FrontendAuth from './FrontendAuth';
// 你的路由表
import routes from './routes';
import { BrowserRouter, Switch, HashRouter } from 'react-router-dom'
import {Provider} from 'react-redux';
import configureStore from './store/ConfigureStore';
import 'animate.css'
const store = configureStore();

function App() {
  return (
      <Provider store={store}>
          <BrowserRouter>
              <Switch>
                  <FrontendAuth routerConfig={routes} />
              </Switch>
          </BrowserRouter>
      </Provider>
  );
}

export default App;
