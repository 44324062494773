
// 时间处理
import * as copy from "copy-to-clipboard";
import {Toast} from "antd-mobile";

Date.prototype.format = function (format) {
  const date = {
    'M+': this.getMonth() + 1,
    'd+': this.getDate(),
    'h+': this.getHours(),
    'm+': this.getMinutes(),
    's+': this.getSeconds(),
    'q+': Math.floor((this.getMonth() + 3) / 3),
    'S+': this.getMilliseconds(),
  };
  if (/(y+)/i.test(format)) {
    format = format.replace(
      RegExp.$1,
      `${this.getFullYear()}`.substr(4 - RegExp.$1.length),
    );
  }
  for (const k in date) {
    if (new RegExp(`(${k})`).test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1
          ? date[k]
          : `00${date[k]}`.substr(`${date[k]}`.length),
      );
    }
  }
  return format;
};

// 获取时间差 current:1497235409744 当前时间  start:1497235419744 开始时间
function getRemainingime(current: Number, start: Number) {
  const time = start - current;
  if (time < 0) {
    return ['0', '0', '0', '0', '0', '0'];
  }
  const year = Math.floor(time / (365 * 30 * 24 * 3600 * 1000)); // 年

  const month = Math.floor(time / (30 * 24 * 3600 * 1000)); // 月

  const days = Math.floor(time / (24 * 3600 * 1000)); // 日
  const temp1 = time % (24 * 3600 * 1000);
  const temp2 = temp1 % (3600 * 1000);
  const minutes = Math.floor(temp2 / (60 * 1000)); // 分
  const hours = Math.floor(temp1 / (3600 * 1000)); // 时
  const temp3 = temp2 % (60 * 1000);
  const seconds = Math.round(temp3 / 1000); // 秒

  const strs = [
    year,
    toNormal(month),
    toNormal(days),
    toNormal(hours),
    toNormal(minutes),
    toNormal(seconds),
  ];
  return strs; // ["0", "0", "2", "7", "33", "30"]0年0月2日 7时33分30秒
}

// 1497235419
function getRemainingimeDistance(distance: Number) {
  const time = distance * 1000;
  if (time < 0) {
    return ['0', '0', '0', '0', '0', '0'];
  }

  const year = Math.floor(time / (365 * 30 * 24 * 3600 * 1000)); // 年

  const month = Math.floor(time / (30 * 24 * 3600 * 1000)); // 月

  const days = Math.floor(time / (24 * 3600 * 1000)); // 日
  const temp1 = time % (24 * 3600 * 1000);
  const hours = Math.floor(temp1 / (3600 * 1000)); // 时
  const temp2 = temp1 % (3600 * 1000);
  const minutes = Math.floor(temp2 / (60 * 1000)); // 分
  const temp3 = temp2 % (60 * 1000);
  const seconds = Math.round(temp3 / 1000); // 秒

  const strs = [
    year,
    toNormal(month),
    toNormal(days),
    toNormal(hours),
    toNormal(minutes),
    toNormal(seconds),
  ];
  // strs.splice(0, 1, String(Number(strs[0]) - 1970));//年
  // strs.splice(1, 1, String(Number(strs[1]) - 1));
  // strs.splice(2, 1, (Number(strs[2]) - 1) < 10 ? '0' + (Number(strs[2]) - 1) : String(Number(strs[2]) - 1));
  // strs.splice(3, 1, (Number(strs[3]) - 8) < 10 ? '0' + (Number(strs[3]) - 8) : String(Number(strs[3]) - 8));
  // strs.splice(4, 1, Number(strs[4]) < 10 ? '0' + Number(strs[4]) : String(Number(strs[4])));
  // strs.splice(5, 1, Number(strs[5]) < 10 ? '0' + Number(strs[5]) : String(Number(strs[5])));
  return strs; // ["0", "0", "2", "7", "33", "30"]0年0月2日 7时33分30秒
}

function toNormal(time: Number) {
  return time >= 10 ? time : `0${time}`;
}

// 转换成日期
function toDate(timestamp: Number, format1 = 'yyyy-MM-dd hh:mm:ss') {
  try {
    if (timestamp > 10000) {
      const date = new Date();
      date.setTime(timestamp);
      return date.format(format1); // 2014-07-10 10:21:12
    }
    return '';
  } catch (erro) {
    return '';
  }
  return '';
}

// 转换成时间搓
function toTimestamp(date: String) {
  const timestamp = Date.parse(date);
  return timestamp / 1000; // 1497233827569/1000
}

// 时间搓转换成年月日
function toTimeFormat(dateInt: String) {
  // 比如需要这样的格式 yyyy-MM-dd hh:mm:ss
  let date = new Date(parseInt(dateInt) * 1000);
  let Y = date.getFullYear() + '-';
  let M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-';
  let D = date.getDate() + 1 < 10 ? '0' + date.getDate() : date.getDate();
  let str = Y + M + D;
  console.log(str);
  return str;
}

// 当前时间年月日
function toPresentTimeFormat(dateInt: String) {
  let date = new Date();
  let Y = date.getFullYear() + '-';
  let M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-';
  let D = date.getDate() + 1 < 10 ? '0' + date.getDate() : date.getDate();
  let str = Y + M + D;
  console.log(str);
  return str;
}

// 当前时间年月
function toYearsTimeFormat(dateInt: String) {
  let date = new Date();
  let Y = date.getFullYear() + '-';
  let M =
    date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let str = Y + M;
  console.log(str);
  return str;
}

// 格林时间转换年月日
function toGreeknTimeFormat(dateInt: String) {
  let date = new Date(dateInt);
  let Y = date.getFullYear() + '-';
  let M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-';
  let D = date.getDate() + 1 < 10 ? '0' + date.getDate() : date.getDate();
  let str = Y + M + D;
  return str;
}

// 格林时间转换年月日
function toCnGreeknTimeFormat(dateInt: String) {
  if(!dateInt){
    return '';
  }
  let date = new Date(Date.parse(dateInt.replace(/-/g, "/")));
  let Y = date.getFullYear() + '年';
  let M =
      (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '月';
  let D = date.getDate() + 1 < 10 ? '0' + date.getDate() : date.getDate();
  let str = Y + M + D+ '日';
  return str;
}

// 格林时间转换年月日
function toGreeknTimeObjFormat(dateInt: String) {
  let date = new Date(dateInt);
  let Y = date.getFullYear();
  let M =
      (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1);
  let D = date.getDate() + 1 < 10 ? '0' + date.getDate() : date.getDate();
  let obj = {
    year: Y,
    month: M,
    date: D,
  };
  return obj;
}

// CST时间=>转换成日期yyyy-MM-dd hh:mm:ss
function getTaskTime(strDate) {
  if (strDate == null || strDate == '') {
    return '';
  }
  const dateStr = strDate.trim().split(' ');
  const strGMT = `${dateStr[0]} ${dateStr[1]} ${dateStr[2]} ${dateStr[5]} ${dateStr[3]} GMT+0800`;
  const date = new Date(Date.parse(strGMT));
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? `0${m}` : m;
  let d = date.getDate();
  d = d < 10 ? `0${d}` : d;
  const h = date.getHours();
  let minute = date.getMinutes();
  minute = minute < 10 ? `0${minute}` : minute;
  let second = date.getSeconds();
  second = second < 10 ? `0${second}` : second;

  return `${y}-${m}-${d} ${h}:${minute}:${second}`;
}

// 1497235419
function getRemainingimeDistance2(distance: Number) {
  const time = distance;
  const days = Math.floor(time / (24 * 3600 * 1000));
  const temp1 = time % (24 * 3600 * 1000);
  const hours = Math.floor(temp1 / (3600 * 1000));
  const temp2 = temp1 % (3600 * 1000);
  let minutes = Math.floor(temp2 / (60 * 1000));
  if (time <= 60 * 1000) {
    minutes = 1;
  }
  const temp3 = temp2 % (60 * 1000);
  const seconds = Math.round(temp3 / 1000);
  return [hours, minutes]; // ["0", "0", "2", "7", "33", "30"]0年0月2日 7时33分30秒
}

function timeago(dateTimeStamp) {
  var dateTimeStamp2 = new Date(dateTimeStamp.replace(/-/g, '/')).getTime();
  var minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
  var hour = minute * 60;
  var day = hour * 24;
  var week = day * 7;
  var halfamonth = day * 15;
  var month = day * 30;
  var now = new Date().getTime(); //获取当前时间毫秒
  // console.log(now)
  var diffValue = now - dateTimeStamp2; //时间差

  if (diffValue < 0) {
    return;
  }
  var minC = diffValue / minute; //计算时间差的分，时，天，周，月
  var hourC = diffValue / hour;
  var dayC = diffValue / day;
  var weekC = diffValue / week;
  var monthC = diffValue / month;
  var result = null;
  if (monthC >= 1 && monthC <= 3) {
    result = ' ' + parseInt(monthC) + '月前';
  } else if (weekC >= 1 && weekC <= 3) {
    result = ' ' + parseInt(weekC) + '周前';
  } else if (dayC >= 1 && dayC <= 6) {
    result = ' ' + parseInt(dayC) + '天前';
  } else if (hourC >= 1 && hourC <= 23) {
    result = ' ' + parseInt(hourC) + '小时前';
  } else if (minC >= 1 && minC <= 59) {
    result = ' ' + parseInt(minC) + '分钟前';
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = '刚刚';
  } else {
    var datetime = new Date();
    datetime.setTime(dateTimeStamp2);
    var Nyear = datetime.getFullYear();
    var Nmonth =
      datetime.getMonth() + 1 < 10
        ? '0' + (datetime.getMonth() + 1)
        : datetime.getMonth() + 1;
    var Ndate =
      datetime.getDate() < 10 ? '0' + datetime.getDate() : datetime.getDate();
    var Nhour =
      datetime.getHours() < 10
        ? '0' + datetime.getHours()
        : datetime.getHours();
    var Nminute =
      datetime.getMinutes() < 10
        ? '0' + datetime.getMinutes()
        : datetime.getMinutes();
    var Nsecond =
      datetime.getSeconds() < 10
        ? '0' + datetime.getSeconds()
        : datetime.getSeconds();
    result = Nyear + '-' + Nmonth + '-' + Ndate;
  }
  return result;
}

// 12/03日期格式转换
function convertDate(str) {
  let s = new Date(str.replace(/-/g, '/'));
  let g = s.getDate();
  let j = s.getMonth() + 1;
  if (g < 10) {
    g = '0' + g;
  }
  if (j < 10) {
    j = '0' + j;
  }
  let t = `${j}/${g}`;
  console.log(t);
  return t;
}

// 12/03日期多少天后的格式转换
function convertFormerlyDate(str, num) {
  let v = new Date(str.replace(/-/g, '/'));
  v.setDate(v.getDate() + num);
  let s = v;
  let g = s.getDate();
  let j = s.getMonth() + 1;
  if (g < 10) {
    g = '0' + g;
  }
  if (j < 10) {
    j = '0' + j;
  }
  let t = `${j}/${g}`;
  console.log(t);
  return t;
}

/**
 * 将阿拉伯数字翻译成中文的大写数字
 */
function numberToChinese(num) {
  var AA = new Array(
    '零',
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '七',
    '八',
    '九',
    '十',
  );
  var BB = new Array('', '十', '百', '仟', '萬', '億', '点', '');
  var a = ('' + num).replace(/(^0*)/g, '').split('.'),
    k = 0,
    re = '';
  for (var i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        re = BB[7] + re;
        break;
      case 4:
        if (!new RegExp('0{4}//d{' + (a[0].length - i - 1) + '}$').test(a[0])) {
          re = BB[4] + re;
        }
        break;
      case 8:
        re = BB[5] + re;
        BB[7] = BB[5];
        k = 0;
        break;
    }
    if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) {
      re = AA[0] + re;
    }
    if (a[0].charAt(i) != 0) {
      re = AA[a[0].charAt(i)] + BB[k % 4] + re;
    }
    k++;
  }

  if (a.length > 1) {
    // 加上小数部分(如果有小数部分)
    re += BB[6];
    for (var i = 0; i < a[1].length; i++) {
      re += AA[a[1].charAt(i)];
    }
  }
  if (re == '一十') {
    re = '十';
  }
  if (re.match(/^一/) && re.length == 3) {
    re = re.replace('一', '');
  }
  return re;
}

/**
 * 判断对象，数组，字符串是否为空
 * @param str  (null|undefined|''|'   '|[]|{}) 均判断为空，返回true
 * @returns {boolean}
 */
function isEmpty(str) {
  if (!str) {
    return true;
  }
  if (typeof str === 'object' && Object.keys(str).length === 0) {
    return true;
  }
  if (str.replace(/(^\s*)|(\s*$)/g, '').length === 0) {
    return true;
  }
  return false;
}

/**
 * 随机背景颜色函数
 */
function rgbBgColor(index){
  var rgb = '';
  if(index==0){
    rgb='#FED8B0'
  } else if(index%8==0){
    rgb='#D4CCF9'
  } else if(index%7==0){
    rgb='#FCE2B7'
  } else if(index%6==0){
    rgb='#B8F9E7'
  } else if(index%5==0){
    rgb='#FDD7B2'
  } else if(index%4==0){
    rgb='#FFEAAA'
  } else if(index%3==0){
    rgb='#FED3D5'
  } else if(index%2==0){
    rgb='#B1EEF8'
  } else if(index==1){
    rgb='#D9D2FA'
  }
  return rgb;
}

function getUrlParams (){
  let url = decodeURI(window.location.href);
  let index = url.indexOf('?')
  let obj = {}
  if (index !== -1) {
    let str = url.split('?')[1]
    let arr = str.split('&')
    for (let i = 0; i < arr.length; i++) {
      obj[arr[i].split('=')[0]] = arr[i].split('=')[1]
    }
  }
  return obj//{articleId: "1", articleNum: "1", opt: "edit"}
}

// 9999+  数字处理方法
function digitalDispose(num){
  if(num<10000){
    return num
  }
  let wan= parseInt(num)/10000
  return parseInt(wan)+'万+'
}

function handleCopy(text){
  copy(text);
  Toast.success('复制成功',1.5)
}

function dateOverCount(date){
  if(!date){
    return '';
  }
  var date1=new Date();  //开始时间
  var date2=new Date(date.replace(/-/g, '/'));    //结束时间
  var date3=date2.getTime()-date1.getTime()  //时间差的毫秒数
  if(date3<=0){
    return '时间已到'
  }

//计算出相差天数
  var days=Math.floor(date3/(24*3600*1000))

//计算出小时数

  var leave1=date3%(24*3600*1000)    //计算天数后剩余的毫秒数
  var hours=Math.floor(leave1/(3600*1000))+days*24
//计算相差分钟数
  var leave2=leave1%(3600*1000)        //计算小时数后剩余的毫秒数
  var minutes=Math.floor(leave2/(60*1000))


//计算相差秒数
  var leave3=leave2%(60*1000)      //计算分钟数后剩余的毫秒数
  var seconds=Math.round(leave3/1000)
  return (hours<10?'0'+hours:hours)+':'+(minutes<10?'0'+minutes:minutes)+':'+(seconds<10?'0'+seconds:seconds)
}

function handleImgAddr(str){
  return `https://cdn.ellll.com/image/${str}.png`
}

export default {
  isEmpty,
  getRemainingime,
  getRemainingimeDistance,
  toDate,
  toTimestamp,
  getTaskTime,
  getRemainingimeDistance2,
  toGreeknTimeObjFormat,
  timeago,
  convertDate,
  convertFormerlyDate,
  toTimeFormat,
  toPresentTimeFormat,
  toYearsTimeFormat,
  toGreeknTimeFormat,
  toCnGreeknTimeFormat,
  rgbBgColor,
  getUrlParams,
  digitalDispose,
  handleCopy,
  dateOverCount,
  handleImgAddr,
};
