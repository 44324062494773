import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import {util} from "../../common/util";
import {api} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";


class SjbPrivacy extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        const {userData} =this.props.UserDataReducer;
        const { }=this.state
        return (
            <div className={"article_view"} style={{paddingBottom: '30px'}}>
                <div style={{fontSize: '32px', fontFamily:'MicrosoftYaHei'}}>习惯公社隐私政策</div>
                <div style={{fontSize: '14px', fontFamily:'MicrosoftYaHei'}}>
                    <p>
                        <span>发布日期：</span>2023年04月11日<br/>
                        <span>生效日期：</span>2023年04月11日
                    </p>
                    <p>
                        <b><span>第一条</span> <span>序言</span></b><b></b>
                    </p>
                    <p>
                        1.习惯公社是由上海形者网络科技发展有限公司开发运营，公司在习惯公社的开发运营过程中非常尊重用户个人信息的保护。<br/>
                        2.在您使用习惯公社平台提供的服务时，习惯公社可能会因为实际业务需要而收集和使用您的相关个人信息。习惯公社希望通过本隐私政策向您清晰地介绍习惯公社对您个人信息的处理方式（包括但不限于个人信息的收集、使用、保护等），请您仔细阅读，在需要时，根据本隐私政策的指引，做出您认为适当的选择。<br/>
                        3.当您以勾选的方式表示接受本隐私政策或在习惯公社更新本隐私政策后（习惯公社将通过推送通知的方式及时提示您更新情况）仍勾选接受的，即表示您认可并接受本政策（含更新版本）的所有内容，并同意习惯公社按本政策收集、使用和保护您的相关个人信息。<br/>
                        4.根据国家互联网信息办公室颁布的《儿童个人信息网络保护规定》，习惯公社在本政策第六条<strong style={{color:'red', fontSize:'16px'}}>未成年人</strong>信息中对您孩子的个人信息做出特殊保护。
                    </p>
                    <p>
                        <b><span>第二条</span> <span>信息的收集和使用</span></b><br/>
                        您理解并同意，习惯公社收集信息是为了向您提供更好、更优的服务，我们将根据合法、正当、必要的原则，仅收集实现业务功能所必要的信息，习惯公社承诺绝不会收集任何与业务功能无关的个人信息和非必要信息。习惯公社提供服务时，可能会收集、储存和使用下列与您有关的信息：<br/>
                        1.1 账户注册
                        首先，您需要注册一个账户成为习惯公社用户。当您注册时，您需要向我们提供您准备使用的您本人的<strong style={{color:'red', fontSize:'16px'}}>手机号码</strong>，届时我们将通过发送短信验证码的方式来验证您的身份是否有效。且若您是教师用户的，那么当您向习惯公社申请学校培训时，系统届时将自动填写您注册账户时所登记的<strong style={{color:'red', fontSize:'16px'}}>手机号码</strong>。<strong style={{color:'red', fontSize:'16px'}}>手机号码</strong>属于敏感信息，但我们收集此类信息是基于国家法律法规要求的网络实名制要求和账户安全保障需要。<strong style={{color:'red', fontSize:'16px'}}>手机号码</strong>届时将由您自行决定是否提供，若您不提供此信息，您可能无法正常使用我们的服务。<br/>
                        1.2为使您获得习惯公社最优的用户体验，我们的以下附加功能中可能会收集和使用您的个人信息。如果您不提供这些个人信息，您依然可以使用习惯公社，但您可能无法使用这些附加功能或需重复填写一些信息。这些附加功能包括：<br/>
                        <span>（</span>1）基于<strong style={{color:'red', fontSize:'16px'}}>位置信息</strong>的附加功能：当您在使用习惯公社中的创建习惯特训营和邀请成员时，您可以选择开通您的位置权限（该定位功能将由高德地图向您提供）以便我们来判断您所处的地点，并使您能够就近选择您所在的区域的体验活动过。<br/>
                        <span>（</span>2）基于<strong style={{color:'red', fontSize:'16px'}}>摄像头</strong>（相机）的附加功能：当您在使用习惯公社中的发通知（此功能仅限教师用户使用）、发布（参与）打卡任务、开（参与）讨论、做（参与）调查、发视频、办（参与）活动、邀请成员（此功能仅限教师用户使用），您可以使用这个附加功能完成视频拍摄和拍照功能，以实现信息的发布。<br/>
                        <span>（</span>3）基于图片上传的附加功能：当您在使用习惯公社中的发通知、发布（参与）打卡任务、开（参与）讨论、做（参与）调查、发视频、办（参与）活动、邀请成员、上传班级<strong style={{color:'red', fontSize:'16px'}}>通讯录</strong>（此功能仅限教师用户使用，下同）、班级文件（此功能仅限教师用户使用，下同）。<br/>
                        <span>（</span>4）基于语音技术的附加功能：当您在使用习惯公社中的发通知、发布（参与）打卡任务、开（参与）讨论、做（参与）调查、发视频、办（参与）活动时，您可以直接使用<strong style={{color:'red', fontSize:'16px'}}>麦克风</strong>功能。在这些功能中我们会收集您的录音内容以具体识别您的需求。<br/>
                        <span>（</span>5）基于<strong style={{color:'red', fontSize:'16px'}}>通讯录</strong>信息的附加功能（此功能仅限教师用户使用）:当您在使用习惯公社中的邀请成员功能时，我们将收集您的<strong style={{color:'red', fontSize:'16px'}}>通讯录</strong>信息以方便您不再手动输入您<strong style={{color:'red', fontSize:'16px'}}>通讯录</strong>中联系人的信息，从而节省您的时间。<strong style={{color:'red', fontSize:'16px'}}>通讯录</strong>信息属于敏感信息，拒绝提供该信息不影响您正常使用习惯公社。<br/>
                        <span>（</span>6）消息推送服务供应商：由阿里云提供推送技术服务，我们可能会将您的设备平台、设备厂商及品牌、设备型号及系统版本、设备识别码、设备序列号等设备信息，应用列表信息、网络信息以及位置相关信息提供给阿里云，用于为您提供推送技术服务。我们在向您推送消息时，我们可能会授权每日互动股份有限公司进行链路调节，相互促活被关闭的SDK推送进程，保障您可以及时接收到我们向您推送的消息。<br/>
                        <span>（</span>7）基于<strong style={{color:'red', fontSize:'16px'}}>IMEI</strong>、<strong style={{color:'red', fontSize:'16px'}}>IMSI</strong>、<strong style={{color:'red', fontSize:'16px'}}>设备MAC地址</strong>、<strong style={{color:'red', fontSize:'16px'}}>软件列表</strong>、<strong style={{color:'red', fontSize:'16px'}}>软件安装列表</strong>、<strong style={{color:'red', fontSize:'16px'}}>设备序列号</strong>、<strong style={{color:'red', fontSize:'16px'}}>androidId</strong>：我们会将收集到的信息进行数据库严格保密，给用户制作唯一标识，为用户的账号安全提供保障。<br/>
                        1.2.1上述附加功能可能需要您在您的设备中向我们开启您的地理位置（<strong style={{color:'red', fontSize:'16px'}}>位置信息</strong>）、相机（<strong style={{color:'red', fontSize:'16px'}}>摄像头</strong>）、<strong style={{color:'red', fontSize:'16px'}}>相册</strong>（<strong style={{color:'red', fontSize:'16px'}}>照片</strong>）、<strong style={{color:'red', fontSize:'16px'}}>麦克风</strong>以及<strong style={{color:'red', fontSize:'16px'}}>通讯录</strong>的访问权限，以实现这些功能所涉及的信息的收集和使用。当您需要关闭该功能时，大多数移动设备都会支持您的这项需求，具体方法请参考或联系您移动设备的服务商或生产商。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。<br/>
                        1.2.2为使您获得习惯公社最优的用户体验，我们的以下附加第三方功能中可能会收集和使用您的个人信息。如果您不提供这些个人信息，您依然可以使用习惯公社，但您可能无法使用这些附加第三方功能或需重复填写一些信息。这些附加功能包括：<br/>
                    </p>
                    <p style={{marginLeft:'18pt', textIndent:'0pt'}}>
                        （1）com.tencent.stat(腾讯移动分析)com.tencent.tauth(腾讯开放平台)获取您的一些基础信息，用于您分享活动新闻等给朋友。
                    </p>
                    <p style={{marginLeft:'18pt', textIndent:'0pt'}}>
                        第三方隐私权政策链接：
                    </p>
                    <p style={{marginLeft:'18pt'}}>
                        <a href="https://cloud.tencent.com/document/product/301/11470"><u><span>https://cloud.tencent.com/document/product/301/11470</span></u></a>&nbsp;
                    </p>
                    <p style={{marginLeft:'18pt', textIndent:'0pt'}}>
                        （2）com.huawei.hms(华为;华为推送)用于向华为手机用户推送实时信息以及一些新闻活动等。
                    </p>
                    <p style={{marginLeft:'18pt', textIndent:'0pt'}}>
                        第三方隐私权政策链接：
                    </p>
                    <p style={{marginLeft:'18pt'}}>
                        <a href="https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177"><u>https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177</u></a>&nbsp;
                        <br/>
                        <span>（</span>3）com.xiaomi.mipush(小米;小米推送)com.xiaomi.push(小米;小米推送)用于向小米手机用户推送实时信息以及一些新闻活动等。
                    </p>
                    <p style={{marginLeft:'18pt', textIndent:'0pt'}}>
                        第三方隐私权政策链接：
                    </p>
                    <p style={{marginLeft:'18pt'}}>
                        <a href="https://dev.mi.com/console/doc/detail?pId=1822"><u><span>https://dev.mi.com/console/doc/detail?pId=1822</span></u></a>&nbsp;
                        <br/>
                        <span>（</span>4）io.agora(声网)用于与好友进行实时音视频通话。
                    </p>
                    <p style={{marginLeft:'18pt', textIndent:'0pt'}}>
                        第三方隐私权政策链接：
                    </p>
                    <p style={{marginLeft:'18pt'}}>
                        <a href="https://www.shengwang.cn/privacy-policy/"><u><span>https://www.shengwang.cn/privacy-policy/</span></u></a>&nbsp;
                        <br/>
                        <span>（</span>5）com.umeng.umsdk(友盟数据统计)会在后台持续获取您的IMSI,ANDROID
                        ID等信息，用于统计用户以及用户喜好，用于使我们更好的优化App。
                    </p>
                    <p style={{marginLeft:'18pt', textIndent:'0pt'}}>
                        第三方隐私权政策链接：
                    </p>
                    <p style={{marginLeft:'18pt'}}>
                        <a href="https://www.umeng.com/policy"><u><span>https://www.umeng.com/policy</span></u></a>&nbsp;
                        <br/>
                        <span>（</span>6）com.alibaba.sdk.android.push(阿里云推送)会在后台持续获取您的IMSI,ANDROID
                        ID等信息，为赋予APP消息和通知的推送能力。
                        <br/>
                        自启动和关联启动说明 <br/>
                        <span>（</span>1）为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的广播信息，本应用须使用(自启动)能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的。<br/>
                        <span>（</span>2）当您打开习惯公社内容类推送消息，在征得您的明确同意后，会跳转习惯公社教培版打开相关内容。在未征得您同意的情况下，则不会有关联启动。<br/>
                        <span>（</span>3）当您打开习惯公社内部下载的文件后，会关联启动第三方APP<br/>
                    </p>
                    <p style={{marginLeft:'18pt', textIndent:'0pt'}}>
                        第三方隐私权政策链接：
                    </p>
                    <p style={{marginLeft:'18pt'}}>
                        <a href="https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112071754_83380.html?spm=a2c4g.434217.0.0.38b4273fw6Zr5Q"><u><span>https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112071754_83380.html?spm=a2c4g.434217.0.0.38b4273fw6Zr5Q</span></u></a>&nbsp;
                    </p>
                    <p style={{marginLeft:'18pt'}}>
                        （7）com.heytap.mcs(OPPO;OPPO推送)用于向OPPO手机用户推送实时信息以及一些新闻活动等。
                    </p>
                    <p style={{marginLeft:'18pt', textIndent:'0pt'}}>
                        第三方隐私权政策链接：
                    </p>
                    <p style={{marginLeft:'18pt'}}>
                        <a href="https://open.oppomobile.com/new/developmentDoc/info?id=10288"><u><span>https://open.oppomobile.com/new/developmentDoc/info?id=10288</span></u></a>&nbsp;
                    </p>
                    <p style={{marginLeft:'18pt'}}>
                        （8）com.taobao.accs移动API网关为企业提供统一的API托管服务，提供API全生命周期管理帮助开发者快速创建、测试、发布、运维API。API网关帮企业进行有效的服务治理，并提供负载均衡、限流、 鉴权、防篡改等能力。 搜集个人信息类型：获取运行中进程信息
                    </p>
                    <p style={{marginLeft:'18pt', textIndent:'0pt'}}>
                        第三方隐私权政策链接：
                    </p>
                    <p style={{marginLeft:'18pt'}}>
                        <a href="http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=a2c4g.49552.J_9220772140.22.7c543983xhqpDy"><u><span>http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=a2c4g.49552.J_9220772140.22.7c543983xhqpDy</span></u></a>&nbsp;
                    </p>
                    <p style={{marginLeft:'18pt'}}>
                        （9）io.rong.push(融云推送)用于向习惯公社用户推送实时信息以及一些新闻活动等。
                    </p>
                    <p style={{marginLeft:'18pt', textIndent:'0pt'}}>
                        第三方隐私权政策链接：
                    </p>
                    <p style={{marginLeft:'18pt'}}>
                        <a href="https://www.rongcloud.cn/privacy?_sasdk=dMTg3N2YwNzE3MTE2MjYtMDE5MDQyNGNkNWNhMzM5LWM0Zjc1MjYtMjcxMDgyNS0xODc3ZjA3MTcxMjczOA"><u><span>https://www.rongcloud.cn/privacy?_sasdk=dMTg3N2YwNzE3MTE2MjYtMDE5MDQyNGNkNWNhMzM5LWM0Zjc1MjYtMjcxMDgyNS0xODc3ZjA3MTcxMjczOA</span></u></a>&nbsp;
                    </p>
                    <p>
                        我们会收集使用实现服务所必须的个人信息，通过加密的通道将消息和通知推送给您。收集使用的个人信息包括：①设备信息：用于识别唯一用户，保证消息推送的精准送达；②软件安装列表：为优化推送通道资源，我们会根据设备上不同应用的活跃情况，整合消息推送的通道资源，提高消息送达率；③网络信息与<strong style={{color:'red', fontSize:'16px'}}>位置信息</strong>：优化与服务器的网络连接请求，保证服务的稳定性和连续性。<br/>
                        1.2.3、为提供服务申请使用的权限:为实现身份验证、拍照拍视频发秀、购物、分享评论、售后沟通及其他功能，我们可能会申请使用您的<strong style={{color:'red', fontSize:'16px'}}>摄像头</strong>(相机)、<strong style={{color:'red', fontSize:'16px'}}>相册</strong>、<strong style={{color:'red', fontSize:'16px'}}>SD卡存储内容</strong>、<strong style={{color:'red', fontSize:'16px'}}>SD卡写入内容</strong>、
                        <strong style={{color:'red', fontSize:'16px'}}>麦克风</strong>、<strong style={{color:'red', fontSize:'16px'}}>通讯录</strong>、<strong style={{color:'red', fontSize:'16px'}}>日历</strong>、<strong style={{color:'red', fontSize:'16px'}}>电话</strong>、剪切板及其他必要权限。<br/>
                        1.2.4、为使您获得习惯公社最优的用户体验，我们的以下附加第三方功能中可能会收集和使用您的个人信息和获取一些权限（<strong style={{color:'red', fontSize:'16px'}}>摄像头</strong>、<strong style={{color:'red', fontSize:'16px'}}>相册</strong>、<strong style={{color:'red', fontSize:'16px'}}>SD卡存储内容</strong>、<strong style={{color:'red', fontSize:'16px'}}>SD卡写入内容</strong>、<strong style={{color:'red', fontSize:'16px'}}>androidId</strong>、<strong style={{color:'red', fontSize:'16px'}}>IMEI</strong>、<strong style={{color:'red', fontSize:'16px'}}>IMSI</strong>、<strong style={{color:'red', fontSize:'16px'}}>设备MAC地址</strong>、<strong style={{color:'red', fontSize:'16px'}}>软件列表</strong>、<strong style={{color:'red', fontSize:'16px'}}>软件安装列表</strong>、<strong style={{color:'red', fontSize:'16px'}}>设备序列号</strong>等）。如果您不提供这些个人信息，您依然可以使用习惯公社，但您可能无法使用这些附加第三方功能或需重复填写一些信息。这些附加功能包括：（1）com.tencent.stat(腾讯移动分析)、com.tencent.tauth(腾讯开放平台)、com.huawei.hms(华为;华为推送)、com.xiaomi.mipush(小米;小米推送)、com.xiaomi.push(小米;小米推送)、io.agora(声网)用于与好友进行实时音视频通话。、com.umeng.umsdk(友盟数据统计)。<br/>
                        1.3当您使用习惯公社的服务时，习惯公社可能会自动收集相关信息并存储为服务日志信息。<br/>
                        (1) 设备信息。例如，设备型号、操作系统版本、唯一设备标识符、电池、信号强度等信息。<br/>
                        (2) 软件信息。例如，软件的版本号、浏览器类型。为确保操作环境的安全或提供服务所需，习惯公社会收集有关您使用的移动应用和其他软件的信息。
                        请注意，单独的设备信息、软件信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息。
                        2.但，请您充分知悉，下列情况中，我们收集、使用个人信息无需征得您的授权同意且我们可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：<br/>
                        a.与国家安全、国防安全有关的；<br/>
                        b.与公共安全、公共卫生、重大公共利益有关的；<br/>
                        c.与犯罪侦查、起诉、审判和判决执行等有关的；<br/>
                        d.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br/>
                        e.所收集的个人信息是个人信息主体自行向社会公众公开的；<br/>
                        f.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；<br/>
                        g.根据您的要求签订合同所必需的；<br/>
                        h.用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；<br/>
                        i.为合法的新闻报道所必需的；<br/>
                        j.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<br/>
                        k.法律法规规定的其他情形。<br/>
                        3.我们会根据本隐私政策的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。<br/>
                        4.在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。<br/>
                        5.请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您自主变更或删除，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除您的个人信息。<br/>
                        6.习惯公社可能会对产品使用情况进行统计。同时，习惯公社可能会与公众分享这些统计信息，以展示习惯公社服务的整体使用趋势。但上述统计信息不会包含您的任何身份识别信息。
                        7.请您理解，习惯公社在未来将是不断更新和发展的。当我们要将您的个人信息用于本隐私政策未载明的其它用途，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式或通过电话沟通的形式以事先征得您的同意。<br/>
                        <b><span>第三条</span> <span>信息的共享、转让及披露</span></b><br/>
                        （一）信息的共享<br/>
                        1.原则上，习惯公社不会与习惯公社以外的任何公司、组织和个人共享您的个人信息。但以下情况除外：<br/>
                        <span>（</span>1）事先获得您明确的同意或授权；<br/>
                        <span>（</span>2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；<br/>
                        <span>（</span>3）在法律法规允许的范围内，为维护习惯公社、您或其他习惯公社用户或社会公众利益、财产或安全免遭损害而有必要提供；<br/>
                        <span>（</span>4） 只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；<br/>
                        <span>（</span>5）应您需求为您处理您与他人的纠纷或争议；<br/>
                        <span>（</span>6）符合与您签署的相关协议（如有）或其他的法律文件约定所提供；<br/>
                        <span>（</span>7）基于符合法律法规的社会公共利益而使用。<br/>
                        2.对习惯公社与之共享个人信息的公司、组织和个人，习惯公社会要求其遵守保密约定，要求他们按照习惯公社的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。<br/>
                        3.为了遵守法律、执行或适用习惯公社的使用条件和其他协议，或者为了保护习惯公社、您或其他习惯公社客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过，这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。<br/>
                        <span>（二）信息的转让</span>
                        <span>习惯公社不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span><br/>
                        <span>（</span>1）事先获得您明确的同意或授权；<br/>
                        <span>（</span>2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；<br/>
                        <span>（</span>3）在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，习惯公社会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,习惯公社将要求该公司、组织重新向您征求授权同意。<br/>
                        <span>（三）信息的披露</span>
                        <span>习惯公社仅会在以下情况，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：</span><br/>
                        <span>（</span>1）您同意或授权习惯公社披露的；<br/>
                        <span>（</span>2）您使用共享功能的；<br/>
                        <span>（</span>3）根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，习惯公社可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当习惯公社收到上述披露信息的请求时，习惯公社会要求必须出具与之相应的法律文件习惯公社对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据；<br/>
                        <span>（</span>4）在紧急情况下，为了保护您、习惯公社及其他用户的合法权益或公共安全及利益的。<br/>
                        <b><span>第四条</span> <span>个人信息保护</span></b><br/>
                        1.您的个人信息将全被存储于中华人民共和国境内。目前我们暂时不存在跨境存储您的个人信息或向境外提供个人信息的场景。如需跨境存储或向境外提供个人信息的，我们会单独向您明确告知（包括出境的目的、接收方、使用方式与范围、使用内容、安全保障措施、安全风险等）并再次征得您的授权同意，并严格要求接收方按照本隐私政策以及其他相关要求来处理您的个人信息。<br/>
                        2.习惯公社仅在实现服务目的所需的最短时限内保留您的个人信息。例如：若您需使用习惯公社服务，我们便需要一直保存您的<strong style={{color:'red', fontSize:'16px'}}>手机号码</strong>，以向您正常提供该功能、保障您的账户和系统安全；当您注销账户后，我们将删除相关信息。在超出上述存储期限后，我们会对您的个人信息进行删除或匿名化处理。但您行使删除权、注销账户的或法律法规另有规定的除外（例如：《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）。
                        当我们的产品或服务发生永久性停止运营情形时，我们将停止继续收集个人信息，同时我们将采取推送通知形式通知您，并在合理的期限内删除或匿名化处理您的个人信息。<br/>
                        但在下列情况下，我们可能因需符合法律要求，更改个人信息的存储时间：<br/>
                        为遵守适用的法律法规等有关规定；<br/>
                        为遵守法院判决、裁定或其他法律程序的规定；<br/>
                        为遵守相关政府机关或法定授权组织的要求；<br/>
                        我们有理由确信需要遵守法律法规等有关规定；<br/>
                        为执行相关服务协议或本政策、维护社会公共利益，为保护客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途。<br/>
                        3.数据安全技术措施：习惯公社会采用符合业界标准的安全防护措施,为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于：身份鉴别（即当您首次注册时，习惯公社将通过您的<strong style={{color:'red', fontSize:'16px'}}>手机号码</strong>唯一确定您的身份信息）、数据加密措施（Md5加密保护，不可逆的加密算法）、访问控制（您须通过密码或者手机验证码进行登录，否则无法正常使用习惯公社）、恶意代码防范（web应用防火墙，可对数据进行清洗，防范恶意代码执行）、安全审计（对数据进行审计过滤，防止sql注入）等。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入习惯公社的产品和服务所用的系统和通讯网络，有可能因习惯公社可控范围外的因素而出现问题。<br/>
                        4.习惯公社仅允许有必要知晓这些信息的员工访问个人信息。习惯公社同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，会被追究法律责任或被终止与习惯公社的合作关系。<br/>
                        5.互联网并非绝对安全的环境，习惯公社建议您在使用习惯公社与第三方交流时注意保护您的个人信息安全。您在使用习惯公社的产品和服务时所提供、上传或发布的内容和信息（例如有关您个人的<strong style={{color:'red', fontSize:'16px'}}>照片</strong>等信息），第三方可能会泄露您的个人信息。当您因为你的共享行为导致你的信息发生泄漏等严重情况时，习惯公社对此不承担相关法律责任。您需要谨慎地考虑，是否在使用习惯公社的产品和服务时向第三方披露您的相关个人信息。例如：<br/>
                        ①<span>当教师在习惯公社中向您（学生</span>/家长）发起调查并由您向教师提供您的个人信息（包括但不限于<strong style={{color:'red', fontSize:'16px'}}>身份证信息</strong>、<strong style={{color:'red', fontSize:'16px'}}>家庭住址</strong>等敏感信息）时，习惯公社不会因此获取您向教师提供的任何信息，但鉴于教师会因此获得您的个人信息，故习惯公社再此特别提醒您，在填写相关资料时请特别注意保护您的个人信息安全。<br/>
                        ②<span>当您将习惯公社的下载链接、邀请链接、打卡任务和办活动中的相关信息分享至微信好友，微信朋友圈，</span>qq好友和qq空间时，可能会涉及到您或他人的<strong style={{color:'red', fontSize:'16px'}}>个人敏感信息</strong>（包括<strong style={{color:'red', fontSize:'16px'}}>头像</strong>、<strong style={{color:'red', fontSize:'16px'}}>姓名</strong>等），请您注意确认第三方平台的隐私政策以保护您的个人信息安全。若您分享的信息中涉及第三方个人隐私信息的，请确认已获得第三方授权。<br/>
                        <b><span>第五条</span> <span>个人信息的管理</span> </b><b></b>
                    </p>
                    <p>
                        习惯公社将尽全力保护您对于您个人信息访问、更正、删除、注销和权利撤回的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：<br/>
                        1、访问、更正、删除您的个人信息
                        除法律法规规定外，您有权随时访问、更正和删除您的个人信息，您可以在“我的页面中”查阅您提交给习惯公社的所有个人信息（包括有<strong style={{color:'red', fontSize:'16px'}}>头像</strong>、称呼、账号（即<strong style={{color:'red', fontSize:'16px'}}>手机号码</strong>）、账号密码、账号类型），你也可通过上述途径更新或删除您的个人信息，其中：①<span>若您需要将您的账号类型由</span><span>“家长”/“学生”变更为“教师”，则您需要正确提供教师邀请码方可进行变更；</span>②<span>若您需要变更您的账号（即<strong style={{color:'red', fontSize:'16px'}}>手机号码</strong>），则我们将通过发送短信验证码的方式来重新验证您的身份是否有效，若验证失败的，您将无法通过此方式进行更正。</span>
                        <span>如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与</span>/或服务时所产生的其他个人信息，或您认为习惯公社存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以联系通过本政策下方所述联系方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，原则上我们将在收到您反馈并验证您的身份后的15天内答复您的请求。<br/>
                        2、注销账户
                        您可以通过联系习惯公社客服（客服电话：400-820-1227）注销您的个人账户。您注销账户后，我们将停止为您提供产品与/或服务，同时，除法律法规另有规定外，我们将及时删除您的个人信息。
                        请您特别注意：注销习惯公社账户是不可恢复的操作。账户注销后，您将无法继续使用注销账户或找回您的任何聊天记录或收藏的任何信息（即使你使用同一<strong style={{color:'red', fontSize:'16px'}}>手机号码</strong>进行重新注册），包括但不限于：<br/>
                        <span>（</span>1）您将无法登陆、使用注销账户，教师/家长/学生 将无法通过注销账户与您联系；<br/>
                        <span>（</span>2）您账户内的个人资料和历史信息（包括<strong style={{color:'red', fontSize:'16px'}}>头像</strong>、称呼、消息记录、收藏等）都将无法找回。<br/>
                        <span>（</span>3）在账户注销期间，若您的账户被他人投诉、被国家机关调查或正处于诉讼、仲裁程序中，习惯公社有权自行终止您账户的注销而无需另行得到您的同意。<br/>
                        <span>（</span>4）请您特别注意：注销您的账户并不代表该账户注销前的账号行为和相关责任得到豁免或减轻。<br/>
                        <span>（</span>5）用户在操作申请注销账号之后实时生效<br/>
                        3、 撤回同意权
                        如您想更改相关功能的授权范围（例如：相机、<strong style={{color:'red', fontSize:'16px'}}>麦克风</strong>等），您可以通过您的硬件设备修改个人设置（例如：“相机”功能的IOS系统的撤回同意路径为：设置—习惯公社—相机，具体方法请参考或联系您移动设备的服务商或生产商）。您也可以通过注销习惯公社的方式，永久撤回我们继续收集您个人信息的全部授权。如您在此过程中遇到操作问题的，可以通过本隐私政策文末提供的方式联系我们。
                        当您取消相关个人信息收集的授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务。<br/>
                        4、在以下情形中，按照法律法规要求，我们将无法响应您的请求：<br/>
                        <span>（</span>1）与国家安全、国防安全有关的；<br/>
                        <span>（</span>2）与公共安全、公共卫生、重大公共利益有关的；<br/>
                        <span>（</span>3）与犯罪侦查、起诉和审判等有关的；<br/>
                        <span>（</span>4）有充分证据表明您存在主观恶意或滥用权利的；<br/>
                        <span>（</span>5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。<br/>
                        <b><span>第六条</span> <span>未成年人信息保护</span></b><br/>
                        1.保护用户（特别是<strong style={{color:'red', fontSize:'16px'}}>未成年人</strong>）的隐私是习惯公社的一项基本政策。本政策所指的<strong style={{color:'red', fontSize:'16px'}}>未成年人</strong>分为：1）<strong style={{color:'red', fontSize:'16px'}}>不满十四周岁的儿童；</strong>2）<strong style={{color:'red', fontSize:'16px'}}>十四周岁以上，不满十八周岁的青少年。</strong><br/>
                        2.<strong style={{color:'red', fontSize:'16px'}}>未成年人</strong>个人信息亦属于本政策的个人信息。<strong style={{color:'red', fontSize:'16px'}}>未成年人</strong>个人信息的的收集、存储、使用、转移、披露，除本条有特殊规定外，适用本政策其他规定。<br/>
                        3.对于基于不同目的收集、使用、转移、披露<strong style={{color:'red', fontSize:'16px'}}>未成年人</strong>个人信息的，习惯公社均会以显著、清晰的方式告知您（家长），并征得您的同意。本政策所述的转移不包括习惯公社在其享有使用权的设备上存储信息的行为，亦不包括习惯公社依据本政策所做的共享行为。<br/>
                        4.如发生向第三方转移<strong style={{color:'red', fontSize:'16px'}}>未成年人</strong>个人信息的情况，习惯公社将自行或委托第三方机构就其安全性进行评估。<br/>
                        5.根据相关法律法规的规定，习惯公社将指定专人负责<strong style={{color:'red', fontSize:'16px'}}>未成年人</strong>个人信息的保护，并严格设定信息访问权限，仅允许有必要知晓这些信息的员工进行访问。习惯公社员工访问<strong style={{color:'red', fontSize:'16px'}}>未成年人</strong>个人信息的，将事先取得<strong style={{color:'red', fontSize:'16px'}}>未成年人</strong>个人信息保护负责人或其授权管理人员的许可，习惯公社会采取技术措施记录其访问情况，避免发生违法复制、下载信息的情况。<br/>
                        6.根据相关法律法规的规定，若您是18周岁以下的<strong style={{color:'red', fontSize:'16px'}}>未成年人</strong>，在使用习惯公社服务前，习惯公社建议您请您的父母或监护人仔细阅读本《隐私政策》，并建议您在提交的个人信息之前寻求父母或监护人的同意和指导。<br/>
                        7.如果习惯公社确实存在在未事先获得父母或法定监护人同意的情况下收集了<strong style={{color:'red', fontSize:'16px'}}>未成年人</strong>的个人信息，则习惯公社将在知悉该情况后设法尽快删除相关数据。
                    </p>
                    <p>
                        <b><span>第七条</span> <span>免责条款</span> </b><b></b>
                    </p>
                    <p>
                        除本政策已列明的免责情况外，在发生下列情况时，习惯公社也无需承担任何责任：<br/>
                        1. 由于您将用户密码告知他人、与他人共享注册账户或其他因您个人保管不善，由此导致的任何个人资料泄露、丢失、被盗用或被篡改等。
                        2. 任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人资料泄露、丢失、被盗用或被篡改等。<br/>
                        3. 其他非习惯公社直接责任导致的个人信息的泄露及由此产生的任何法律争议和后果。<br/>
                        <b><span>第八条</span> <span>适用范围</span></b><b>&nbsp;</b>
                    </p>
                    <p>
                        <span>习惯公社所提供的所有服务均适用本政策。若某些服务有其特定的隐私指引</span>/声明，该特定隐私指引/声明更具体地说明习惯公社在该服务中如何处理您的信息。<br/>
                        <b><span>第九条</span> <span>联系我们</span></b><br/>
                        1、如您在使用习惯公社产品、服务中对本隐私政策有任何问题、投诉、建议等，您均可通过习惯公社对外公布的客服电话与习惯公社联系；或者您也可以将相关材料邮寄至【上海市金沙江路1006号华师大科技园D栋4楼习惯公社】，习惯公社在收到您的材料后将会安排专人尽快审核所涉问题并及时给予您答复。一般情况下，我们不会因此对您收取服务费。<br/>
                        2、原则上，我们将在收到您意见并核实身份后的十五天内回复；如遇法定节假日等特殊情况的，我们的回复周期将作出相应顺延，望您予以理解。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、公安及工商等监管部门进行投诉或举报。<br/>
                        <b><span>第十条</span> <span>修订</span> </b>
                    </p>
                    <p>
                        <span>您理解并同意，习惯公社有权适时根据产品及服务的发展情况，对本隐私政策进行修订。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。</span>
                        <span>届时习惯公社会通过推送通知的方式向您发布修订的政策内容，届时您可仍通过勾选方式确认是否愿意接受修订后的政策并继续使用习惯公社平台服务。</span>
                    </p>
                    <p style={{textAlign:'right'}}>
                        上海形者网络科技发展有限公司
                    </p>
                    <p style={{textAlign:'right'}}>
                        &nbsp;
                    </p>
                </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(SjbPrivacy)
