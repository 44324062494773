import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/spellHome.css'
import {util} from "../../common/util";
import {api, qiNiuIP, shareIP} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";
import user_default_icon from "../../image/user_default_icon.png";
import group_main_img from "../../image/spell/group_main_img.png";
import spell_user_default_img from "../../image/spell/spell_user_default_img.png";
import SpellRecommended from "../../component/spell/SpellRecommended";
import select_oragin_icon from "../../image/spell/select_oragin_icon.png";


class spellInvitation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orderObj: null,
        };
    }

    componentDidMount() {
        let obj=JsTypeFunction.getUrlParams();
        console.log(obj);
        if(obj.id){
            this.handleGetOrder(obj.id)
        }else{
            Toast.info('您查看的已失效')
        }
    }

    // 获取订单号
    handleGetOrder=(id)=>{
        util.getYangAxios(
            api.spellBillOrder+`/${id}`,
            {
            },
            (res) => {
                console.log(res);
                this.setState({
                    orderObj: res,
                });
            },
            (err) => {
                console.log(err);
            },
            false,
        );
    }

    // 渲染假人
    handleQuestionRender=(num)=>{
        let list=[1,2,3,4,5,6,7,8,9,10].slice(0,num)
        return (
            <>
                {
                    list.map((item,index)=>{
                        return (
                            <div className={'spell_invitation_user_box row_center'} key={index}>
                                <img src={spell_user_default_img} alt="" className={'spell_invitation_user_img'}/>
                            </div>
                        )
                    })
                }
            </>
        )
    }

    // 没有登录跳登录
    handleSkipLogin=()=>{
        const { orderObj }=this.state
        let url= `${shareIP}spellClassHome?id=${orderObj.good_id}&collageId=${orderObj.id}`
        if(orderObj.good.length>0&&orderObj.good[0].type==2){
            url=`${shareIP}purchaseGoodsDetails?id=${orderObj.good_id}&collageId=${orderObj.id}`
        }
        let login_token = window.localStorage.getItem("login_token")?window.localStorage.getItem("login_token"):'';
        if(login_token){
            window.location.href=url
        }else{
            this.props.history.push({
                pathname: '/loginPage',
                state: {
                    url: url,
                    type: 4, //1下载  2邀请
                }
            })
        }
    }

    // 再逛逛
    handleLook=()=>{
        this.props.history.push('download');
    }


    render() {
        const {userData} =this.props.UserDataReducer;
        const { orderObj ,}=this.state
        if(!orderObj){
            return null;
        }
        return (
            <div className={"article_view"}>
                {
                    orderObj.is_self==1&&<div className={'row_center spell_invitation_succ_div pt30'}>
                        <img src={select_oragin_icon} alt="" className={'select_oragin_icon'}/>
                        拼单成功
                    </div>
                }
                <div className={'row_warp spell_invitation_user_div'}>
                    {
                        orderObj.oder_bill.length>0&&orderObj.oder_bill.map((item,index)=>{
                            return (
                                <div className={'spell_invitation_user_box row_center'} key={index}>
                                    <img src={item.user_icon?`${qiNiuIP}${item.user_icon}`:user_default_icon} alt="" className={'spell_invitation_user_img'}/>
                                    {
                                        index==0&&<img src={group_main_img} alt="" className={'spell_invitation_group_main_img'}/>

                                    }
                                </div>
                            )
                        })
                    }
                    {this.handleQuestionRender(orderObj.total_num-orderObj.now_num)}
                </div>
                <div className={'column_center'}>
                    {
                        orderObj.status==1? <div className={'spell_invitation_title_text'}>{orderObj.total_num}人拼单，还差<span style={{color: '#FE6B02'}}>{orderObj.total_num-orderObj.now_num}</span>人</div>:<div className={'spell_invitation_title_text'}>{orderObj.total_num}人拼单</div>
                    }

                    {
                        orderObj.status==1&&orderObj.is_self!=1? <div className={'spell_invitation_participate_btn row_center'} onClick={this.handleSkipLogin}>
                            立即参与
                        </div>: <div className={'spell_invitation_participate_btn row_center'} onClick={this.handleLook}>
                            再逛逛
                        </div>
                    }

                </div>
                <SpellRecommended type={orderObj.good&&orderObj.good.length>0?orderObj.good[0].type: 1}/>

            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(spellInvitation)
