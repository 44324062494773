import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/parentsSchool.css'
import {util} from "../../common/util";
import {api} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
import HeaderView from "../../component/header/headerView";
import StarTitleView from "../../component/header/StarTitleView";
import JsTypeFunction from "../../common/JsTypeFunction";
import FloatFooter from "../../component/Footer/FloatFooter";
class SpeciallyHabit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            articleId: 1,
            articleObj: null,
        };
    }

    componentDidMount() {
        let obj=this.getUrlParams();
        console.log(obj);
        if(obj.id){
            this.setState({
                articleId: obj.id,
            },()=>{
                this.handleRefresh();
            })
        }else{
            Toast.info('您查看的特训营已失效')
        }
    }


    getUrlParams = () => {
        let url = decodeURI(window.location.href);
        let index = url.indexOf('?')
        let obj = {}
        if (index !== -1) {
            let str = url.split('?')[1]
            let arr = str.split('&')
            for (let i = 0; i < arr.length; i++) {
                obj[arr[i].split('=')[0]] = arr[i].split('=')[1]
            }
        }
        return obj//{articleId: "1", articleNum: "1", opt: "edit"}
    }


    // 刷新函数
    handleRefresh = () => {
        const { articleId }=this.state;
        util.getYangAxios(
            api.train,
            {
                id: articleId,
            },
            (res) => {
                console.log(res);
                this.setState({
                    articleObj: res
                })
            },
            (err) => {
                console.log(err);
                // Toast.fail('服务器异常,请稍后重试')
            },
        );
    };

    // 前往下载
    handleDoload=()=>{
        this.props.history.push('download')
    }


    render() {
        const {userData} =this.props.UserDataReducer;
        const {articleObj }=this.state
        return (
            <div className={"article_view"}>
                <div className={'row_center article_header_div'}>
                    {articleObj&&articleObj.name}
                </div>
                {
                    articleObj&&<>
                        <div className={'article_top_div'}>
                            <img src={`https://cdn.ellll.com/${articleObj.pic}?imageView2/5/q/75|imageslim`} alt="" className={'specially_img'}/>
                        </div>
                        <StarTitleView title={'推荐关键好习惯'}/>
                        <div className={'row_warp'}>
                            {
                                articleObj.habit.length>0&&articleObj.habit.map((item,index)=>{
                                    return (
                                        <div key={index} className={'row_center'} style={{width: '33%'}}>
                                            <div className={'column_align specially_small_div'} style={{backgroundColor: JsTypeFunction.rgbBgColor(index)}}>
                                                <img src={`https://cdn.ellll.com/${item.cover}?imageView2/5/q/75|imageslim`} alt="" className={'specially_small_img'}/>
                                                <div className={'specially_small_text'}>
                                                    {item.name}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <StarTitleView title={'特训营介绍'}/>
                        <div className={'activity_bottom_div'}>
                            {
                                articleObj.content?<div dangerouslySetInnerHTML={{__html:articleObj.content}}></div>:<div className={'row_center'}>
                                    <img src={'https://cdn.ellll.com/capphabit/class_empty_img.png'} alt="" className={'class_empty_img'}/>
                                </div>
                            }
                        </div>
                    </>
                }
                <FloatFooter/>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(SpeciallyHabit)
