import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/invite.css'
import {util} from "../../common/util";
import {api} from "../../common/Config";
import {InputItem, Toast, Picker} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";
import jpb_share1 from '../../image/jpb_share1.png'

class InvitePrincipal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            level: 1,
            titleList: [],
            title: '',
            titleValue: '',
        };
    }

    componentDidMount() {
        let obj=JsTypeFunction.getUrlParams();
        console.log(obj);
        if(obj.id){
            let list=[];
            if(obj.level==1){
                list=[
                    { value: '校长', label: '校长' },
                    { value: '副校长', label: '副校长' },
                    { value: '书记', label: '书记' },
                    { value: '副书记', label: '副书记' },
                    { value: '德育主任', label: '德育主任' },
                    { value: '顾问', label: '顾问' },
                    { value: '教导主任', label: '教导主任' },
                ]
            }else if(obj.level==2){
                list= [
                    { value: '班主任', label: '班主任' },
                    { value: '生活老师', label: '生活老师' },
                    { value: '老师', label: '老师' },
                    { value: '顾问', label: '顾问' },
                ]
            }else{
                list= [
                    { value: '妈妈', label:'妈妈' },
                    { value: '爸爸', label:'爸爸' },
                    { value: '爷爷', label:'爷爷' },
                    { value: '奶奶', label:'奶奶' },
                    { value: '外公', label:'外公' },
                    { value: '外婆', label:'外婆' },
                ]
            }
            this.setState({
                id: obj.id,
                level: obj.level,
                str: obj.str,
                titleList: list,
            })
        }else{
            Toast.info('您查看的已失效')
        }
    }

    // 加入
    handleJoin=()=>{
        const {str, titleList, titleValue,id }=this.state
        const isLogin = localStorage.getItem("login_token");
        console.log(isLogin)
        if(!titleValue){
            Toast.info('请选择身份',1.5)
            return null;
        }
        if(isLogin){
            this.handleInvite()
        }else{
            this.props.history.push({
                pathname: '/login',
                state: {
                    id,
                    type: 5,
                    data:{
                        id: id,
                        name: titleValue,
                        title: titleValue,
                    }
                }
            })
        }
    }

    handleInvite=()=>{
        const {str, titleList, title,id, titleValue }=this.state
        util.postYangAxios(
            api.organJoin,
            {
                id: id,
                name: titleValue,
                title: titleValue,
            },
            (res) => {
                console.log(res);
                this.props.history.push('inviteJpbSuccess')
            },
            (err) => {
                console.log(err);
            },
        );
    }

    render() {
        const {userData} =this.props.UserDataReducer;
        const {str, titleList, title }=this.state
        return (
            <div className={"article_view jpb_share_view"} >
                <img src="http://appshare.xzwl100.com/static/img/headBg.91c6bbfa.png" alt="" className={'jpb_share_img'}/>
               <div className={'ph30 column_align'}>
                   <div className={'jpb_share_title'}>{str}</div>
                   <Picker
                       data={titleList}
                       title="选择身份"
                       value={title}
                       cols={1}
                       onOk={v => this.setState({ title: v,titleValue: v[0] })}
                   >
                       <div className={'row_center jpb_share_id_view'}>
                           您的身份：{title?title: '请选择'} <img src={jpb_share1} alt="" className={'jpb_share1'}/>
                       </div>
                   </Picker>
                   <div className={'jpb_join_btn row_center'} onClick={this.handleJoin}>加入</div>
               </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(InvitePrincipal)
