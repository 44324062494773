import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";
import {Map, Marker} from '@pansy/react-amap'
import xggs_logo from "../../image/spell/xggs_logo.png";
import Swiper from 'react-id-swiper';
import 'swiper/swiper-bundle.min.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

class mapPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locationList: [
                {
                    position:{
                        longitude: 116.3972282409668,
                        latitude: 39.90960456049752
                    },
                },{
                    position:{
                        longitude: 118,
                        latitude: 32
                    },
                },{
                    position:{
                        longitude: 121,
                        latitude: 31
                    },
                },{
                    position:{
                        longitude: 119,
                        latitude: 29
                    },
                },
            ],
            swiperIndex: 0,
        };
        this.mapPage= null;
    }

    componentDidMount() {
        // let obj=JsTypeFunction.getUrlParams();
        // console.log(obj);
        let list=[
            {
                position:{
                    longitude: 116.3972282409668,
                    latitude: 39.90960456049752,
                },
            },{
                position:{
                    longitude: 118,
                    latitude: 32
                },
            },{
                position:{
                    longitude: 121,
                    latitude: 31
                },
            },{
                position:{
                    longitude: 119,
                    latitude: 29
                },
            },
        ];
        this.setState({locationList: list})
        setTimeout(()=>{
            list.map((item,index)=>{
                this.handleGetAddr(item.position.longitude,item.position.latitude,index);
                return item;
            })
        },500)
    }
    componentWillMount() {

    }

    updateCurrentIndex=(index)=>{
        // console.log(index);
        const {locationList, swiperIndex }=this.state
        this.setState({
            swiperIndex: index
        },()=>{
            if(this.mapPage){
                this.mapPage.panTo([locationList[index].position.longitude,locationList[index].position.latitude])
            }
        })
    }

    handleGetAddr= async (longitude,latitude,index)=>{
        await fetch(`http://restapi.amap.com/v3/geocode/regeo?key=b98c2a030fd0706eed55e88efc1cfc44&location=${longitude},${latitude}&radius=1000&extensions=all&batch=false&roadlevel=0`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: ``
        })
            .then((response) => response.json())
            .then((jsonData) => {
                // console.log(jsonData)
                // console.log(jsonData.regeocode)
                if(jsonData&&jsonData.regeocode){
                    let list= this.state.locationList;
                    list[index].addr=jsonData.regeocode
                    this.setState({locationList: list})
                }
            })
            .catch((error) => {
                console.error(error);
            })
    }

    render() {
        const {userData} =this.props.UserDataReducer;
        const {locationList, swiperIndex }=this.state
        const params = {
            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            spaceBetween: 10,
            coverflowEffect: {
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true
            },
            pagination: {
                el: '.swiper-pagination'
            },
            on: { slideChange: index => { this.updateCurrentIndex(index.snapIndex); }, }
        }
        return (
            <div className={"article_view"} >
                <div style={{width: document.body.clientWidth,height: document.body.clientHeight,position: 'relative'}}>
                    <Map mapKey='5884a011e9649f3b97db87498f86b3bd' center={locationList[0].position} zoom={10} ref={(ref)=>this.mapPage=ref}>
                        {locationList.map((item, index) => {
                            return (
                                <Marker {...item} key={index}>
                                    <img src={xggs_logo} alt="" className={'spell_store_logo'}/>
                                </Marker>
                            )
                        })}
                    </Map>
                    <div className={'map_modal_div'}>
                        <Swiper {...params}>
                            {locationList.map((item, index) => {
                                if(!item.addr){
                                    return (
                                        <div key={index} className={'map_modal_box'} >
                                            <div className={'map_modal_skeleton_img'}>
                                                <Skeleton count={1} className={'map_modal_skeleton_img_line'}/>
                                            </div>
                                            <p className={'map_modal_skeleton'}>
                                                <Skeleton count={3}/>
                                            </p>
                                        </div>
                                    );
                                }
                                return (
                                    <div key={index} className={'map_modal_box row_align'}>
                                        <img src={xggs_logo} alt="" className={'spell_store_logo'}/>
                                        <div>
                                            <div className={'map_title'}>{item.addr.formatted_address}</div>
                                            <div className={'map_content'}>{item.addr.addressComponent.country}{item.addr.addressComponent.province}{item.addr.addressComponent.district}{item.addr.addressComponent.township}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(mapPage)
