import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/parentsSchool.css'
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";
import StarTitleView from "../../component/header/StarTitleView";
import FloatFooter from "../../component/Footer/FloatFooter";
class AppraisalContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            articleId: 1,
            articleObj: null,
        };
    }

    componentDidMount() {
        let obj=this.getUrlParams();
        console.log(obj);
        if(obj.id){
            this.setState({
                articleId: obj.id,
            },()=>{
                this.handleRefresh();
            })
        }else{
            Toast.info('您查看的文章已失效')
        }
    }


    getUrlParams = () => {
        let url = decodeURI(window.location.href);
        let index = url.indexOf('?')
        let obj = {}
        if (index !== -1) {
            let str = url.split('?')[1]
            let arr = str.split('&')
            for (let i = 0; i < arr.length; i++) {
                obj[arr[i].split('=')[0]] = arr[i].split('=')[1]
            }
        }
        return obj//{articleId: "1", articleNum: "1", opt: "edit"}
    }


    // 家长学堂获取数据
    handleRefresh = () => {
        const { articleId }=this.state;
        util.getYangAxios(
            api.getTagInfo,
            {
                id: articleId,
            },
            (res) => {
                console.log(res);
                this.setState({
                    articleObj: res
                })
            },
            (err) => {
                console.log(err);
                // Toast.fail('服务器异常,请稍后重试')
            },
        );
    };

    // 前往下载
    handleDoload=()=>{
        this.props.history.push('download')
    }


    render() {
        const {userData} =this.props.UserDataReducer;
        const {articleObj, type }=this.state
        return (
            <div className={"article_view"}>
                {
                    articleObj&&<>
                        <div className={'activity_bottom_div'} style={{paddingLeft: 0,paddingRight: 0}}>
                            <StarTitleView title={articleObj.name}/>
                            {
                                articleObj.content?<div dangerouslySetInnerHTML={{__html:articleObj.content}}></div>:<div className={'row_center'}>
                                    <img src={'https://cdn.ellll.com/capphabit/class_empty_img.png'} alt="" className={'class_empty_img'}/>
                                </div>
                            }
                        </div>
                    </>
                }
                <FloatFooter/>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(AppraisalContent)
