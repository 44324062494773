import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import {util} from "../../common/util";
import {api} from "../../common/Config";
import JsTypeFunction from "../../common/JsTypeFunction";
class DownloadXyb extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wxShow: false,
        };
    }

    componentDidMount(){
        let ua = navigator.userAgent
        console.log(ua)
        // if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        //     window.location.href = 'xggsxyb://';
        //     console.log('苹果')
        // } else if (ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1) {
        //     window.location.href = 'xggsxyb://xggsxyb/';
        //     console.log('安卓')
        // }
        let uc = window.navigator.userAgent.toLowerCase();
        if(uc.match(/MicroMessenger/i) == 'micromessenger'){      //判断是否是微信环境
            this.setState({
                wxShow: true,
            })
        }
    }


    // 下载按钮
    handleSkipDown=()=>{
        let ua = navigator.userAgent
        if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            window.location.href = 'xggsxyb://';
            console.log('苹果')
            window.location.href ="https://apps.apple.com/cn/app/%E4%B9%A0%E6%83%AF%E5%85%AC%E7%A4%BE-%E5%AD%A6%E6%A0%A1%E7%89%88/id1526087997";
        } else if (ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1) {
            window.location.href = 'xggsxyb://xggsxyb/';
            console.log('安卓')
            window.location.href ="https://a.app.qq.com/o/simple.jsp?pkgname=com.xz.xggsxyb&channel=0002160650432d595942&fromcase=60001";
        }
    }



    render() {
        const { wxShow } = this.state;
        return (
            <div className={'down_div'} style={{overflow: wxShow?'hidden':'scroll'}}>
                {/*<img src={'https://cdn.ellll.com/capphabit/background.jpg'} className="top_logo"  onClick={this.handleSkipDown}/>*/}
                <img src={JsTypeFunction.handleImgAddr('down')} className="top_logo"/>
                <div className="down_btn_view">
                    <img src={JsTypeFunction.handleImgAddr('down_btn')} className="down_btn"  onClick={this.handleSkipDown}/>
                </div>
                {
                    wxShow&& <img src={'https://cdn.ellll.com/capphabit/open_browser_img.png'} className="open_browser_img" />
                }
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(DownloadXyb)
