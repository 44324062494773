import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/videoShare.css'
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
import {
    BigPlayButton,
    Player,
    ControlBar,
    PlayToggle, // PlayToggle 播放/暂停按钮 若需禁止加 disabled
    ReplayControl, // 后退按钮
    ForwardControl,  // 前进按钮
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,  // 倍速播放选项
    VolumeMenuButton
} from 'video-react';
import HLSSource from './HLSSource';
import JsTypeFunction from "../../common/JsTypeFunction";

class ImgLang extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cover: '',
        };
    }

    componentDidMount() {
        let obj=JsTypeFunction.getUrlParams();
        console.log(obj);
        if(obj.cover){
            this.setState({
                cover: obj.cover
            })
        }
    }

    render() {
        const {userData} =this.props.UserDataReducer;
        const { cover }=this.state;
        return (
            <div className={"article_view"}>
                {
                    cover&& <img src={qiNiuIP+cover} alt="" style={{width: '100%'}}/>
                }
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(ImgLang)
