import React, { Component } from 'react'
import { withRouter, } from 'react-router-dom'
class FloatFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {
    }

    // 前往下载
    handleDoload=()=>{
        this.props.history.push('download')
    }

    render() {
        return (
            <div className={"article_btn_view row_center  animate__animated animate__fadeInUp"}>
                <img src={'https://cdn.ellll.com/capphabit/xggs_invite_logo.png'} alt="" className={'article_xggs_invite_logo'}/>
                <div>
                    <div className={'article_bottom_text'}>习惯公社</div>
                    <div className={'article_bottom_text'}>为中国3-12岁少儿养成好习惯</div>
                </div>
                <div className={"article_btn_box row_center"} onClick={this.handleDoload}>
                    前往下载
                </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default withRouter(FloatFooter);
