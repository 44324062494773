import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/invite.css'
import {util} from "../../common/util";
import {api} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
class InviteClub extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inviteObj: null,
            inviteType: 1,
            phoneValue: '',
            codeValue: '',
            codeNum: 60,
            codeType: 0,
            name: '',
            content: '',
        };
    }

    componentDidMount() {
        let obj=this.getUrlParams();
        console.log(obj);
        if(obj){
            this.setState({
                inviteObj: obj,
            },()=>{
                this.handleGetName()
            })
        }else{
            // Toast.info('您想加入的组织无效')
        }
    }

    handleGetName=()=>{
        const { inviteObj }=this.state;
        util.getYangAxios(
            api.departmentInfo,
            {
                id: inviteObj.id,
            },
            (res) => {
                console.log(res);
                this.setState({
                    name: res.teacher.length>0?res.teacher[0].name:'班主任',
                    content: res.name,
                })
            },
            (err) => {
                console.log(err);
            },
        );
    }


    getUrlParams = () => {
        console.log(window.location)
        let url = decodeURI(window.location.href);
        let index = url.indexOf('?')
        let obj = {}
        if (index !== -1) {
            let str = url.split('?')[1]
            let arr = str.split('&')
            for (let i = 0; i < arr.length; i++) {
                obj[arr[i].split('=')[0]] = arr[i].split('=')[1]
            }
        }
        return obj//{articleId: "1", articleNum: "1", opt: "edit"}
    }


    handleJoin=()=>{
        const { inviteObj }=this.state

    }

    onPhoneChange = (event) => {
        this.setState({
            phoneValue:  event.target.value,
        });
    }

    onCodeChange=(event)=>{
        this.setState({
            codeValue:  event.target.value,
        })
    }

    // 获取手机号登陆验证短信
    handlePhoneLoginNote = () => {
        const {phoneValue} = this.state;
        if (phoneValue.length < 11) {
            Toast.info('手机号格式错误', 1);
            return null;
        }
        if (!/^1[0-9]{10}$/.test(phoneValue)) {
            Toast.info('手机号格式错误', 1);
            return null;
        }
        util.postYangAxios(
            api.sendCode,
            {
                phone: phoneValue,
            },
            (res) => {
                console.log(res);
                this.setState(
                    {
                        codeType: 1,
                        codeNum: 60,
                    },
                    () => {
                        const add = setInterval(() => {
                            const {codeNum} = this.state;
                            if (codeNum == 1) {
                                this.setState({
                                    codeType: 2,
                                });
                                clearInterval(add);
                            }
                            this.setState({
                                codeNum: codeNum - 1,
                            });
                        }, 1000);
                    },
                );
            },
            (err) => {
                console.log(err);
                // Toast.fail('服务器异常,请稍后重试')
            },
        );
    };

    // 提交
    handleSubmit=()=>{
        const { inviteObj, inviteType,codeNum, codeType,
            name,
            content, }=this.state
        const isLogin = localStorage.getItem("login_token");
        console.log(isLogin)
        if(isLogin){
            // this.props.history.push({
            //     pathname: '/selectChild',
            //     state: {
            //         id: inviteObj.id,
            //         name: name,
            //         type: 3, //1下载  2邀请
            //     }
            // })
            this.handleSkipMenber();
        }else{
            this.props.history.push({
                pathname: '/login',
                state: {
                    id: inviteObj.id,
                    name: content,
                    type: 3, //1下载  2邀请
                }
            })
        }
    }

    // 判断有没有会员孩子
    handleSkipMenber=()=>{
        const { inviteObj, inviteType,codeNum, codeType,
            name,
            content, }=this.state
        util.getYangAxios(
            api.user,
            {
            },
            (g) => {
                if(g.is_bind==1){
                    this.props.history.push({
                        pathname: '/perfectInformation',
                        state: {
                            id: inviteObj.id,
                            name: content,
                        }
                    })
                }else{
                    util.postYangAxios(
                        api.groupInvite,
                        {
                            department_id: inviteObj.id,
                        },
                        (r) => {
                            util.getYangAxios(
                                api.child,
                                {
                                    is_vip: 2,
                                },
                                (res) => {
                                    console.log(res);
                                    if(res.length==0){
                                        this.props.history.push({
                                            pathname: '/memberDetail',
                                            state: {
                                                id: inviteObj.id,
                                                name: content,
                                                type: 3, //1下载  2邀请
                                            }
                                        })
                                    }else{
                                        this.props.history.push('inviteSuccessful')
                                    }
                                },
                                (err) => {
                                    console.log(err);
                                },
                            );
                        },
                        (err) => {
                            console.log(err);
                        },
                        true,
                    );
                }
            },
            (err) => {
                console.log(err);
            },
            true,
        );

    }

    // 加入督导团
    handleInvite=()=>{
        const { inviteObj}=this.state
        if(!inviteObj||!inviteObj.id){
            Toast.info('邀请人不存在',2)
            return null;
        }
        util.postYangAxios(
            api.invite,
            {
                child_id: inviteObj.id,
            },
            (res) => {
                console.log(res);
                this.props.history.push('inviteSuccessful')
            },
            (err) => {
                console.log(err);
            },
        );
    }

    render() {
        const {userData} =this.props.UserDataReducer;
        const { inviteObj, inviteType,codeNum, codeType,
            name,
            content, }=this.state
        const inviteStyle = {
            width: "100%",
            backgroundSize: '100%,100%',
            backgroundImage: `url(https://cdn.ellll.com/capphabit/invite_bg.png)`,
            backgroundRepeat: "no-repeat",
            backgroundColor: '#FFFFFF'
        };
        return (
            <div style={inviteStyle} className={"invite_view column_between"}>
                <div className={'row_between invite_close_box'}>
                    <img src={'https://cdn.ellll.com/capphabit/invite_close_img.png'} alt="" className={'invite_close_img'} style={{opacity: 0}}/>
                    <div className={'invite_title_text'}>邀请</div>
                    <img src={'https://cdn.ellll.com/capphabit/invite_close_img.png'} alt="" className={'invite_close_img'} onClick={()=> this.props.history.push({
                        pathname: '/loginPage',
                        state: {
                            id: inviteObj.id,
                            name: content,
                            type: 3, //1下载  2邀请
                        }
                    })}/>
                </div>
                <div className={'column_end invite_join_view'}>
                    <img src={'https://cdn.ellll.com/capphabit/xggs_invite_logo.png'} alt="" className={'xggs_invite_logo'}/>
                    <div className={'invite_join_name'}>
                        {name}
                    </div>
                    <div className={'invite_join_content'}>
                        邀请您加入{content}私教社<br/>
                        让我们来一起养成好习惯吧！
                    </div>
                    {
                        inviteObj&&  <div className={'invite_join_btn row_center'} style={{fontSize: '1rem'}} onClick={this.handleSubmit}>
                            加入
                        </div>
                    }
                </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(InviteClub)
