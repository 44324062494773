import React, { Component } from 'react'
import './css/header.css'
import { withRouter, } from 'react-router-dom'
import stay_left_img from '../../image/stay_left_img.png'
import stay_right_img from '../../image/stay_right_img.png'
class StarTitleView extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {
    }


    render() {
        return (
            <div className={'row_center stay_title_text'}>
                <img src={stay_left_img} alt="" className={'stay_left_img'}/>
                <div className={'stay_left_text'}>
                    {this.props.title}
                </div>
                <img src={stay_right_img} alt="" className={'stay_left_img'}/>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default withRouter(StarTitleView);
