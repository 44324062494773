import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/selectChild.css'
import integral_back_img from '../../image/integral_back_img.png'
import user_default_icon from '../../image/user_default_icon.png'
import add_gray_img from '../../image/add_gray_img.png'
import {Toast} from "antd-mobile";
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";
class SelectChild extends Component {

    constructor(props) {
        super(props);
        this.state = {
            childList: [],
            selectIndex: 0,
            type: 3,
            id: null,
            name: '',
        };
    }

    componentDidMount() {
        if(this.props.history.location.state){
            this.setState({
                type: this.props.history.location.state.type,
                name: this.props.history.location.state.name,
                id: this.props.history.location.state.id,
            })
        }
        this.handleGetChild();
    }

    componentWillUnmount() {
    }

    // 加入督导团
    handleGetChild=()=>{
        util.getYangAxios(
            api.child,
            {
            },
            (res) => {
                console.log(res);
                this.setState({
                    childList: res
                })
            },
            (err) => {
                console.log(err);
            },
        );
    }

    // 跳抓
    handleSkip=()=>{
        this.props.history.push('download')
    }

    handleBack=()=>{
        this.props.history.goBack();
    }

    // 提交
    handleSubmit=()=>{
        const { id, selectIndex }=this.state
        if(selectIndex==0||!selectIndex){
            Toast.info('请选择孩子');
            return null;
        }
        if(!id){
            Toast.info('您想加入的私教社已失效');
            return null;
        }
        util.postYangAxios(
            api.groupInvite,
            {
                department_id: id,
                child_id: selectIndex,
            },
            (res) => {
                console.log(res);
                this.props.history.push('download')
                // this.props.history.push('memberDetail')
            },
            (err) => {
                console.log(err);
            },
            true,
        );
    }

    render() {
        const { childList, selectIndex,name,id }=this.state;
        return (
            <div className={"select_child_view"}>
                <div className={'row_between back_view'}>
                    <img src={integral_back_img} alt="" className={'integral_back_img'} onClick={this.handleBack}/>
                    <div className={'integral_back_text'}>加入</div>
                    <img src={integral_back_img} alt="" className={'integral_back_img'} style={{opacity: 0}}/>
                </div>
                <div className={"select_child_title"} style={{ marginTop: '3rem'}}>
                    <div className={"select_child_title_small"}>请选择</div>谁加入<br/>
                </div>
                <div className={"select_child_title"}>
                    {name?name:''}
                </div>
                <div className={'select_child_box'}>
                    {
                        childList.length>0&&childList.map((item,index)=>{
                            return (
                                <div className={'child_div column_center'} key={index} onClick={()=>this.setState({selectIndex:item.id})}>
                                    <img src={item.cover?`${qiNiuIP}${item.cover}`:user_default_icon} alt="" className={`user_default_icon ${selectIndex==item.id?'select_child_border':''}`}/>
                                    <div className={'child_name_text'} style={{color: selectIndex==item.id?'#FE6B02':'#676E7A'}}>
                                        {item.name}
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className={'child_div column_center'} onClick={()=> this.props.history.push({
                        pathname: '/addChild',
                        state: {
                            id: id,
                            name: name,
                        }
                    })}>
                        <img src={add_gray_img} alt="" className={'user_default_icon'}/>
                        <div className={'child_label_text'}>
                            添加孩子
                        </div>
                    </div>
                </div>
                <div className={'invite_join_view'}>
                    <div className={'invite_join_btn row_center'} onClick={this.handleSubmit} style={{marginBottom: '0.8rem'}}>
                        确认
                    </div>
                </div>
                <div className={'down_load_view'} onClick={this.handleSkip}>
                    下载APP，去体验一下
                </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(SelectChild)
