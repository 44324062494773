import axios from 'axios'
import { Toast, } from 'antd-mobile';
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history"
const router = new BrowserRouter();
// const axios = require('axios').create({ timeout: 10000 })
const history = createBrowserHistory()
// let axios = axios.create({ timeout: 1000 });
// 过滤状态吗
const filterCode = (res, successCallback) => {
    if (res.data.code == 0) {
        successCallback(res.data.data);
    } else if (res.data.code == 3) {
        // NavigationService.navigate('bindingPhone');
    } else if (res.data.code == 2 ) {
        window.localStorage.removeItem("login_token")
        Toast.info(res.data.msg?res.data.msg:'请登录！', 1.5, () => {
            history.push("/login")
            window.location.reload()
        });
    } else {
        Toast.info(res.data.msg, 1.5);
    }
};

// 过滤错误吗
const filterErrCode = (err, failCallback) => {
    if (err.message && err.message == 'Network Error') {
        Toast.info('网络异常，请检查网络后再试', 1.5);
        failCallback(err);
    } else {
        Toast.info('服务器出了点小差，请耐心等待。', 1.5);
        failCallback(err);
    }
};

// 获取本地token
const getTokenHeader = async () => {
    let login_token = window.localStorage.getItem("login_token")?window.localStorage.getItem("login_token"):'';
    let login_long_token = window.localStorage.getItem("login_long_token")?window.localStorage.getItem("login_long_token"):'';
    let extend_id = window.localStorage.getItem("extend_id")?window.localStorage.getItem("extend_id"):'';
    // let reg_id = window.localStorage.getItem("reg_id")?window.localStorage.getItem("reg_id"):'';
    const header = {
        'token': login_token, // login_token
        'login-token': login_long_token,
        'extend_id': extend_id,
        "Access-Control-Allow-Origin":"*",
        // app_type: 3,
        // reg_id: reg_id,
    };
    return header;
};

export const util= {
    // Fetch
    getYangAxios: async (url, data, successCallback, failCallback, notLoading) => {
        let key= null
        if(!notLoading){
            key = Toast.loading('加载中', 0)
        }
        let headerObj = {};
        await getTokenHeader().then((header) => {
            headerObj = header;
        });
        console.log(data);
        axios
            .get(url, {
                params: data,
                headers: headerObj,
            })
            .then((res) => {
                console.log(`${url}返回数据————————————————————————`);
                console.log(res);
                Toast.hide()
                filterCode(res, successCallback);
            })
            .catch((err) => {
                Toast.hide()
                filterErrCode(err, failCallback);
            });
    },
    postYangAxios: async (url, data, successCallback, failCallback, notLoading) => {
        let key= null
        if(!notLoading){
            key = Toast.loading('加载中', 0)
        }
        let headerObj = {};
        await getTokenHeader().then((header) => {
            headerObj = header;
        });
        console.log(data);
        await axios
            .post(url, data, {
                headers: headerObj,
            })
            .then((res) => {
                console.log(`${url}返回数据————————————————————————`);
                console.log(res);
               Toast.hide()
                filterCode(res, successCallback);
            })
            .catch((err) => {
               Toast.hide()
                filterErrCode(err, failCallback);
            });
    },
    putYangAxios: async (url, data, successCallback, failCallback, notLoading) => {
        let key= null
        if(!notLoading){
            key = Toast.loading('加载中', 0)
        }
        let headerObj = {};
        await getTokenHeader().then((header) => {
            headerObj = header;
        });
        console.log(data);
        await axios
            .put(url, data, {
                headers: headerObj,
            })
            .then((res) => {
                console.log(`${url}返回数据————————————————————————`);
                console.log(res);
               Toast.hide()
                filterCode(res, successCallback);
            })
            .catch((err) => {
               Toast.hide()
                filterErrCode(err, failCallback);
            });
    },
    deleteYangAxios: async (url, data, successCallback, failCallback, notLoading) => {
        let key= null
        if(!notLoading){
            key = Toast.loading('加载中', 0)
        }
        let headerObj = {};
        await getTokenHeader().then((header) => {
            headerObj = header;
        });
        console.log(data);
        await axios
            .delete(url, {
                data: data,
                headers: headerObj,
            })
            .then((res) => {
                console.log(`${url}返回数据————————————————————————`);
                console.log(res);
               Toast.hide()
                filterCode(res, successCallback);
            })
            .catch((err) => {
               Toast.hide()
                filterErrCode(err, failCallback);
            });
    },
};

