import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/login.css'
import {InputItem, Toast,} from "antd-mobile";
import {util} from '../../common/util';
import {api} from "../../common/Config";
import sjs_logo_img from '../../image/sjs_logo_img.png'
class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phoneValue: '',
            codeValue: '',
            nameValue: '',
            codeNum: 60,
            loginNum: 0,
            codeType: 0,
            type: 1,
            id: 1,
            name: '',
            url: '',
            data: null,
        };
    }

    async componentDidMount() {
        console.log(this.props.history.location.state);
        if(this.props.history.location.state){
            this.setState({
                type: this.props.history.location.state.type,
                name: this.props.history.location.state.name,
                id: this.props.history.location.state.id,
                url: this.props.history.location.state.url,
                data: this.props.history.location.state.data?this.props.history.location.state.data:null,
            })
        }
        let obj=this.getUrlParams();

    }

    getUrlParams = () => {
        let url = window.location.search;//?articleId=1&&articleNum=1&opt=edit
        let index = url.indexOf('?')
        let obj = {}
        if (index !== -1) {
            let str = url.substr(1)
            let arr = str.split('&')
            for (let i = 0; i < arr.length; i++) {
                obj[arr[i].split('=')[0]] = arr[i].split('=')[1]
            }
        }
        return obj//{articleId: "1", articleNum: "1", opt: "edit"}
    }

    onPhoneChange = (event) => {
        this.setState({
            phoneValue:  event.target.value,
        });
    }

    onCodeChange=(event)=>{
        this.setState({
            codeValue:  event.target.value,
        })
    }

    onNameChange=(event)=>{
        this.setState({
            nameValue:  event.target.value,
        })
    }

    // 获取手机号登陆验证短信
    handlePhoneLoginNote = () => {
        const {phoneValue} = this.state;
        if (phoneValue.length < 11) {
            Toast.info('手机号格式错误', 1);
            return null;
        }
        if (!/^1[0-9]{10}$/.test(phoneValue)) {
            Toast.info('手机号格式错误', 1);
            return null;
        }
        util.getYangAxios(
            api.sendCode,
            {
                phone: phoneValue,
            },
            (res) => {
                console.log(res);
                this.setState(
                    {
                        codeType: 1,
                        codeNum: 60,
                    },
                    () => {
                        const add = setInterval(() => {
                            const {codeNum} = this.state;
                            if (codeNum == 1) {
                                this.setState({
                                    codeType: 2,
                                });
                                clearInterval(add);
                            }
                            this.setState({
                                codeNum: codeNum - 1,
                            });
                        }, 1000);
                    },
                );
            },
            (err) => {
                console.log(err);
                // Toast.fail('服务器异常,请稍后重试')
            },
        );
    };

    // 登陆
    handleLogin=()=>{
        const {
            phoneValue,
            codeValue,
            type,
            nameValue,
            id,
            name,
            url,
        } = this.state;
        if((type==2)&&!nameValue){
            Toast.info('请输入您的姓名',2)
            return null;
        }
        if(!phoneValue){
            Toast.info('请输入手机号',2)
            return null;
        } if(!codeValue){
            Toast.info('请输入验证码',2)
            return null;
        }
        util.postYangAxios(
            api.login,
            {
                phone: phoneValue,
                code: codeValue,
            },
            async (res) => {
                await window.localStorage.setItem("login_token",res.token);
                if(type==2){
                    this.handleInvite();
                }else if(type==3){
                    this.handleSkipMenber();
                    // this.handleInviteClub();
                    // this.props.history.push({
                    //     pathname: '/selectChild',
                    //     state: {
                    //         id: id,
                    //         name: name,
                    //         type: 3, //1下载  2邀请
                    //     }
                    // })
                }else if(type==4){
                    window.location.href=url
                }else if(type==5){
                    this.handleInviteJpb();
                }else{
                    this.props.history.push('download')
                    let extend_id = window.localStorage.getItem("extend_id")?window.localStorage.getItem("extend_id"):'';
                    if(extend_id){
                        this.handleExtend();
                    }
                }

            },
            (err) => {
                console.log(err);
            },
        );
    }

    handleInviteJpb=()=>{
        const {data }=this.state
        util.postYangAxios(
            api.organJoin,
            data,
            (res) => {
                console.log(res);
                this.props.history.push('inviteJpbSuccess')
            },
            (err) => {
                console.log(err);
            },
        );
    }

    // 推广id接口
    handleExtend=()=>{
        util.postYangAxios(
            api.extend,
            {
            },
            (res) => {
                console.log(res);
            },
            (err) => {
                console.log(err);
            },
            true
        );
    }

    // 判断有没有会员孩子
    handleSkipMenber=()=>{
        const {
            phoneValue,
            codeValue,
            type,
            nameValue,
            id,
            name,
        } = this.state;
        util.getYangAxios(
            api.user,
            {
            },
            (g) => {
                if(g.is_bind==1){
                    this.props.history.push({
                        pathname: '/perfectInformation',
                        state: {
                            id: id,
                            name: name,
                        }
                    })
                }else{
                    util.postYangAxios(
                        api.groupInvite,
                        {
                            department_id: id,
                        },
                        (r) => {
                            util.getYangAxios(
                                api.child,
                                {
                                    is_vip: 2,
                                },
                                (res) => {
                                    console.log(res);
                                    if(res.length==0){
                                        this.props.history.push({
                                            pathname: '/memberDetail',
                                            state: {
                                                id: id,
                                                name: name,
                                                type: 3, //1下载  2邀请
                                            }
                                        })
                                    }else{
                                        this.props.history.push('inviteSuccessful')
                                    }
                                },
                                (err) => {
                                    console.log(err);
                                },
                            );
                        },
                        (err) => {
                            console.log(err);
                        },
                        true,
                    );
                }
            },
            (err) => {
                console.log(err);
            },
            true,
        );
    }

    handleInviteClub=()=>{
        const { id}=this.state
        util.postYangAxios(
            api.groupInvite,
            {
                department_id: id,
            },
            (res) => {
                console.log(res);
                this.props.history.push('inviteSuccessful')
                // this.props.history.push('memberDetail')
            },
            (err) => {
                console.log(err);
            },
            true,
        );
    }

    // 加入督导团
    handleInvite=()=>{
        const { id, nameValue}=this.state
        util.postYangAxios(
            api.invite,
            {
                child_id: id,
                title: nameValue,
            },
            (res) => {
                console.log(res);
                this.props.history.push('inviteSuccessful')
            },
            (err) => {
                console.log(err);
            },
        );
    }

    render() {
        const {
            codeType,
            codeNum,
            phoneValue,
            codeValue,
            type,
            nameValue,
        } = this.state;
        return (
            <div className={'login_view'}>
                <div className={'title_text'}>
                    登录
                </div>
                <div className={'column_center'}>
                    {/*<img src={'https://cdn.ellll.com/capphabit/logo_img.png'} alt="" className={'logo_img'}/>*/}
                    <img src={sjs_logo_img} alt="" className={'logo_member_img'}/>
                </div>
                <div className={'input_view'}>
                    {
                        (type==2)&&<div className={'input_box'} onClick={()=>{ this.nameInputs.focus()}}>
                            <input type="text" value={nameValue} onChange={this.onNameChange} className={'input_text'} placeholder={'请输入您的姓名'} ref={(c) => {
                                this.nameInputs=c;
                            }}/>
                        </div>
                    }
                    <div className={'input_box'} onClick={()=>{ this.phoneInputs.focus()}}>
            <input type="text" value={phoneValue} onChange={this.onPhoneChange} className={'input_text'} placeholder={'请输入手机号'} ref={(c) => {
                this.phoneInputs=c;
            }}/>
                    </div>
                    <div className={'row_between input_box'} onClick={()=>{ this.codeInputs.focus()}}>
            <input type="text" value={codeValue} onChange={this.onCodeChange} className={'input_text'} placeholder={'请输入验证码'} ref={(c) => {
                this.codeInputs=c;
            }}/>
                        {codeType === 0 && (
                            <div className={'code_text'} onClick={this.handlePhoneLoginNote}>
                                获取
                            </div>
                        )}

                        {codeType === 1 && (
                            <div className={'code_text'}> {codeNum}秒</div>
                        )}
                        {codeType === 2 && (
                            <div className={'code_text'} onClick={this.handlePhoneLoginNote}>重新获取</div>
                        )}
                    </div>
                </div>
               <div className={'row_center login_btn_big_view'}>
                   <div className={'row_center login_btn_view'} onClick={this.handleLogin}>
                       登录
                   </div>
               </div>


            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(Login)
