import React, { Component } from 'react'
import './css/RecommendedShowList.css'
import { withRouter, } from 'react-router-dom'
import {InfiniteScroll} from "antd-mobile-v5";
import {util} from "../../common/util";
import {api, qiNiuIP, shareIP} from "../../common/Config";
import xin_white from '../../image/xin_white.png'
import {Toast} from "antd-mobile";
class RecommendedShowList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasMore: true,
            showDataList: [],
            page: 1,
        };
    }


    componentDidMount() {
        this.onRefresh(true);
    }

    loadMore=()=>{
        return new Promise((res)=>{
           this.doRequest()
        },)

    }

    doRequest=()=>{
        console.log('加载更多')
        Toast.info('可前往习惯公社APP查看更多精彩秀一秀')
        setTimeout(()=>{
            this.setState({hasMore: false,})
        },1500)
    }

    // 页面刷新
    onRefresh = (ref) => {
        const {page, showDataList} = this.state;
        util.getYangAxios(
            api.show,
            {
                page: ref ? 1 : page,
                // screen: showTabIndex,
            },
            (r) => {
                this.setState({
                    showDataList: ref ? r.data : showDataList.concat(r.data),
                    page: ref ? 2 : page + 1,
                });
            },
            (err) => {
                console.log(err);
            },
            false,
        );
    };

    handleGoDetail=(item)=>{
        window.location.href = `${shareIP}showShareSjb?id=${item.id}`;
    }

    // 渲染
    handleItemRender=(item,index)=>{
        return (
            <div className="show_video_view" key={index} onClick={()=>this.handleGoDetail(item)}>
                <img
                    src={`${qiNiuIP}${item.cover}`}
                    alt=""
                    className={'show_video_view_img'}
                />
                <div className="show_card_box">
                    <div className="show_card_title">{item.name}</div>
                    <div className="row_between">
                        {
                            item.child&& <div className="row_align" >
                                <img src={qiNiuIP+item.child.cover+'?imageView2/5/q/75|imageslim'} className="user_img"/>
                                <div className="user_name">{item.child.name}</div>
                            </div>
                        }
                        <div className="row_align">
                            <img src={xin_white} className="xin_white"/>
                            <div className="user_name">{item.zan_sum}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { hasMore, showDataList }=this.state
        if(showDataList.length==0){
            return null;
        }
        return (
           <>
               <div className="show_video_title">推荐</div>
               <div className={'row_between_warp ph30'}>
                   {
                       showDataList.map(this.handleItemRender)
                   }
               </div>
               <InfiniteScroll loadMore={this.loadMore} hasMore={hasMore} />
           </>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default withRouter(RecommendedShowList);
