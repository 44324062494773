import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/invite.css'
import {util} from "../../common/util";
import {api} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
class Invite extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inviteObj: null,
            inviteType: 1,
            phoneValue: '',
            codeValue: '',
            codeNum: 60,
            codeType: 0,
        };
    }

    componentDidMount() {
        let obj=this.getUrlParams();
        console.log(obj);
        if(obj){
            this.setState({
                inviteObj: obj,
            })
        }else{
            // Toast.info('您想加入的组织无效')
        }
    }


    getUrlParams = () => {
        console.log(window.location)
        let url = decodeURI(window.location.href);
        let index = url.indexOf('?')
        let obj = {}
        if (index !== -1) {
            let str = url.split('?')[1]
            let arr = str.split('&')
            for (let i = 0; i < arr.length; i++) {
                obj[arr[i].split('=')[0]] = arr[i].split('=')[1]
            }
        }
        return obj//{articleId: "1", articleNum: "1", opt: "edit"}
    }


    handleJoin=()=>{
        const { inviteObj }=this.state

    }

    onPhoneChange = (event) => {
        this.setState({
            phoneValue:  event.target.value,
        });
    }

    onCodeChange=(event)=>{
        this.setState({
            codeValue:  event.target.value,
        })
    }

    // 获取手机号登陆验证短信
    handlePhoneLoginNote = () => {
        const {phoneValue} = this.state;
        if (phoneValue.length < 11) {
            Toast.info('手机号格式错误', 1);
            return null;
        }
        if (!/^1[0-9]{10}$/.test(phoneValue)) {
            Toast.info('手机号格式错误', 1);
            return null;
        }
        util.postYangAxios(
            api.sendCode,
            {
                phone: phoneValue,
            },
            (res) => {
                console.log(res);
                this.setState(
                    {
                        codeType: 1,
                        codeNum: 60,
                    },
                    () => {
                        const add = setInterval(() => {
                            const {codeNum} = this.state;
                            if (codeNum == 1) {
                                this.setState({
                                    codeType: 2,
                                });
                                clearInterval(add);
                            }
                            this.setState({
                                codeNum: codeNum - 1,
                            });
                        }, 1000);
                    },
                );
            },
            (err) => {
                console.log(err);
                // Toast.fail('服务器异常,请稍后重试')
            },
        );
    };

    // 提交
    handleSubmit=()=>{
        const { inviteObj, inviteType,codeNum, codeType,
            phoneValue,
            codeValue, }=this.state
        const isLogin = localStorage.getItem("login_token");
        console.log(isLogin)
        if(isLogin){
            this.handleInvite()
        }else{
            this.props.history.push({
                pathname: '/login',
                state: {
                    id: inviteObj.id,
                    type: 2, //1下载  2邀请
                }
            })
        }
    }

    // 加入督导团
    handleInvite=()=>{
        const { inviteObj}=this.state
        if(!inviteObj||!inviteObj.id){
            Toast.info('邀请人不存在',2)
            return null;
        }
        util.postYangAxios(
            api.invite,
            {
                child_id: inviteObj.id,
            },
            (res) => {
                console.log(res);
                this.props.history.push('inviteSuccessful')
            },
            (err) => {
                console.log(err);
            },
        );
    }

    render() {
        const {userData} =this.props.UserDataReducer;
        const { inviteObj, inviteType,codeNum, codeType,
            phoneValue,
            codeValue, }=this.state
        const inviteStyle = {
            width: "100%",
            backgroundSize: '100%,100%',
            backgroundImage: `url(https://cdn.ellll.com/capphabit/invite_bg.png)`,
            backgroundRepeat: "no-repeat",
            backgroundColor: '#FFFFFF'
        };
        return (
            <div style={inviteStyle} className={"invite_view column_between"}>
                <div className={'row_between invite_close_box'}>
                    <img src={'https://cdn.ellll.com/capphabit/invite_close_img.png'} alt="" className={'invite_close_img'} style={{opacity: 0}}/>
                    <div className={'invite_title_text'}>邀请</div>
                    <img src={'https://cdn.ellll.com/capphabit/invite_close_img.png'} alt="" className={'invite_close_img'} onClick={()=>{
                        this.props.history.push({
                            pathname: '/loginPage',
                            state: {
                                id: inviteObj.id,
                                type: 2, //1下载  2邀请
                            }
                        })
                    }}/>
                </div>
                <div className={'column_end invite_join_view'}>
                    <img src={'https://cdn.ellll.com/capphabit/xggs_invite_logo.png'} alt="" className={'xggs_invite_logo'}/>
                    {
                        inviteType==1? <>
                            <div className={'invite_join_name'}>
                                {inviteObj&&inviteObj.name}
                            </div>
                            <div className={'invite_join_content'}>
                                {inviteObj&&inviteObj.content}
                            </div>
                            {
                                inviteObj&&  <div className={'invite_join_btn row_center'} onClick={this.handleSubmit}>
                                    加入
                                </div>
                            }
                        </>: <>
                            <div className={'row_align invite_input_view'} onClick={()=>{ this.phoneInputs.focus()}}>
                                <div className={'invite_input_box'}>手机号码</div>
                                <input type="text" value={phoneValue} onChange={this.onPhoneChange} className={'invite_input_text'} placeholder={'请输入手机号'} ref={(c) => {
                                    this.phoneInputs=c;
                                }}/>
                            </div>
                            <div className={'row_align invite_input_view'} onClick={()=>{ this.codeInputs.focus()}}>
                                <div className={'invite_input_box'}>验证码</div>
                                <input type="text" value={codeValue} onChange={this.onCodeChange} className={'invite_input_text'} placeholder={'请输入验证码'} ref={(c) => {
                                    this.codeInputs=c;
                                }}/>
                                {codeType === 0 && (
                                    <div className={'code_text'} onClick={this.handlePhoneLoginNote}>
                                        获取
                                    </div>
                                )}

                                {codeType === 1 && (
                                    <div className={'code_text'}> {codeNum}秒</div>
                                )}
                                {codeType === 2 && (
                                    <div className={'code_text'} onClick={this.handlePhoneLoginNote}>重新获取</div>
                                )}
                            </div>
                            <div className={'invite_join_btn row_center'} style={{marginTop: 20}} onClick={this.handleSubmit}>
                                立即登录
                            </div>
                        </>
                    }
                </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(Invite)
