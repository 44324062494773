import React, { Component } from 'react'
import './css/empty.css'
import { withRouter, } from 'react-router-dom'
import class_empty_img from '../../image/class_empty_img.png'
class EmptyStatePage extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  componentDidMount() {
  }


  render() {
    return (
        <div className={'column_center'}>
          <img src={class_empty_img} alt="" className={'class_empty_img'}/>
          <div className={'class_empty_title'}>
            {this.props.title?this.props.title:'暂无数据'}
          </div>
        </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default withRouter(EmptyStatePage);
