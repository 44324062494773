import React, { Component } from 'react'
import { withRouter, } from 'react-router-dom'
import {Modal} from "antd";
import close_btn_img from "../../image/spell/close_btn_img.png";
import group_main_img from "../../image/spell/group_main_img.png";
import user_default_icon from "../../image/user_default_icon.png";
import EmptyStatePage from "../Empty/EmptyStatePage";
import DateCountdownText from "./DateCountdownText";
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";

class SpellGroupModal extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {
    }


    render() {
        const { collageList } = this.props;
        if(!collageList||collageList.length==0){
            return null;
        }
        return (
            <Modal visible={this.props.visible} onCancel={this.props.onCancel} centered footer={null} closable={false} bodyStyle={{padding: 0,borderRadius: '1.1rem'}} width={'21rem'} wrapClassName={'modal_br'}>
                <div className={'spell_group_modal_title'}>可参与拼单</div>
                <img src={close_btn_img} alt="" className={'close_btn_img'} onClick={this.props.onCancel}/>
                <div style={{overflowY: 'scroll',height: '18.47rem'}}>
                    {
                        collageList.length>0?collageList.slice(0,10).map((item,index)=>{
                            return (
                                <div key={index} className={'row_between spell_group_modal_box'}>
                                    <div className={'row_align'} style={{position: 'relative'}}>
                                        <img src={item.oder_bill.length>0&&item.oder_bill[0].user_icon?qiNiuIP+item.oder_bill[0].user_icon:user_default_icon} alt="" className={'spell_group_user_icon'}/>
                                        <img src={group_main_img} alt="" className={'group_main_img'}/>
                                        <div>
                                            <div className={'spell_group_modal_user_name'}>{item.oder_bill.length>0&&item.oder_bill[0].user_name} 还差{item.total_num-item.now_num}人拼成</div>
                                            <div className={'spell_group_modal_user_date'}>剩余<DateCountdownText date={item.expire_at}/></div>
                                        </div>
                                    </div>
                                    <div className={'spell_user_btn_view row_center'} onClick={()=>this.props.handlePingTuan(item)}>
                                        去拼单
                                    </div>
                                </div>
                            )
                        }):<EmptyStatePage/>
                    }
                </div>
                <div className={'spell_group_modal_label'}>
                    仅显示10个可参与的拼单
                </div>
            </Modal>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default withRouter(SpellGroupModal);
