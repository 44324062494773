import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import {util} from "../../common/util";
import {api} from "../../common/Config";

class Download extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wxShow: false,
            query:{},
            platform:'',
            isHuawei:false
        };
    }

    componentDidMount(){
        let obj=this.getUrlParams();
        console.log(obj);
        if(obj){
            this.setState({
                query: obj,
            })
        }
        let uc = window.navigator.userAgent.toLowerCase();
        if(uc.match(/MicroMessenger/i) == 'micromessenger'){      //判断是否是微信环境
            this.setState({
                wxShow: true,
            })
        }
        let ua = navigator.userAgent
        if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            console.log('苹果')
            this.setState({
                platform:'ios'
            })
           
        } else if (ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1) {
            console.log('安卓')
            this.setState({
                platform:'android'
            })
            if(ua.match(/huawei/i)){
                this.setState({
                    isHuawei:true
                })
            }
            
        }
        
    }
    getUrlParams = () => {
        console.log(window.location)
        let url = decodeURI(window.location.href);
        let index = url.indexOf('?')
        let obj = {}
        if (index !== -1) {
            let str = url.split('?')[1]
            let arr = str.split('&')
            for (let i = 0; i < arr.length; i++) {
                obj[arr[i].split('=')[0]] = arr[i].split('=')[1]
            }
        }
        return obj//{articleId: "1", articleNum: "1", opt: "edit"}
    }

    // 下载按钮
    handleSkipDown=()=>{
        this.handleDownload();
        let ua = navigator.userAgent
        if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            console.log('苹果')
            window.location.href ="https://apps.apple.com/cn/app/%E4%B9%A0%E6%83%AF%E5%85%AC%E7%A4%BE/id1481559141";
        } else if (ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1) {
            console.log('安卓')
            if(this.state.query&&decodeURI(this.state.query.source)==='huawei'){
                window.location.href ="https://appgallery.huawei.com/#/app/C101103709";
            }else{
                window.location.href ="https://sj.qq.com/myapp/detail.htm?apkName=com.xz.xggs";
            }
        }
    }
    handleSkipDownAppStore=()=>{
        this.handleDownload();
        let ua = navigator.userAgent
        if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            console.log('苹果')
            window.location.href ="https://apps.apple.com/cn/app/%E4%B9%A0%E6%83%AF%E5%85%AC%E7%A4%BE/id1481559141";
        } else if (ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1) {
            console.log('安卓')
            if(this.state.query&&decodeURI(this.state.query.source)==='huawei'){
                window.location.href ="https://appgallery.huawei.com/#/app/C101103709";
            }else if(ua.match(/huawei/i)){
                console.log("这是华为手机")
                window.location.href ="https://appgallery.huawei.com/#/app/C101103709";
            }else{
                window.location.href ="https://sj.qq.com/myapp/detail.htm?apkName=com.xz.xggs";
            }
        }
    }

    // 下载接口
    handleDownload=()=>{
        let ua = navigator.userAgent
        util.postYangAxios(
            api.download,
            {
                // app_type: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)?1:2
            },
            (res) => {
                console.log(res);
            },
            (err) => {
                console.log(err);
            },
            true
        );
    }
    handleSkipDownStore=()=>{
        this.handleDownload();
        window.location.href ="https://www.pgyer.com/seKK";
    }

    render() {
        const { wxShow ,platform,isHuawei} = this.state;
        var height = window.innerHeight;
        return (
            <div className={'down_sjb_container'} style={{height:height+'px'}}>
                <img src={'https://cdn.ellll.com/capphabit/huaSiDa_logo.png'} className="huaSiDa_logo" alt=""   />
                <img src={'https://cdn.ellll.com/capphabit/sjb_logo.png'} className="sib_logo" alt=""   />
                <div className="download_btns">
                    {(platform==='ios'||isHuawei)&&<div className="load_btn" onClick={this.handleSkipDownAppStore}>应用商店下载</div>}
                    {/* 应用宝下载 */}
                   {platform==='android'&&<div className="load_btn" onClick={this.handleSkipDownStore}>直接下载</div>}
                </div>
                {
                    wxShow&& <img src={'https://cdn.ellll.com/capphabit/open_browser_img.png'} className="open_browser_img" alt="" />
                }

                <div className='down_sjb_company_container'>
                     <div  className='down_sjb_company_tit'>国家教育部教备号：教APP备3100753号</div>
                     <div  className='down_sjb_company_tit'>技术支持：上海形者网络科技发展有限公司</div>
                </div>

            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(Download)
