import React, { Component } from 'react'
import { withRouter, } from 'react-router-dom'
import { Modal, Button, WhiteSpace, WingBlank, Icon } from 'antd-mobile';
import close_btn_img from "../../image/spell/close_btn_img.png";
import group_main_img from "../../image/spell/group_main_img.png";
import user_default_icon from "../../image/user_default_icon.png";
import EmptyStatePage from "../Empty/EmptyStatePage";
import addr_icon from "../../image/spell/addr_icon.png";
import xggs_logo from "../../image/spell/xggs_logo.png";
import close_black_cha_icon from "../../image/spell/close_black_cha_icon.png";
import addr_right_icon from "../../image/spell/addr_right_icon.png";
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";

class SpellMakeOrderModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            num: 1,
            specObj: null,
            specList:[],
            addrObj: null, // 默认地址
        };
    }


    componentDidMount() {
        this.handleGetSpec();
        if(this.props.type==2){

        }
        this.handleGetAddr()
    }

    // 获取默认地址
    handleGetAddr = () => {
        util.getYangAxios(
            api.userAddressDefault,
            {
            },
            (res) => {
                console.log(res);
                this.setState({
                    addrObj: res.info
                })
            },
            (err) => {
                console.log(err);
            },
            true,
        );
    };

    // 获取商品规格
    handleGetSpec = () => {
        const { goodObj }=this.props;
        const { goodId }=this.state;
        util.getYangAxios(
            api.goodSpec+`/${goodObj.id}`,
            {
            },
            (res) => {
                console.log(res);
                let specObj= null;
                if(res.spec.length>0){
                    let obj=res.spec.find((fruit)=>{
                        return fruit.repertory>2;
                    })
                    specObj= obj
                }
                this.setState({
                    specList: res.spec,
                    specObj
                })
            },
            (err) => {
                console.log(err);
            },
            true,
        );
    };

    // 增加数量
    handleAddNum=(number)=>{
        const { num }=this.state;
        let a=num+number;
        if(a<=0){

        }else{
            this.setState({
                num:a,
            })
        }
    }

    render() {
        const { num, specObj, specList, addrObj }=this.state;
        const { goodObj, payType,type }=this.props;
        return (
            <Modal
                popup
                visible={this.props.visible}
                onClose={this.props.onCancel}
                animationType="slide-up"
            >
                <div className={'spell_make_order_title row_between ph30'}>
                    <img src={close_black_cha_icon} alt="" className={'close_black_cha_icon'} style={{opacity: 0}}/>
                    确认订单
                    <img src={close_black_cha_icon} alt="" className={'close_black_cha_icon'} onClick={this.props.onCancel}/>
                </div>
                {
                    type!=1&& <>
                        {
                            addrObj? <div className={'spell_make_addr_div row_between'} onClick={()=>this.props.history.push('addressList')}>
                                <div className={'row_align'}>
                                    <img src={addr_icon} alt="" className={'addr_icon'}/>
                                    <div className={'addr_details_div'}>
                                        {addrObj.name}，{addrObj.phone}，{addrObj.province} {addrObj.city} {addrObj.area}
                                        {addrObj.address}
                                    </div>
                                </div>
                                <img src={addr_right_icon} alt="" className={'addr_right_icon'}/>
                            </div>:<div className={'spell_make_addr_div row_between'} onClick={()=>this.props.history.push('addressList')}>
                                <div className={'row_align'}>
                                    <img src={addr_icon} alt="" className={'addr_icon'}/>
                                    <div className={'addr_details_div'}>
                                        请先添加收货地址
                                    </div>
                                </div>
                                <img src={addr_right_icon} alt="" className={'addr_right_icon'}/>
                            </div>
                        }
                        <div className={'gray_line'}/>
                    </>
                }
                {
                    specObj&&<div className={'spell_make_shop_div'}>
                        <div className={'row_align'}>
                            <img  src={goodObj.image.length>0&&goodObj.image[0]?`${qiNiuIP}${goodObj.image[0]}`:xggs_logo} alt="" className={'spell_make_shop_img'}/>
                            <div style={{textAlign: 'left'}}>
                                <div className={'spell_make_shop_price'}>¥{payType==1?specObj.price_sell:specObj.price_spell}</div>
                                <div className={'spell_make_shop_title'}>{payType==1?'':`${goodObj.spell_num}人成团`}</div>
                                <div className={'spell_make_shop_title'}>已选择：{specObj.spec_name}</div>
                                {
                                    type!=1&& <div className={'row_align'} style={{marginTop: '0.2rem'}}>
                                        <div className={'row_center spell_make_shop_reduction'} onClick={()=>this.handleAddNum(-1)}>-</div>
                                        <div className={'spell_make_shop_num_text'}>{num}</div>
                                        <div className={'row_center spell_make_shop_add'} onClick={()=>this.handleAddNum(1)}>+</div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={'spell_make_name_text'}>型号</div>
                        <div className={'spell_make_classification_box'}>
                            <div className={'row_warp'}>
                                {
                                    specList.length>0&&specList.map((item,index)=>{
                                        return (
                                            <div className={'row_center spell_make_classification_div'} key={index} style={{background: specObj&&item.id==specObj.id?'#FE6B02': '#F4F5F6',color: specObj&&item.id==specObj.id?'#fff': '#000'}} onClick={()=>this.setState({specObj: item})}>
                                                {item.spec_name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={'spell_make_pay_btn row_center'} onClick={()=>this.props.handlePay(specObj,num)}>
                            立即支付 ¥{(payType==1?specObj.price_sell:specObj.price_spell)*10000*num/10000}
                        </div>

                    </div>
                }
            </Modal>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default withRouter(SpellMakeOrderModal);
