import React, { Component } from 'react'
import JsTypeFunction from "../../common/JsTypeFunction";

/**
 * 动态倒计时文字
 * @export
 * @class StickyHeader
 */
export default class DateCountdownText extends Component {

    constructor(props) {
        super(props);
        this.state = {
            num: 0,
        };
    }

    componentDidMount() {
       this.inter=setInterval(()=>{
           this.setState({
               num: this.state.num++,
           });
       },1000)
    }

    componentWillUnmount() {
        this.inter&&clearInterval(this.inter);
    }


    render() {
        if(!this.props.date){
            return null;
        }
        return (
           <span>{JsTypeFunction.dateOverCount(this.props.date)}</span>
        )
    }
}

