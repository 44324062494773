import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/invite.css'
class InviteSuccessful extends Component {

    constructor(props) {
        super(props);
        this.state = {
            num: 5
        };
    }

    componentDidMount() {
        this.inval=setInterval(()=>{
            if(this.state.num==0){
                clearInterval(this.inval)
                this.handleSkip()
            }
            this.setState({
                num: this.state.num-1
            })
        },1000)
    }

    componentWillUnmount() {
        this.inval&&clearInterval(this.inval)
    }

    // 跳抓
    handleSkip=()=>{
        this.inval&&clearInterval(this.inval)
        this.props.history.push('download')
    }

    render() {
        return (
            <div className={"invite_view column_align"} style={{ minHeight: window.innerHeight + 'px',}}>
                <div className={'row_between invite_close_box'}>
                    <img src={'https://cdn.ellll.com/capphabit/invite_close_img.png'} alt="" className={'invite_close_img'} style={{opacity: 0}}/>
                    <div className={'invite_title_text'}>邀请</div>
                    <img src={'https://cdn.ellll.com/capphabit/invite_close_img.png'} alt="" className={'invite_close_img'} onClick={this.handleSkip}/>
                </div>
                <img src={'https://cdn.ellll.com/capphabit/xggs_sjb_logo.png'} alt="" className={'xggs_sjb_logo'}/>
                <div className={'xggs_sjb_logo_text'}>习惯公社私教版</div>
                <div className={'invite_successful_title'}>
                    恭喜您，加入成功！
                </div>
                <div className={'invite_successful_content'}>
                    您将在 <span style={{color: '#FE6B02'}}>{this.state.num}</span> S后，进入下载页<br/>
                    如已下载APP，请点击跳转，进入习惯公社APP
                </div>
                <div className={'invite_join_view'}>
                    <div className={'invite_join_btn row_center'} onClick={this.handleSkip}>
                        跳转
                    </div>
                </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(InviteSuccessful)
