import React, { Component } from 'react'
import './css/header.css'
import { withRouter, } from 'react-router-dom'

class HeaderView extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {
    }


    render() {
        return (
            <div className={'row_between title_text'}>
                <img src={'https://cdn.ellll.com/capphabit/back_black_icon.png'} alt="" className={'back_black_icon'} onClick={()=>this.props.history.goBack()}/>
                <div>
                    {this.props.title}
                </div>
                <img src={'https://cdn.ellll.com/capphabit/back_black_icon.png'} alt="" className={'back_black_icon'} style={{opacity: 0}}/>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default withRouter(HeaderView);
