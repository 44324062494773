import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import {util} from "../../common/util";
import {api} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";


class JpbProtocol extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        const {userData} =this.props.UserDataReducer;
        const { }=this.state
        return (
            <div className={"article_view"} style={{paddingBottom: '30px'}}>
                <div style={{fontSize: '32px'}}>教培版-习惯公社，教培版习惯公社用户协议</div>
                <div style={{fontSize: '24px'}}>
                    发布日期：2019年10月10日<br/>
                    生效日期：2019年10月10日<br/>
                    教培版-习惯公社，教培版习惯公社用户协议（以下简称“本协议”）由以下双方根据《中华人民共和国合同法》等相关法律法规签署并遵照执行。<br/>
                    用户：在手机、平板电脑、电脑等电子客户端注册成为教培版-习惯公社，教培版习惯公社应用软件（以下简称“教培版-习惯公社，教培版习惯公社”“教培版-习惯公社，教培版习惯公社平台”亦或“平台”）的使用者，通过教培版-习惯公社，教培版习惯公社发布、查看、接收图文/音频/视频信息或其他文件，或可与其他用户进行延时/实时交流的终端用户（以下简称“用户”）。<br/>
                    平台方：上海形者网络科技发展有限公司，一家为用户提供教培版-习惯公社，教培版习惯公社软件服务的公司（以下简称“平台方”）。<br/>
                    本协议“用户”包括但不限于教师身份用户、家长身份用户和学生身份用户。<br/>
                    第一条 用户注册<br/>
                    1.1用户下载教培版-习惯公社，教培版习惯公社并根据教培版-习惯公社，教培版习惯公社的注册规则完成注册程序（包括但不限于设置用户名称及密码）后，即有权通过教培版-习惯公社，教培版习惯公社发布、查看、接收图文/音频/视频信息或其他文件，或可与其他用户进行延时/实时交流。用户在教培版-习惯公社，教培版习惯公社上有效注册（或不时有效变更注册）的用户名及密码（以下简称“用户名及密码”），共同构成用户通过教培版-习惯公社，教培版习惯公社发布、查看、接收图文/音频/视频信息或其他文件，或可与其他用户进行延时/实时交流的唯一有效身份证明。<br/>
                    1.2用户一旦完成注册，则视为用户允许平台方通过短信、电子邮件、APP/服务器推送或其他方式向其发送与教培版-习惯公社，教培版习惯公社相关的信息。<br/>
                    1.3用户应当保证其完成上述注册（或不时的有效变更注册）时所提供的身份信息及电话、电子邮箱等必要信息真实、准确、有效；如该等信息有任何变动，用户应当在三（3）日内通过教培版-习惯公社，教培版习惯公社完成信息更新。因用户提供虚假或无效信息导致平台方或其他用户遭受损失的，用户应当承担全部的赔偿责任。<br/>
                    1.4用户应妥善保管用户名及密码，除经平台方事先书面同意或本协议另有约定外，不得将其赠予、转让、出售或出借于他人使用；如用户发现其用户名及密码遭他人使用，应立即通知平台方以避免损失或损失扩大。因网络攻击、用户保管用户名及密码不当、转让或出借用户名及密码、怠于履行本协议及其他相关协议下的通知义务等情形或其他非平台方原因，导致用户未能根据本协议使用教培版-习惯公社，教培版习惯公社或遭受任何损失的，均由用户自行承担，平台方不承担任何责任。<br/>
                    1.5用户确认，在用户开始注册使用教培版-习惯公社，教培版习惯公社前，用户应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若用户不具备前述与用户行为相适应的民事行为能力，则用户及用户的监护人应依照法律规定承担因此而导致的一切后果（未成年人阅读时应得到其监护人的陪同）。<br/>
                    1.6用户注册的账户为用户自行设置并由用户保管，平台方任何时候均不会主动要求用户提供账户密码。用户账户因用户的主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果，平台方并不承担责任，用户应通过司法、行政等救济途径向侵权行为人追偿。<br/>
                    1.7如用户注册的账户符合以下条件，则平台方可回收用户注册的账户，用户将不能再登录教培版-习惯公社，教培版习惯公社平台，相应服务同时终止：<br/>
                    （一）经发现非由注册用户本人实际使用的；<br/>
                    （二）连续三个月未用于登录教培版-习惯公社，教培版习惯公社平台；<br/>
                    （三）其他平台方认为有必要的情形。<br/>
                    1.8用户一旦完成注册，即视为完全了解、接受并同意遵守本协议项下的全部内容并受限于本协议相关条件和条款。平台方有权根据法律法规、政策以及运营中的实际情况对协议进行修改，并对用户进行公布。修改后的协议条款一经平台方公布即代替本协议的原条款，构成用户与平台方之间就本协议的全部、最新协议。用户可以随时在教培版-习惯公社，教培版习惯公社应用程序中查阅最新协议条款。如果用户不接受平台方修改后的最新协议条款，请立即停止使用教培版-习惯公社，教培版习惯公社。如用户选择继续使用教培版-习惯公社，教培版习惯公社，则视为用户完全了解、接受并同意遵守平台方修改后的最新协议条款。用户承认并认可，已经完全了解并理解本协议相关内容，对本协议及相关内容不存在任何重大误解；同时认可本协议内容并不存在显失公平的情形，用户与平台方在协议中处平等地位，用户对协议的接受与否具有自由选择的权利。<br/>
                    第二条 服务内容<br/>
                    2.1平台方为用户提供发布、查看、接收图文/音频/视频信息或其他文件，或可与其他用户进行延时/实时交流的服务。<br/>
                    2.2平台方作为教培版-习惯公社，教培版习惯公社软件的设计/开发/所有/经营者，有权对于教培版-习惯公社，教培版习惯公社软件不时作出更新和调整，只要用户仍为教培版-习惯公社，教培版习惯公社的注册用户，即视为其同意平台方作出的任何变更和更新。<br/>
                    2.3平台方仅为用户提供发布、查看、接收图文/音频/视频信息或其他文件，或可与其他用户进行延时/实时交流的技术支持，因用户自身原因造成的延迟确认、延迟回复、未按时保存、操作失误等情形，平台方不承担任何责任。<br/>
                    2.4用户应当知晓在使用教培版-习惯公社，教培版习惯公社软件的过程中可能存在由其他用户进行通知、提醒、审批、定时、评论、发起调查、投票等行为设置，平台方仅为前述行为提供技术支持，前述行为均为用户自身行为，与平台方无关。<br/>
                    2.5用户应当知晓在使用教培版-习惯公社，教培版习惯公社软件的过程中可能涉及第三方服务软件的参与（包括但不限于微信、QQ、支付宝等），因前述第三方服务软件所造成的信息提供错误、信息提供延误、信息泄露、连接错误、服务器故障等情形与平台方无关。<br/>
                    第三条 各方的权利义务<br/>

                    3.1用户的权利义务<br/>
                    3.1.1用户应遵守中国有关的法律法规规定及本协议下的相关义务。<br/>
                    3.1.2用户向教培版-习惯公社，教培版习惯公社平台提供的各类信息应真实、准确、完整；用户在教培版-习惯公社，教培版习惯公社发布的各类信息（包括但不限于图文、音频、视频等）应符合中国有关的法律法规；用户通过教培版-习惯公社，教培版习惯公社进行的延时/实时交流活动应当符合中国有关的法律法规。经平台方发现用户违反前述约定的，用户向教培版-习惯公社，教培版习惯公社平台提供/发布的信息将不再适用本合同有关隐私保护的约定，平台方有权公示、向政府部门进行举报或直接向用户追究法律责任。平台方在任何时候有权验证用户提供/发布的信息，由于用户提供虚假或不完整信息所导致的任何责任和损失，应由用户承担。<br/>
                    3.1.3用户利用教培版-习惯公社，教培版习惯公社平台发布虚假、侵犯他人隐私、侵犯他人知识产权、侮辱他人、造谣诽谤等违反法律法规或公德良俗的内容从而给平台方、其他用户或第三方造成损害的，由前述用户承担全部赔偿责任。<br/>
                    3.1.4用户不得有任何损害平台方权益的行为，若用户损害平台方权益的，平台方有权要求用户承担赔偿责任，情节严重的平台方将保留追究法律责任的权利。<br/>
                    3.1.5用户不得利用本协议项下平台方和其他用户从事违法违规活动，也不得要求平台方和其他用户进行违法违规活动。<br/>
                    3.1.6用户应当保证发布/查看、接收图文/音频/视频信息或其他文件时是自愿的。<br/>
                    3.1.7平台方暂时免费向用户提供本协议约定之服务，但平台方保留将来向用户收取费用的权利。用户之间产生的任何费用支付均与平台方无关。<br/>
                    3.2平台方的权利义务<br/>
                    3.2.1教培版-习惯公社，教培版习惯公社平台提供用户发布或接受信息/文件并与教培版-习惯公社，教培版习惯公社平台及时联接的终端设备（包括但不限于手机、平板电脑、电脑等电子客户端）中的应用程序，并在教培版-习惯公社，教培版习惯公社平台的变更、更新、优化后，及时通知或协助用户更新终端设备中的应用程序。<br/>
                    3.2.2各方同意，教培版-习惯公社，教培版习惯公社平台无法保证其所提供的信息中没有任何错误、缺陷、恶意软件或病毒。对于因使用（或无法使用）教培版-习惯公社，教培版习惯公社平台导致的任何损害，教培版-习惯公社，教培版习惯公社平台不承担责任（除非此类损害是由教培版-习惯公社，教培版习惯公社平台的故意或重大疏忽造成的）。此外，对于因使用（或无法使用）与教培版-习惯公社，教培版习惯公社平台的电子通信手段导致的任何损害，包括但不限于因电子通信传达失败或延时、第三方或用于电子通信的计算机程序对电子通信的拦截或操纵，以及病毒传输导致的损害，教培版-习惯公社，教培版习惯公社平台不承担责任。<br/>
                    第四条 其他责任约定<br/>
                    4.1因不可预测或无法克服的原因导致平台方未能按约提供服务的，平台方不承担责任。<br/>
                    4.2服务过程中因协议各方以外的其他方原因造成的损失，由该其他方承担赔偿责任。<br/>
                    4.3 用户不得使用教培版-习惯公社，教培版习惯公社制作、上传、发送、传播敏感信息和违反国家法律制度的信息，包括但不限于下列信息:<br/>
                    (1) 反对宪法所确定的基本原则的；<br/>
                    (2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br/>
                    (3) 损害国家荣誉和利益的；<br/>
                    (4) 煽动民族仇恨、民族歧视，破坏民族团结的；<br/>
                    (5) 破坏国家宗教政策，宣扬邪教和封建迷信的；<br/>
                    (6) 散布谣言，扰乱社会秩序，破坏社会稳定的；<br/>
                    (7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br/>
                    (8) 侮辱或者诽谤他人，侵害他人合法权益的；<br/>
                    (9) 含有法律、行政法规禁止的其他内容的。<br/>
                    4.4 用户同意在任何情况下不使用其他用户的帐号或密码。在您怀疑他人在使用您的帐号或密码时，您同意立即通知教培版-习惯公社，教培版习惯公社公司。<br/>
                    4.5 用户有权更改、删除在教培版-习惯公社，教培版习惯公社服务中的个人资料、注册信息及传送内容等，但删除有关信息的同时也会删除任何您储存在系统中的文字和图片。用户需承担该风险。<br/>
                    4.6 如因用户违反本协议中的任何条款，教培版-习惯公社，教培版习惯公社公司有权依据本协议终止对违约用户教培版-习惯公社，教培版习惯公社帐号提供服务。<br/>
                    4.7 如用户注册教培版-习惯公社，教培版习惯公社帐号后长期不登录该帐号，教培版-习惯公社，教培版习惯公社公司有权回收该帐号，以免造成资源浪费，由此带来问题均由用户自行承担。<br/>
                    第五条 用户信息<br/>
                    5.1 用户个人信息包括但不限于下列信息：用户真实姓名、性别、职业、任职/就读学校、头像、手机号码、IP地址等。<br/>
                    5.2用户非个人信息包括但不限下列信息：一切属于第5.1条所述的用户个人信息范围以外的信息，均为普通信息，不属于用户个人信息；包括但不限于用户对教培版-习惯公社，教培版习惯公社服务的操作状态、使用记录、使用习惯等反应在平台方服务器端的全部记录信息。<br/>
                    5.3重要提示：为向客户提供本协议所述服务，平台方将可能合理使用用户个人信息和非用户个人信息（前述合称“用户信息”）。用户一旦注册、登录、使用教培版-习惯公社，教培版习惯公社，将视为用户完全了解、同意并接受平台方通过包括但不限于收集、统计、分析、使用等方式合理使用用户信息，无需其他意思表示。为向用户完整地提供包括但不限于本协议所述的服务，平台方将可能要求用户上传用户信息（包括但不限于通讯录等），用户一旦选择上传用户信息，将视为用户完全了解、同意并接受平台方基于向用户提供服务的目的读取并合理使用用户信息。<br/>
                    5.4用户认可其已完全了解平台方使用用户信息的目的在于为用户提供包括本协议所述的服务或将来可能新增的服务，平台方使用用户信息的方式包括但不限于：收集、统计、分析、商业用途的使用等方式；平台方使用用户信息的范围包括但不限于本条第5.1条、5.2条、5.3条所定义的信息等。<br/>
                    5.5用户持续使用教培版-习惯公社，教培版习惯公社的行为视为其对平台方就用户信息的持续授权。用户可以通过注销账号的方式删除用户信息。<br/>
                    5.6平台方对用户信息的使用无需向用户支付任何费用，并且在用户同意本协议的基础上，无需向用户另行取得授权。<br/>
                    5.7平台方尊重用户的合法权利，不会以违反法律、行政法规以及本协议约定的方式收集、使用用户信息。同时，平台方不会主动向使用教培版-习惯公社，教培版习惯公社软件过程中涉及的任何第三方提供用户信息。如第三方需要通过平台获取用户信息的，平台方将以显著的方式告知用户，并取得用户的同意。<br/>
                    5.8非因平台方违反本协议的约定而导致的用户信息的泄露与平台方无关。任何用户（包括但不限于教师身份用户、家长身份用户、学生身份用户）不得利用、泄露、散播通过教培版-习惯公社，教培版习惯公社平台获取的其他用户的用户信息；用户发生前述行为的，平台方不承担任何责任，由前述侵权用户承担全部责任。任何平台方、用户外的第三人不得利用、泄露、散播通过教培版-习惯公社，教培版习惯公社平台获取的其他用户的用户信息，第三人发生前述行为的，平台方不承担任何责任，由前述侵权方承担全部责任。<br/>
                    5.9平台方对因不可抗力、按照有关法律法规要求、按照相关政府主管部门/司法机关的要求而披露用户信息的行为不承担任何责任。<br/>
                    第六条 未成年人个人信息<br/>

                    6.1 根据平台方提供的服务，平台方合理使用的用户信息中可能包含未成年人个人信息。<br/>
                    6.2平台方将在遵守《隐私政策》相关规定的情况下，按照本协议约定使用未成年人个人信息。<br/>
                    第七条 知识产权<br/>
                    7.1 知识产权是指与教培版-习惯公社，教培版习惯公社服务相关的各种类的过去有效的、现行有效的、或即将产生的知识产权，包括但不限于商标、著作权、计算机软件、发明、实用新型、外观设计以及相关申请的权利。<br/>
                    7.2平台方为与教培版-习惯公社，教培版习惯公社服务相关的全部知识产权的权利人，对教培版-习惯公社，教培版习惯公社服务提供过程中包含的全部知识产权，包括但不限于任何文本、图片、图形、音频和/或视频资料享有及保留完整、独立的全部权利。未经平台方同意，用户及第三人不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行平台方享有的知识产权或者平台方提供的任何资料和信息，也不得将前述资料和信息用于任何商业目的。<br/>
                    7.3对于用户本人创作并上传到教培版-习惯公社，教培版习惯公社的任何图文/音频/视频等，平台方保留对其内容进行实时监控的权利，并有权依平台方独立判断对任何违反本协议约定或涉嫌违法、违规的内容实施删除。平台方对于删除用户作品引起的任何后果或导致用户的任何损失不负任何责任。<br/>
                    7.4知识产权条款持续有效，不因用户关闭教培版-习惯公社，教培版习惯公社账户或者停止使用教培版-习惯公社，教培版习惯公社服务而失效。<br/>
                    第八条 其他免责声明<br/>
                    8.1平台方或用户提供的全部信息仅依照该等信息提供时的现状提供并仅供用户参考，平台方不对前述信息的真实性、准确性、完整性、适用性等做任何承诺和保证。用户应对平台方或其他用户提供的信息自行判断，并承担因使用前述信息而引起的任何、全部风险，包括因其对信息的真实性、准确性、完整性或适用性的任何依赖或信任而导致的风险。平台方无需对因用户使用信息的任何行为导致的任何损失承担责任。<br/>
                    8.2对于因不可抗力或平台方不能预料、或不能控制的原因（包括但不限于计算机病毒或黑客攻击、系统不稳定、用户不当使用账户、以及其他任何技术、互联网络、通信线路原因等）造成的包括但不限于用户计算机信息和数据的安全，用户个人信息的安全等给用户或任何第三方造成的任何损失等，平台方不承担任何责任。<br/>
                    8.3由于用户以违法、违规或违反本协议约定等任何方式使用教培版-习惯公社，教培版习惯公社的行为，包括但不限于提供违法、不真实、不正当信息，侵犯第三方任何合法权益等，给平台方或其他第三人造成的任何损失，用户同意承担由此造成的全部损害赔偿责任。<br/>
                    8.4用户完全了解并同意，在使用教培版-习惯公社，教培版习惯公社服务的过程中，可能存在来自任何其他用户的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为，也可能存在对他人权利（包括知识产权）造成侵犯的匿名、冒名、或伪造的各种信息或行为，用户须自行判断相关内容、信息、行为的安全性等风险，并自行独立承担上述风险给平台方、其他用户或第三方造成的任何责任。<br/>
                    8.5平台方及其合作方对教培版-习惯公社，教培版习惯公社服务的任何及全部内容不作任何形式的承诺或保证，不论是明确的或暗示的。前述承诺或保证包括但不限于：教培版-习惯公社，教培版习惯公社服务真实性、时效性、对特定用途的适用性、任何形式的所有权保证，非侵权保证等。平台方对因前述未做承诺或保证的内容导致的任何直接、间接、偶然、特殊及后续的损害，不承担任何责任。<br/>
                    第九条 适用法律和争议解决<br/>
                    9.1本协议的成立、生效、履行、解释及因本协议产生的任何争议，均适用中华人民共和国法律（不包括港澳台地区法律）。<br/>
                    9.2用户和平台方之间与本协议有关的任何争议，首先应友好协商解决，在争议发生之日起三十日内仍不能通过协商达成一致的，用户在此同意将前述争议提交平台方所在地的人民法院进行诉讼。<br/>
                    9.3如本协议中的任何条款因任何原因完全或部分无效或不具有执行力，均不影响本协议其他条款的效力。<br/>
                    9.4本协议及本协议任何条款内容的最终解释权及修改权归平台方所有。<br/>
                    第十条 修订 您理解并同意，教培版-习惯公社，教培版习惯公社学校版有权适时根据产品及服务的发展情况，对本隐私政策进行修订。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。 届时教培版-习惯公社，教培版习惯公社学校版会通过推送通知的方式向您发布修订的政策内容，届时您可仍通过勾选方式确认是否愿意接受修订后的政策并继续使用教培版-习惯公社，教培版习惯公社学校版平台服务。<br/>
                    上海形者网络科技发展有限公司<br/>
                </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(JpbProtocol)
