import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/selectChild.css'
import integral_back_img from '../../image/integral_back_img.png'
import user_default_icon from '../../image/user_default_icon.png'
import slect_boy_img from '../../image/slect_boy_img.png'
import slect_boy_img_default from '../../image/slect_boy_img_default.png'
import gray_down from '../../image/gray_down.png'
import add_gray_img from '../../image/add_gray_img.png'
import {DatePicker, Toast, Picker} from "antd-mobile";
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";
import { Modal, Upload } from "antd"

class AddChild extends Component {

    constructor(props) {
        super(props);
        this.state = {
            frontImgUrl: null,
            qiToken: "",
            nameValue: "",
            gender: 1,
            childDate: null,
            grade: 0,
            gradeList: [],
            gradeAllList: [],
            schoolType: 1,
            // id: null,
            // name: '',
        };
    }

    componentDidMount() {
        // this.handleGetChild();
        // if(this.props.history.location.state){
        //     this.setState({
        //         name: this.props.history.location.state.name,
        //         id: this.props.history.location.state.id,
        //     })
        // }
        this.handleGetQiNiu()
        this.handleGetGread()
    }

    // 获取年纪
    handleGetGread = (ref) => {
        const { schoolType }=this.state
        util.getYangAxios(
            api.grade,
            {
                type: schoolType,
            },
            (res) => {
                console.log(res);
                let list =res.map((item,index)=>{
                    return {value: index,label: item.name}
                })
                this.setState({
                    gradeList: list,
                    gradeAllList: res,
                });
            },
            (err) => {
                console.log(err);
            },
            true,
        );
    };

    /**
     * 习惯公社上传图片随机名 xggs_********
     */

    randomImageNum = () => {
        let r = Math.floor(Math.random() * 1000000000)
        let f = Math.floor(Math.random() * 1000000000)
        let d = Date.parse(new Date())
        // console.log(`xg_GS${d}${r}${f}`)
        return `xg_GS${d}${r}${f}`
    }


    // token
    handleGetQiNiu = () => {
        util.postYangAxios(
            api.qnToken,
            {},
            async (res) => {
                this.setState({
                    qiToken: res,
                })
            },
            (err) => {
                console.log(err)
            }
        )
    }

    handleGetChild=()=>{
        util.getYangAxios(
            api.child,
            {
            },
            (res) => {
                console.log(res);

            },
            (err) => {
                console.log(err);
            },
        );
    }

    // 跳抓
    handleSkip=()=>{
        this.props.history.push('download')
    }

    handleBack=()=>{
        this.props.history.goBack();
    }

    onNameChange = (event) => {
        this.setState({
            nameValue: event.target.value,
        })
    }

    handleSelectGender=(id)=>{
        this.setState({
            gender: id
        })
    }

    // js格林时间转化本地时间格式
    setDate= (newDate) => {
        const date = new Date(newDate)
        const Str = `${date.getFullYear()}-${
            date.getMonth() + 1}-${
            date.getDate()}`
        return Str
    };

    handleJoin=()=>{
        const { qiToken, frontImgUrl,nameValue,gender, childDate, grade , gradeList, schoolType,gradeAllList}=this.state;
        if(!frontImgUrl){
            Toast.info("请上传孩子头像", 1.5)
            return null
        }
        if(!nameValue){
            Toast.info("请输入孩子姓名", 1.5)
            return null
        }  if(!childDate){
            Toast.info("请选择孩子的出生日期", 1.5)
            return null
        }
        let obj={
            cover: frontImgUrl,
            name: nameValue,
            sex: gender,
            type: schoolType,
            grade_id: gradeAllList[grade].id,
            birthday: this.setDate(childDate),
        }
        util.postYangAxios(
            api.child,
            obj,
            (res) => {
                console.log(res);
                this.handleSubmit(res.id);
                // this.props.history.push('memberDetail')
            },
            (err) => {
                console.log(err);
            },
            false,
        );
    }


    // 提交
    handleSubmit=(childId)=>{
        const { id }=this.state
        if(childId==0||!childId){
            Toast.info('您的孩子无效');
            return null;
        }
        util.postYangAxios(
            api.order,
            {
                child_id: childId,
            },
            (res) => {
                console.log(res);
                // res.id
                this.handlePayOrder(res.id)
            },
            (err) => {
                console.log(err);
            },
            true,
        );
    }

    // 下单
    handlePayOrder=(id)=>{
        util.postYangAxios(
            `${api.pay}/${id}/1`,
            {
                bound_url: api.links+`payResult`
            },
            (res) => {
                console.log(res);
                // res.id
                window.location.href=res.url;
            },
            (err) => {
                console.log(err);
            },
            false,
        );
    }


    render() {
        const { qiToken, frontImgUrl,nameValue,gender, childDate, grade , gradeList, schoolType, name}=this.state;
        const schoolTypeList=[{value: 1, label: '幼儿园'},{value: 2, label: '小学'}];
        return (
            <div className={"select_child_view"}>
                <div className={'row_between back_view'}>
                    <img src={integral_back_img} alt="" className={'integral_back_img'} onClick={this.handleBack}/>
                    <div className={'integral_back_text'}>创建孩子</div>
                    <img src={integral_back_img} alt="" className={'integral_back_img'} style={{opacity: 0}}/>
                </div>
                <div className={'ph30'}>
                    <div className={'row_between mb30'}>
                        <div className={'add_child_title'}>孩子头像</div>
                        <Upload
                            action="http://upload.qiniu.com"
                            // listType="picture-card"
                            // fileList={coverList}
                            // onPreview={this.handlePreview}
                            accept="image/png, image/jpeg"
                            onChange={({ fileList }) => {
                                console.log(fileList)
                                Toast.loading("上传中", 0)
                                if (fileList[0].response) {
                                    this.setState(
                                        {
                                            frontImgUrl: `${fileList[0].response.key}`,
                                        },
                                        () => {
                                            Toast.hide()
                                            Toast.info("上传成功！", 1.5)
                                        }
                                    )
                                }
                            }}
                            maxCount={1}
                            showUploadList={false}
                            data={{ token: qiToken, key: this.randomImageNum() }}>
                            <img src={frontImgUrl?`${qiNiuIP}${frontImgUrl}`:user_default_icon} alt="" className={`user_default_icon`}/>
                        </Upload>
                    </div>
                </div>
                <div className={'ph30'}>
                    <div className={'row_between mb30'}>
                        <div className={'add_child_title'}>孩子姓名</div>
                        <div className={'child_input_view row_center'}>
                            <input
                                type="text"
                                value={nameValue}
                                onChange={this.onNameChange}
                                className={"authentication_input_text"}
                                placeholder={"姓名"}
                                ref={(c) => {
                                    this.nameInputs = c
                                }}
                            />
                        </div>
                    </div>
                    <div className={'row_between mb30'}>
                        <div className={'add_child_title'}>孩子性别</div>
                        <div className={'row_align'}>
                            <img src={gender==1?slect_boy_img:slect_boy_img_default} alt="" className={'slect_boy_img'} onClick={()=>this.handleSelectGender(1)}/>
                            <div className={'slect_boy_img_text'}>男孩</div>
                        </div>
                        <div className={'row_align'}>
                            <img src={gender==2?slect_boy_img:slect_boy_img_default} alt="" className={'slect_boy_img'} onClick={()=>this.handleSelectGender(2)}/>
                            <div className={'slect_boy_img_text'}>女孩</div>
                        </div>
                    </div>
                    <div className={'row_between mb30'}>
                        <div className={'add_child_title'}>孩子出生日期</div>
                        <DatePicker
                            mode="date"
                            title="选择孩子生日"
                            extra="Optional"
                            value={childDate}
                            maxDate={new Date()}
                            onChange={date => {
                                console.log(date)
                                this.setState({ childDate: date })
                            }}
                        >
                            <div className={'child_input_view row_between'}>
                                <div className={'child_input_view_text'} style={{color: childDate?'#171819':'#9CA4B1'}}>
                                    {childDate?this.setDate(childDate):'选择孩子出生日期'}
                                </div>
                                <img src={gray_down} alt="" className={'gray_down'}/>
                            </div>
                        </DatePicker>
                    </div>
                    <div className={'row_between mb30'}>
                        <div className={'add_child_title'}>孩子学段</div>
                        <Picker data={schoolTypeList} cols={1} onChange={(value)=>{this.setState({schoolType: value[0]},()=>{
                            this.handleGetGread();
                        })}} >
                            <div className={'child_input_view row_between'}>
                                <div className={'child_input_view_text'} style={{color: '#171819'}}>
                                    {schoolType==1?'幼儿园':'小学'}
                                </div>
                                <img src={gray_down} alt="" className={'gray_down'}/>
                            </div>
                        </Picker>
                    </div>
                    <div className={'row_between mb30'}>
                        <div className={'add_child_title'}>孩子年级</div>
                        <Picker data={gradeList} cols={1} onChange={(value)=>{this.setState({grade: value[0]})}} >
                            <div className={'child_input_view row_between'}>
                                <div className={'child_input_view_text'} style={{color: (grade||grade==0)?'#171819':'#9CA4B1'}}>
                                    {(grade||grade==0)&&gradeList.length>0?gradeList[grade].label:'选择孩子年级'}
                                </div>
                                <img src={gray_down} alt="" className={'gray_down'}/>
                            </div>
                        </Picker>
                    </div>
                </div>


                <div className={'invite_join_view row_between add_child_btn'}>
                    <div className={'invite_join_btn row_center'} onClick={this.handleBack} style={{width: '45%',background: '#F6F7F8',color: '#171819'}}>
                        取消
                    </div>
                    <div className={'invite_join_btn row_center'} style={{width: '45%'}} onClick={this.handleJoin}>
                        确认,并加入
                    </div>
                </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(AddChild)
