import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import {util} from "../../common/util";
import "./css/appraisal.css";
import {api} from "../../common/Config";
import {InputItem, Toast,} from "antd-mobile";

import JsTypeFunction from "../../common/JsTypeFunction";
class XggsAppraisal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questionnaireList: [
            ],
            type: 0,
            stepType: 1,
            selectList: [],
            twoIndex: 0,
        };
    }

    componentDidMount() {
        let ua = navigator.userAgent
        if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            console.log('苹果')
            window.addEventListener("message", this.handleMessage);
        } else if (ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1) {
            console.log('安卓')
            document.addEventListener("message", this.handleMessage);
        }else{
            Toast.info('请在手机中打开')
        }
    }

    componentWillUnmount() {
        let ua = navigator.userAgent
        if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            window.removeEventListener("message",this.handleMessage)
        } else if (ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1) {
            document.removeEventListener("message",this.handleMessage)
        }
    }

    handleMessage=async (event)=>{
        window.ReactNativeWebView.postMessage(event.data)
        await window.localStorage.setItem("login_token",event.data);
        window.ReactNativeWebView.postMessage('设置成功')
        setTimeout(()=>{ this.handleGetCp();},300)
    }

    handleGetCp=()=>{
        util.getYangAxios(
            api.xggsEvaluation,
            {
            },
            async (res) => {
                let num=0;
                res.map((item,index)=>{
                    if(item.type==2&&index+1<res.length&&res[index+1].type==1){
                        num=index
                    }
                })
                this.setState({
                    questionnaireList: res,
                    twoIndex: num,
                })
            },
            (err) => {
                console.log(err);
            },
        );
        util.getYangAxios(
            api.xggsEvaluation,
            {
                is_self: 1,
            },
            async (res) => {
                if(res==2){
                    this.props.history.push('xggsAppraisalReport')
                }
            },
            (err) => {
                console.log(err);
            },
        );
    }

    // 选择题
    handleSelectItem = (index) => {
        const {type} = this.state;
        const {selectList} = this.state;
        selectList[type] = index;
        console.log(selectList);
        this.setState({
            selectList,
        });
    };

    // 下标转英文
    handleEnglishNum = (index) => {
        switch (index) {
            case 0:
                return 'A';
            case 1:
                return 'B';
            case 2:
                return 'C';
            case 3:
                return 'D';
            case 4:
                return 'E';
            case 5:
                return 'F';
            case 6:
                return 'G';
            case 7:
                return 'H';
            case 8:
                return 'I';
            case 9:
                return 'J';
            case 10:
                return 'K';
            case 11:
                return 'L';
            case 12:
                return 'M';
        }
    };

    // 下一题
    handleDown = () => {
        const {questionnaireList, type, selectList, twoIndex} = this.state;
        if (selectList.hasOwnProperty(type)) {
            if(twoIndex==type){
                this.setState({
                    stepType: 2,
                })
            }
            this.setState({
                type: type + 1,
            });
        } else {
            Toast.info('请完成该题后再进行下一题', 1.5);
        }
    };

    // 上一题
    handleUp = () => {
        const {questionnaireList, type, selectList} = this.state;
        if (type != 0) {
            this.setState({
                type: type - 1,
            });
        }
    };

    // 提交
    handleSubmit = () => {
        const {questionnaireList, type, selectList} = this.state;
        if (selectList.hasOwnProperty(type)) {
            let list=questionnaireList.map((item,index)=>{
                return {...item,answer: selectList[index]}
            })
            util.postYangAxios(
                api.xggsEvaluation,
                {
                    evaluation: list,
                },
                async (res) => {
                    this.props.history.push('xggsAppraisalReport')
                },
                (err) => {
                    console.log(err);
                },
            );
        } else {
            Toast.info('请完成该题后再提交', 1.5);
        }
    };


    // 获取当前题数的宽度
    handleGetWidth = () => {
        const {questionnaireList, type, selectList} = this.state;
        const num = ((type + 1) / questionnaireList.length) * 100;
        return `${parseInt(num)}%`;
    }; // 获取当前题数的宽度

    // 获取文字位置
    handleGetTextWidth = () => {
        const {questionnaireList, type, selectList} = this.state;
        if (type + 11 > questionnaireList.length) {
            return `${parseInt(((type + 1) / questionnaireList.length) * 100 - 10)}%`;
        }
        const num = ((type + 1) / questionnaireList.length) * 100 + 4;
        return `${parseInt(num)}%`;
    };

    render() {
        const {questionnaireList, type, selectList, stepType} = this.state;
        if(questionnaireList&&questionnaireList.length==0){
            return null;
        }
        if(stepType==1){
            return (
                <div className={'appraisal_div column_center'} style={{position: 'relative'}}>
                    <img src={JsTypeFunction.handleImgAddr('cp_bg')} alt="" className={'cp_bg'} />
                    <div className={'appraisal_long_text column_center'}>
                        <div>
                            尊敬的家长：<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;您好！<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;在本次测评正式开始之前，需要对家长和孩子的信息做基本的收集。便于在后续的报告中去分析家庭环境和成长环境对孩子12商数的影响情况，以及孩子在面临选择时所做各种决定的原因，以便后续为孩子提供针对性的解决方案。<br/>

                            &nbsp;&nbsp;&nbsp;&nbsp;本部分共14道题，所有信息仅需填写一次，填写完毕后系统将会自动纳入到您的个人资料库。<br/>

                            &nbsp;&nbsp;&nbsp;&nbsp;“华师大家校共育课题组”将对您和您孩子的所有隐私信息完全保密，仅供做数据统计及学术研究之用！<br/>
                        </div>
                        <div className={'row_center appraisal_long_btn'} onClick={()=>this.setState({
                            stepType: 3,
                        })}>
                            下一步
                        </div>

                    </div>
                </div>
            )
        }
        if(stepType==2){
            return (
                <div className={'appraisal_div'} style={{background: '#fff'}}>
                    <img src={JsTypeFunction.handleImgAddr('qs_cp_bg')} alt="" className={'qs_cp_bg'} />
                    <div className={'appraisal_qs_div'}>
                        <div>
                            尊敬的家长：<br/>
                            您好！欢迎您参加本次测评，本测评由华东师范大学“家校共育与少儿习惯发展研究”课题组研发实施。该测评是与少儿情商密切相关的初期研究，目的是了解少儿群体在情商上的发展情况。<br/>
                            本次测评为不记名测评，共43道题目，大概需要10分钟时间，请您结合孩子的真实情况来回答所有问题。所有题目的答案均无对错之分，对那些不太确定的问题请尽量尝试回答，不要跳过任何问题，全卷答完之前，请不要退出。<br/>
                            测评中收集的所有的数据仅供华师大学校共育课题组统计分析和学术研究之用，绝对保障您的隐私。<br/>
                            另外需要说明的是，测评中的“他”代指孩子，无男女之分。<br/>
                            非常感谢您对学术研究的支持！
                        </div>
                        <div className={'row_center appraisal_qs_btn'} onClick={()=>this.setState({
                            stepType: 3,
                        })}>
                            开始测评
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className={'appraisal_div'}>
                <div className={'row_center appraisal_title_div'}>
                    {questionnaireList[type].name}
                </div>
                {/*<div className={'row_center appraisal_content_div'}>*/}
                {/*    <div className={'appraisal_content_box row_center'}>*/}
                {/*        <img src={'https://cdn.ellll.com/capphabit/download_bg.png'} alt="" className={'appraisal_content_img'} />*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={'appraisal_select_div'}>
                    {
                        questionnaireList[type].option.map((item,index)=>{
                            return (
                                <div className={'appraisal_select_box row_center'} key={index} onClick={() => this.handleSelectItem(index + 1)} style={
                                    {
                                        backgroundColor:
                                            selectList[type] - 1 == index ? '#FDC412' : '#fff',
                                        color:
                                            selectList[type] - 1 == index ? '#fff' : '#5E6368',
                                    }
                                }>
                                    {item}
                                    <div className={'appraisal_select_num'} style={
                                        {
                                            backgroundColor:
                                                selectList[type] - 1 == index ? '#fff' : '#FDC412',
                                            color:
                                                selectList[type] - 1 == index ? '#FDC412' : '#fff',
                                        }
                                    }>{this.handleEnglishNum(index)}</div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={'row_between appraisal_submit_btn'}>
                    {
                        type == 0 ?<div className={'appraisal_submit_btn_box row_center'}>
                            返回上一题
                        </div>:<div className={'appraisal_submit_yellow_btn_box row_center'} onClick={this.handleUp}>
                            返回上一题
                        </div>
                    }
                    {
                        questionnaireList.length - 1 == type? <div className={'appraisal_submit_yellow_btn_box row_center'} onClick={this.handleSubmit}>
                            完成
                        </div>:<div className={'appraisal_submit_yellow_btn_box row_center'} onClick={this.handleDown}>
                            下一题
                        </div>
                    }

                </div>
                <div className={'appraisal_schedule_div'}>
                    <div className={'appraisal_schedule_box'}>
                        <div className={'appraisal_schedule_yellow_box'} style={{width: this.handleGetWidth()}}/>
                        <div className={'appraisal_schedule_yellow_text'} style={{left: this.handleGetTextWidth(),color:
                                type + 11 >  questionnaireList.length ? '#fff' : '#5E6368',}}>{type + 1}/{questionnaireList.length}</div>
                        <img src={'https://cdn.ellll.com/capphabit/rocket_img.png'} alt="" className={'rocket_img'} />

                    </div>
                </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(XggsAppraisal)
