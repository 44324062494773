import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/invite.css'
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";
import {InputItem, Toast, Picker, Modal} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";
import jpb_share1 from '../../image/jpb_share1.png'
import jpb_share2 from '../../image/jpb_share2.png'
import user_default_icon from '../../image/user_default_icon.png'

class InviteJpbPatriarch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            titleList: [
                { value: '妈妈', label:'妈妈' },
                { value: '爸爸', label:'爸爸' },
                { value: '爷爷', label:'爷爷' },
                { value: '奶奶', label:'奶奶' },
                { value: '外公', label:'外公' },
                { value: '外婆', label:'外婆' },
            ],
            title: '',
            titleValue: '',
            type: 1,
            selectModal: false,
            childObj: null,
            childList: [],
        };
    }

    componentDidMount() {
        let obj=JsTypeFunction.getUrlParams();
        console.log(obj);
        if(obj.id){
            this.setState({
                id: obj.id,
                str: obj.str,
                type: obj.type,
            },()=>{
                if(obj.type==2){
                    this.onRefresh()
                }
            })
        }else{
            Toast.info('您查看的已失效')
        }
    }

    // 获取班级成员
    onRefresh=()=>{
        const { id }=this.state;
        util.getYangAxios(
            api.organChildPerson,
            {
                id: id,
            },
            (res) => {
                console.log(res);
                this.setState({
                    childList: res
                })
            },
            (err) => {
                console.log(err);
            },
        );
    }

    // 加入
    handleJoin=()=>{
        const {str, titleList, titleValue,id }=this.state
        const isLogin = localStorage.getItem("login_token");
        console.log(isLogin)
        if(!titleValue){
            Toast.info('请选择身份',1.5)
            return null;
        }
        if(isLogin){
            this.handleInvite()
        }else{
            this.props.history.push({
                pathname: '/login',
                state: {
                    id,
                    type: 5,
                    data:{
                        id: id,
                        name: titleValue,
                        title: titleValue,
                    }
                }
            })
        }
    }

    handleInvite=()=>{
        const {str, titleList, title,id, titleValue }=this.state
        util.postYangAxios(
            api.organJoin,
            {
                id: id,
                name: titleValue,
                title: titleValue,
            },
            (res) => {
                console.log(res);
                this.props.history.push('inviteJpbSuccess')
            },
            (err) => {
                console.log(err);
            },
        );
    }

    onClose=()=>{
        this.setState({
            selectModal: false
        })
    }

    handleSelect=()=>{
        const {str, titleList, childObj,id, titleValue }=this.state
        if(!titleValue){
            Toast.info('请选择身份',1.5)
            return null;
        }
        this.setState({
            selectModal: false,
        })
        util.postYangAxios(
            api.organJoin,
            {
                id: childObj.id,
                name: titleValue,
                title: titleValue,
            },
            (res) => {
                console.log(res);
                this.props.history.push('inviteJpbSuccess')
            },
            (err) => {
                console.log(err);
            },
        );
    }

    // 选择孩子
    handleClickChild=(obj)=>{
        const isLogin = localStorage.getItem("login_token");
        if(isLogin){
            this.setState({
                selectModal: true,
                childObj: obj,
            })
        }else{
            this.props.history.push({
                pathname: '/login',
                state: {
                    type: 4,
                    url: window.location.href,
                }
            })
        }
    }
    render() {
        const {userData} =this.props.UserDataReducer;
        const {str, titleList, title, type, selectModal, childList, childObj }=this.state
        return (
            <div className={"article_view jpb_share_view"} >
                <img src="http://appshare.xzwl100.com/static/img/headBg.91c6bbfa.png" alt="" className={'jpb_share_img'}/>
                <div className={'ph30 column_align'}>
                    <div className={'jpb_share_title'}>{str}</div>
                    {
                        type==1&&<>
                            <Picker
                                data={titleList}
                                title="选择身份"
                                value={title}
                                cols={1}
                                onOk={v => this.setState({ title: v,titleValue: v[0] })}
                            >
                                <div className={'row_center jpb_share_id_view'}>
                                    您的身份：{title?title: '请选择'} <img src={jpb_share1} alt="" className={'jpb_share1'}/>
                                </div>
                            </Picker>
                            <div className={'jpb_join_btn row_center'} onClick={this.handleJoin}>加入</div>
                        </>
                    }
                    {
                        type==2&&<div className={'row_warp'}>
                            {
                                childList.length>0&&childList.map((item,index)=>{
                                    return (
                                        <div key={index} className={'column_center jpb_child_box'} onClick={this.handleClickChild.bind(this,item)}>
                                            <img src={item.logo?`${qiNiuIP}${item.logo}?imageView2/5/q/75|imageslim`:user_default_icon} alt="" className={'jpb_child_img'}/>
                                            <div>{item.name}</div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    }
                </div>
                <Modal
                    visible={selectModal}
                    transparent
                    maskClosable={false}
                    onClose={this.onClose}
                    title="选择关系"
                    footer={[
                        { text: '取消', onPress: () => { this.onClose();}},
                        { text: '确认', onPress: () => { this.handleSelect() }},
                    ]}
                >
                    <div className={'column_center'}>
                        {
                            childObj&&<div className={'column_center jpb_child_box'}>
                                <img src={childObj.logo?`${qiNiuIP}${childObj.logo}?imageView2/5/q/75|imageslim`:user_default_icon} alt="" className={'jpb_child_img'}/>
                                <div style={{color: '#000'}}>{childObj.name}</div>
                            </div>
                        }
                        <Picker
                            data={titleList}
                            title="选择身份"
                            value={title}
                            cols={1}
                            onOk={v => this.setState({ title: v,titleValue: v[0] })}
                        >
                            <div className={'row_center jpb_share_id_view_two'}>
                                您的身份：{title?title: '请选择'} <img src={jpb_share2} alt="" className={'jpb_share1'}/>
                            </div>
                        </Picker>
                    </div>

                </Modal>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(InviteJpbPatriarch)
