import React, { Component } from 'react'
import { withRouter, } from 'react-router-dom'
import InfiniteScroll from "react-infinite-scroll-component";
import SpellStoreGoods from "./SpellStoreGoods";
import spell_recommended_vip_img from "../../image/spell/spell_recommended_vip_img.png";
import {util} from "../../common/util";
import {api, qiNiuIP} from "../../common/Config";
import EmptyStatePage from "../Empty/EmptyStatePage";

class SpellRecommended extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shopList: [],
            page: 1,
        };
    }


    componentDidMount() {
        this.onRefresh();
    }

    onRefresh=(refs)=>{
        const { shopList,page }=this.state
        util.getYangAxios(
            api.spellRecommend,
            {
                type: this.props.type,
                page,
            },
            (res) => {
                console.log(res);
                if(res.data.length==0){
                   return null;
                }
                this.setState({
                    shopList: refs?res.data:shopList.concat(res.data),
                    page: page+1,
                })

            },
            (err) => {
                console.log(err);
            },
            true,
        );
    }


    render() {
        const { shopList }=this.state
        return (
            <div>
                <div className={'row_center mb30'}>
                    <div className={'spell_recommended_line'}/>
                    <div className={'spell_recommended_circle'}/>
                    <img src={spell_recommended_vip_img} alt="" className={'spell_recommended_vip_img'}/>
                    <div className={'spell_recommended_title_text'}>
                        精选推荐
                    </div>
                    <div className={'spell_recommended_circle'}/>
                    <div className={'spell_recommended_line'}/>
                </div>
                <InfiniteScroll
                    dataLength={shopList.length}
                    next={this.onRefresh}
                    hasMore={true}
                >
                    {
                        shopList.length>0?<div className={'row_between_warp ph30 pb100'}>
                            {shopList.map((item, index) => (
                                <SpellStoreGoods key={index} goodObj={item}/>
                            ))}
                        </div>:<EmptyStatePage/>
                    }

                </InfiniteScroll>

            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default withRouter(SpellRecommended);
