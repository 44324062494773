import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/spellHome.css'
import {util} from "../../common/util";
import {api, qiNiuIP, shareIP} from "../../common/Config";
import {InputItem, Toast, ListView} from "antd-mobile";
import JsTypeFunction from "../../common/JsTypeFunction";
import {Image, Carousel, Rate} from 'antd';
import spell_goback_icon from "../../image/spell/spell_goback_icon.png";
import xggs_logo from "../../image/spell/xggs_logo.png";
import user_default_icon from "../../image/user_default_icon.png";
import SpellStoreGoods from "../../component/spell/SpellStoreGoods";
import EmptyStatePage from "../../component/Empty/EmptyStatePage";
import SpellGroupModal from "../../component/spell/SpellGroupModal";
import SpellMakeOrderModal from "../../component/spell/SpellMakeOrderModal";
import DateCountdownText from "../../component/spell/DateCountdownText";

class purchaseGoodsDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSpellGroupVisible: false,
            isSpellMakeOrderVisible: false,
            payType: 1, //1是单独购买 2是拼团
            goodObj: null,
            collageObj: null,
        };
    }

    componentDidMount() {
        let obj=JsTypeFunction.getUrlParams();
        if(obj.id){
            this.onRefresh(obj.id)
            if(obj.collageId){
                this.setState({
                    isSpellMakeOrderVisible: true,
                    payType: 2,
                    collageObj:{id:obj.collageId}
                })
            }
        }else{
            if(this.props.history.location.state&&this.props.history.location.state.id){
                this.onRefresh(this.props.history.location.state.id)
            }else{
                Toast.info('您查看的商品已失效')
            }
        }
    }

    // 刷新
    onRefresh = (id) => {
        util.getYangAxios(
            api.classGood+`/${id}`,
            {
                // id: goodId,
            },
            (res) => {
                console.log(res);
                this.setState({
                    goodObj: res,
                });
            },
            (err) => {
                console.log(err);
            },
            true,
        );
    };

    // 渲染拼单列表
    spellListRender=()=>{
        const { goodObj }=this.state;
        let arr= [];
        if(goodObj&&goodObj.bill_order.length==0){
            return null;
        }
        for(var i=0;i<goodObj.bill_order.length;i+=2){
            arr.push(goodObj.bill_order.slice(i,i+2));
        }
        return (
            <div className={'spell_card'}>
                <div className={'row_between spell_list_title_view'}>
                    <div className={'spell_list_title'}>{goodObj.bill_order.length}人发起拼单，可直接参与</div>
                    <div className={'spell_list_look_btn row_center'} onClick={()=>this.setState({ isSpellGroupVisible: true})}>立即查看</div>
                </div>
                <Carousel autoplay dots={false} dotPosition={'left'}>
                    {
                        arr.map((item,index)=>{
                            return (
                                <div key={index}>
                                    {this.handleUserSpell(item[0])}
                                    {this.handleUserSpell(item[1])}
                                </div>
                            )
                        })
                    }
                </Carousel>
            </div>
        )
    }

    // 渲染用户拼单
    handleUserSpell=(obj)=>{
        if(!obj){
            return null
        }
        return (
            <div className={'row_between spell_user_view'}>
                <div className={'row_align'}>
                    {
                        obj.oder_bill.length>0&&obj.oder_bill.map((item,index)=>{
                            if(index!=0){
                                return (
                                    <img src={item.user_icon?`${qiNiuIP}${item.user_icon}`:user_default_icon} alt="" className={'spell_user_icon'} key={index} style={{marginLeft: '-0.8rem'}}/>
                                )
                            }
                            return (
                                <img src={item.user_icon?`${qiNiuIP}${item.user_icon}`:user_default_icon} alt="" className={'spell_user_icon'} key={index}/>
                            )
                        })
                    }

                </div>
                <div className={'row_align'}>
                    <div>
                        <div className={'spell_user_number_text'}>
                            {obj.total_num}人拼，还差<span style={{color: '#FE6B02'}}>{obj.total_num-obj.now_num}</span>人拼成
                        </div>
                        <div className={'spell_user_date_text'}>
                            剩余<DateCountdownText date={obj.expire_at}/>
                        </div>
                    </div>
                    <div className={'spell_user_btn_view row_center'} onClick={()=>this.handlePingTuan(obj)}>
                        去拼单
                    </div>

                </div>

            </div>
        )
    }

    // 拼团
    handlePingTuan=(obj)=>{
        const { isSpellGroupVisible }=this.state;
        if(isSpellGroupVisible){
            this.setState({
                isSpellGroupVisible: false,
            },()=>{
                setTimeout(()=>{
                    this.setState({isSpellMakeOrderVisible: true, payType: 2,collageObj: obj})
                },600)
            })
            return null;
        }
        this.setState({isSpellMakeOrderVisible: true, payType: 2,collageObj: obj})
    }


    // 渲染评价
    handleCommentsRender=()=>{
        const {  goodObj }=this.state;
        return (
            <div className={'spell_card'}>
                <div className={'row_between'}>
                    <div className={'spell_comments_title'}>商品评价（{goodObj.evaluate_count}）</div>
                    {/*<div className={'row_align'}>*/}
                    {/*    <div className={'spell_comments_more'}>查看全部</div>*/}
                    {/*    <img src={habit_right_gray_icon} alt="" className={'habit_right_gray_icon'}/>*/}
                    {/*</div>*/}
                </div>
                {
                    goodObj.evaluate.length>0?goodObj.evaluate.map((item,index)=>{
                        return (
                            <div key={index} className={'spell_comments_user_box'}>
                                <div className={'row_align'}>
                                    <img src={item.user_icon?`${qiNiuIP}${item.user_icon}`:user_default_icon} alt="" className={'spell_comments_user_icon'}/>
                                    <div className={'spell_comments_user_name'}>{item.user_name}</div>
                                </div>
                                <div className={'spell_comments_content'}>{item.content}</div>
                            </div>
                        )
                    }):<EmptyStatePage/>
                }
            </div>
        )
    }

    // 商铺渲染
    handleStoreRender=()=>{
        const {  goodObj }=this.state;
        return (
            <div className={'spell_card'}>
                <div className={'row_between'}>
                    <div className={'row_align'}>
                        <img  src={goodObj.shop.logo?`${qiNiuIP}${goodObj.shop.logo}`:xggs_logo} alt="" className={'spell_store_img'}/>
                        <div className={'spell_store_name'}>{goodObj.shop.name}</div>
                    </div>
                    <div className={'spell_list_look_btn row_center'} onClick={()=>this.props.history.push({
                        pathname: '/spellStoreHome',
                        state: {
                            id: goodObj.shop.id,
                        }
                    })}>进店逛逛</div>
                </div>
                <div className={'row_align'} style={{marginTop: '0.6rem',marginBottom: '0.6rem'}}>
                    <div className={'spell_store_score_title'}>综合体验</div>
                    <Rate disabled defaultValue={goodObj.shop.star} className={'spell_store_score_num'}/>
                </div>
                <div className={'row_between_warp'}>
                    {
                        goodObj.shop.class_good.map((item,index)=>{
                            return (
                                <SpellStoreGoods key={index} goodObj={item}/>
                            )
                        })
                    }

                </div>

            </div>
        )

    }

    // 渲染班级详情
    handleClassContentRender=()=>{
        const {  goodObj }=this.state;
        return (
            <div className={'spell_card'}>
                <div className={'spell_comments_title'} style={{marginBottom: '0.6rem'}}>商品详情</div>
                {
                    goodObj.content?<div dangerouslySetInnerHTML={{__html:goodObj.content}}></div>:<EmptyStatePage/>
                }
            </div>
        )
    }

    // 渲染底部按钮
    handleBottomBtnRender=()=>{
        const {  goodObj }=this.state;
        if(goodObj.repertory==0){
            return (
                <div className={'spell_action_btn_div row_end'}>
                    <div className={'spell_action_btn column_center'} style={{background: '#CACBCF'}}>
                        <div className={'spell_action_btn_price'}>¥{goodObj.price_sell}</div>
                        <div className={'spell_action_btn_price'}>单独购买</div>
                    </div>
                    <div className={'spell_action_btn column_center'} style={{background: '#CACBCF'}}>
                        <div className={'spell_action_btn_price'}>¥{goodObj.price_spell}</div>
                        <div className={'spell_action_btn_price'}>发起拼单</div>
                    </div>
                </div>
            )
        }
        return (
            <div className={'spell_action_btn_div row_end'}>
                <div className={'spell_action_btn column_center'} onClick={()=>this.handlePayAction(1)}>
                    <div className={'spell_action_btn_price'}>¥{goodObj.price_sell}</div>
                    <div className={'spell_action_btn_price'}>单独购买</div>
                </div>
                <div className={'spell_action_btn column_center'} style={{background: '#FE6B02'}} onClick={()=>this.handlePayAction(2)}>
                    <div className={'spell_action_btn_price'}>¥{goodObj.price_spell}</div>
                    <div className={'spell_action_btn_price'}>发起拼单</div>
                </div>
            </div>
        )

    }

    // 点及购买按钮
    handlePayAction=(type)=>{
        this.setState({
            isSpellMakeOrderVisible: true,
            payType: type,
            collageObj: null,
        })
    }

    // 关闭拼团模态框
    handleCloseSpellGroup=()=>{
        this.setState({
            isSpellGroupVisible: false
        })
    }

    // 关闭确认订单模态框
    handleCloseSpellMakeOrder=()=>{
        this.setState({
            isSpellMakeOrderVisible: false
        })
    }

    // 支付
    handlePay=(specObj,num)=>{
        this.handleCloseSpellMakeOrder();
        const { payType, goodObj, specList, addrObj, collageObj }=this.state;
        // `${shareIP}spellInvitation?id=${id}`
        util.postYangAxios(
            `${api.goodOrder}/${specObj.id}${collageObj?'/'+collageObj.id:''}`,
            {
                type: payType,
                number: num,
            },
            (res) => {
                console.log(res);
                util.postYangAxios(
                    `${api.orderPay}/${res.order.id}/cashierDesk`,
                    {
                        bound_url: `${shareIP}spellInvitation?id=${res.order.bill_order_id}`,
                    },
                    (r) => {
                        console.log(r);
                        window.location.href=r.url;
                    },
                    (err) => {
                        console.log(err);
                    },
                    true,
                );
            },
            (err) => {
                console.log(err);
            },
            true,
        );

    }

    render() {
        const {userData} =this.props.UserDataReducer;
        const {  isSpellGroupVisible, isSpellMakeOrderVisible, goodObj, payType }=this.state
        if(!goodObj){
            return null;
        }
        return (
            <div className={"article_view"} style={{paddingBottom: '7rem'}}>
                <Carousel autoplay dots={false}>
                    {
                        goodObj.image.map((item,index)=>{
                            return (
                                <div key={index}>
                                    <Image
                                        width={'100%'}
                                        height={'20.7rem'}
                                        src={qiNiuIP+item}
                                        placeholder={
                                            <Image
                                                preview={false}
                                                src={qiNiuIP+item}
                                                width={'100%'}
                                                height={'20.7rem'}
                                            />
                                        }
                                    />
                                </div>
                            )
                        })
                    }
                </Carousel>
                <img src={spell_goback_icon} alt="" className={'spell_goback_icon'} onClick={()=>this.props.history.goBack()}/>
                <div className={'spell_card'}>
                    <div className={'row_between'}>
                        <div className={'spell_shop_name'}>¥<span style={{fontSize: '1.4rem'}}>{goodObj.price_spell}</span>起</div>
                        <div className={'spell_shop_member'}>已售{JsTypeFunction.digitalDispose(goodObj.sales_volume)}单</div>
                    </div>
                    <div className={'spell_shop_title'}> {goodObj.name}</div>
                    {/*<div className={'spell_shop_content'}>简介：这里是的简介这里是的简介这里是的简介这</div>*/}
                </div>
                <div className={'gray_line'}/>
                {this.spellListRender()}
                <div className={'gray_line'}/>
                {this.handleCommentsRender()}
                <div className={'gray_line'}/>
                {this.handleStoreRender()}
                <div className={'gray_line'}/>
                {this.handleClassContentRender()}
                {this.handleBottomBtnRender()}
                <SpellGroupModal
                    visible={isSpellGroupVisible}
                    onCancel={this.handleCloseSpellGroup}
                    handlePingTuan={this.handlePingTuan}
                    collageList={goodObj.bill_order}
                />
                <SpellMakeOrderModal
                    visible={isSpellMakeOrderVisible}
                    onCancel={this.handleCloseSpellMakeOrder}
                    goodObj={goodObj}
                    payType={payType}
                    type={2}
                    handlePay={this.handlePay}
                />
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(purchaseGoodsDetails)
